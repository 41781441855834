<div class="documents-list">
  <div
    class="results col-12 tab-pane fade show active"
    id="nav-docs"
    role="tabpanel">
    <!-- Search Documents -->
    <div class="input-group mb-4 d-none">
      <span class="input-group-text bg-white search-input"
        ><i class="icon-search-lg"></i
      ></span>
      <input
        type="text"
        class="form-control search-input"
        placeholder="Search by document name or service..."
        [(ngModel)]="searchDocTerm"
        (keyup.enter)="onEnterInSearchDocInput()" />
    </div>
    <h2 class="result-header d-lg-none d-md-none">
      {{ 'Documents' | translate }}
    </h2>
    <div
      class="document-categories d-flex w-100"
      *ngIf="
        filteredCertificates.length !== 0 || filteredAttachments.length !== 0
      ">
      <ng-select
        class="d-lg-none d-md-none w-100"
        [items]="categories"
        bindLabel="name"
        bindValue="name"
        [(ngModel)]="selectedCategory"
        (change)="filterDocuments($event)"
        [clearable]="false"
        id="categorySelect">
      </ng-select>
      <ul class="nav nav-tabs mb-3 d-none d-lg-flex">
        <li class="nav-item" *ngFor="let category of categories">
          <a
            class="nav-link"
            [class.active]="category === selectedCategory"
            (click)="filterDocuments(category)">
            {{ category }}
          </a>
        </li>
      </ul>
    </div>
    <!-- Results View -->
    <div
      class="content-list"
      [ngClass]="{
        'custom-row col-12': !showMobileApplicationList,
        'd-flex flex-column': showMobileApplicationList
      }">
      <!-- Loading Spinner -->
      <div
        *ngIf="isLoadingDocuments"
        class="d-flex align-items-center justify-content-center text-center py-5">
        <div
          class="spinner-grow text-primary"
          style="width: 3rem; height: 3rem"
          role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <!-- Document List -->
      <ng-container *ngIf="!isLoadingDocuments">
        <div
          class="document mb-3 mb-lg-0"
          *ngFor="let singleCert of filteredCertificates; index as i">
          <div class="document-details">
            <div class="document-name">
              {{ singleCert.serviceName }} -
              {{ singleCert.applicationNumber }}
            </div>
            <span class="service-name">{{ singleCert.serviceName }}</span>
          </div>
          <div class="document-expiry">
            <div
              class="d-flex align-items-center justify-content-center"
              *ngIf="singleCert.nonExpiringCertificate; else expiry">
              {{ 'Expires on' | translate }} :
              <i class="icon-infinity ms-2"></i>
            </div>
          </div>

          <ng-template #expiry>
            <div class="d-flex align-items-center justify-content-center">
              {{ 'Expires on' | translate }}:{{
                singleCert.expirationDate | date : 'dd/MM/yyyy'
              }}
            </div>
          </ng-template>
          <div class="divider"></div>
          <div class="document-action d-flex flex-row col-12">
            <button
              class="preview btn btn-link text-decoration-none"
              *ngIf="!singleCert.expired"
              (click)="
                openLanguageSelectorModal(
                  singleCert,
                  documentMode.preview,
                  certificate_content
                )
              ">
              {{ 'Preview' | translate }}
            </button>
            <button
              class="btn download ms-auto btn-link text-decoration-none"
              (click)="
                openLanguageSelectorModal(singleCert, documentMode.download)
              ">
              {{ 'Download' | translate }}
            </button>
          </div>
        </div>

        <!-- Attachments -->
        <div
          class="document mb-3 mb-lg-0"
          *ngFor="let attachment of filteredAttachments; index as i">
          <div class="document-details">
            <div class="document-name">
              {{ attachment.serviceName }} -
              {{ attachment.applicationNumber }}
            </div>
            <span class="service-name">{{ attachment.serviceName }}</span>
          </div>
          <div class="divider"></div>
          <div
            class="document-action d-flex flex-row col-12"
            *ngIf="
              allowedAttachmentPreviewTypes.includes(
                attachment.fileName.split('.').pop() || ''
              )
            ">
            <button
              class="preview btn btn-link text-decoration-none"
              (click)="previewAttachment(attachment)">
              {{ 'Preview' | translate }}
            </button>
            <button
              class="btn download ms-auto btn-link text-decoration-none"
              (click)="downloadAttachment(attachment)">
              {{ 'Download' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <!-- End Documents -->
  </div>
</div>
