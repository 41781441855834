import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { AuthService } from '../../modules/auth/service/auth.service';
import { IdleTimerService } from '../../modules/auth/service/idle-timer.service';
import { Subscription } from 'rxjs';

import { INavbarItem } from '@irembo-andela/irembogov3-common';

import { MainNavBarLinkItems } from '../../config/nav-bar-link-items.config';
import { NavigationStart, Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FindOffcanvasComponent } from '../find-offcanvas/find-offcanvas.component';
import { UserProfileService } from '../../../app/services/user-profile.service';
import { IUserProfile } from '../../../app/models/user-profile.model';
import { environment } from '../../../environments/environment';
import { BusinessService } from '../../../app/services/business.service';
import { UserBusinessService } from '../../../app/services/user-business.service';
import { IdentityVerificationService } from '../../../app/services/show-identity-verification.service';

@Component({
  selector: 'irembogov-citizen-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  isLoggedIn?: boolean;
  keycloakProfile: KeycloakProfile = {};
  roles: string[] = [];
  userProfile!: IUserProfile;

  modalOpen = false;
  idleCountDown = 0;
  idleState!: string;
  _idleState!: Subscription;
  navigationSubscription: Subscription | undefined;
  breakPointSubscription: Subscription | undefined;
  showIdentityVerification = false;

  env = environment;
  private subscriptions = new Subscription();

  @Input() navbarItems: INavbarItem[] = [];

  @ViewChild('logoutWarningContent') logoutWarningModal!: ElementRef;
  @ViewChild('verificationModal') verificationModal!: ElementRef;
  signupLink: string;
  redirectSignup = false;

  constructor(
    private keycloak: KeycloakService,
    private idleTimerService: IdleTimerService,
    private modalService: NgbModal,
    private authService: AuthService,
    private offcanvasService: NgbOffcanvas,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private userProfileService: UserProfileService,
    private businessService: BusinessService,
    private userBusinessService: UserBusinessService,
    private identityVerificationService: IdentityVerificationService
  ) {
    this.authService.showLogOutWarningModal$.subscribe((show: boolean) => {
      if (show) {
        this.openLogoutModal(this.logoutWarningModal);
      }
    });

    this.idleTimerService.initIdleTimer();

    this._idleState = this.idleTimerService
      .idleTimeOutCountDown()
      .subscribe((countDown: number) => {
        this.idleCountDown = countDown;
      });

    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.offcanvasService.dismiss();
      }
    });

    this.breakPointSubscription = this.breakpointObserver
      .observe(['(min-width: 576px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.offcanvasService.dismiss();
        }
      });

    if (this.env.signupRedirectUrl) {
      this.signupLink = this.env.signupRedirectUrl;
      this.redirectSignup = true;
    } else {
      this.signupLink = '/auth/register';
    }
  }

  async ngOnInit() {
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    if (this.isLoggedIn) {
      this.businessService.fetchAllIremboPermissionsAndPermissionGroups();
      this.keycloakProfile = await this.keycloak.loadUserProfile();
      this.getUserProfile();
      this.userBusinessService.fetchAndSetUserServices();
    }

    this.authService.updateIncompleteProcessState(false);
    this.navbarItems = this.getMainNavBarLinkItems();
  }

  logoutUser() {
    this.offcanvasService.dismiss();
    this.openLogoutModal(this.logoutWarningModal);
  }

  openVerificationModal(content: ElementRef): void {
    if (this.modalOpen) {
      return;
    }
    this.modalService
      .open(content, { backdrop: 'static', centered: true })
      .result.then(
        result => {
          this.modalOpen = false;

          if (result === 'start-verification') {
            this.verifyUserIdentity();
            return;
          }
        },
        () => {
          this.modalOpen = false;
        }
      );
  }

  openLogoutModal(content: ElementRef): void {
    if (this.modalOpen) {
      return;
    }
    this.modalService
      .open(content, {
        backdrop: 'static',
        windowClass: 'logout-modal',
        centered: true,
      })
      .result.then(
        result => {
          this.modalOpen = false;

          if (result === 'logout') {
            this.authService.updateIncompleteProcessState(false);
            this.authService.logOutUser();
            return;
          }
          this.idleTimerService.initIdleTimer();
        },
        () => {
          this.modalOpen = false;
          this.idleTimerService.initIdleTimer();
        }
      );
  }

  ngOnDestroy(): void {
    if (this._idleState) this._idleState.unsubscribe();
    this.navigationSubscription?.unsubscribe();
    this.breakPointSubscription?.unsubscribe();
  }

  getMainNavBarLinkItems(): INavbarItem[] {
    const navbarItems: INavbarItem[] = [];
    Object.keys(MainNavBarLinkItems).forEach((key: string) => {
      const item: INavbarItem = MainNavBarLinkItems[key];
      // Exchange menu items based on whether the use is logged-in
      if (this.isLoggedIn && key === 'users') {
        return;
      }
      if (!this.isLoggedIn && key === 'applications') {
        return;
      }
      navbarItems.push(item);
    });

    return navbarItems;
  }

  get userInitialsAndName() {
    const userDetails = {
      initials: '',
      names: '',
    };
    if (!this.keycloakProfile.firstName && !this.keycloakProfile.lastName) {
      if (this.keycloakProfile.username) {
        userDetails.initials = this.keycloakProfile.username.charAt(0);
        userDetails.names = this.keycloakProfile.username;
      }
    }

    if (this.keycloakProfile.firstName) {
      userDetails.initials = this.keycloakProfile.firstName.charAt(0);
      userDetails.names = this.keycloakProfile.firstName + ' ';
    }

    if (this.keycloakProfile.lastName) {
      userDetails.initials =
        userDetails.initials + this.keycloakProfile.lastName.charAt(0);
      userDetails.names = userDetails.names + this.keycloakProfile.lastName;
    }

    const formattedName = userDetails.names.split(' ');
    userDetails.names =
      formattedName.length == 1
        ? formattedName[0]
        : `${formattedName[0]} ${formattedName[1]}`;

    return userDetails;
  }

  open(mobileMenuTemplateRef: TemplateRef<unknown>, position: 'start' | 'end') {
    this.offcanvasService.open(mobileMenuTemplateRef, {
      ariaLabelledBy: 'mobile-navigation',
      position,
      panelClass: 'mobile-container',
      backdrop: 'static',
    });
  }

  openCategoriesInstitutionsMenu(activeTabId: any) {
    this.offcanvasService.dismiss();
    const offcanvasRef = this.offcanvasService.open(FindOffcanvasComponent, {
      panelClass: 'find-offcanvas',
    });
    offcanvasRef.componentInstance.setActive = activeTabId;
  }

  onLogin() {
    this.keycloak.login({
      redirectUri: window.location.origin + '/',
      locale: localStorage.getItem('locale') ?? 'en-US',
    });
  }

  verifyUserIdentity() {
    this.router.navigate(['/verify']);
  }

  cancelIdentityVerification() {
    this.showIdentityVerification = !this.showIdentityVerification;
    this.identityVerificationService.setShowIdentityVerification(
      this.showIdentityVerification
    );
  }

  getUserProfile() {
    if (!this.isLoggedIn) return;

    this.userProfileService.getUserProfile().subscribe({
      next: res => {
        this.userProfile = res;
        this.showIdentityVerification = this.isLoggedIn
          ? !(this.isLoggedIn && res.accountVerified)
          : this.showIdentityVerification;
        this.identityVerificationService.setShowIdentityVerification(
          this.showIdentityVerification
        );
        if (this.userProfile?.firstLogin) {
          this.openVerificationModal(this.verificationModal);
          this.updateUserFirstLogin();
        }
      },
    });
  }

  updateUserFirstLogin() {
    this.userProfileService.updateFirstLogin().subscribe();
  }

  onRegister() {
    if (this.env.signupRedirectUrl) {
      window.open(this.env.signupRedirectUrl, '_blank');
    } else {
      this.router.navigate(['/auth', 'register']);
    }
  }

  isExternalLink(url: string): boolean {
    return url.includes('http');
  }
}
