import { HttpRequest } from '@angular/common/http';

export function shouldAddToken(request: HttpRequest<unknown>): boolean {
  const { method, url } = request;

  const excludedRoutes = [
    '/admin/v1/translations/static-text/by-locale/',
    '/admin/v1/languages',
    '/admin/v1/translation-files',
    '/admin/v1/ui-translations',
  ];
  const isGetRequest = 'GET' === method.toUpperCase();

  const isExcludedRoute = excludedRoutes.some(route => url.includes(route));

  return !(isGetRequest && isExcludedRoute);
}
