import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'irembogov-time-picker',
  templateUrl: './irembo-time-picker.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboTimePickerComponent),
      multi: true,
    },
  ],
})
export class IremboTimePickerComponent implements OnInit, ControlValueAccessor {
  @Input() startHour = 8;
  @Input() endHour = 16;
  @Input() interval = 40;
  @Input() placeholder = '';
  @Input() labelForId: unknown;
  @Input() customFormControl = new FormControl();
  @Output() itemSelected = new EventEmitter<string | null>();
  timeSlots: Record<string, string>[] = [];

  ngOnInit(): void {
    this.generateTimeSlots(this.startHour, this.endHour, this.interval);
  }

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  private _onTouch = (value: unknown) => {};
  /* eslint-enable */

  writeValue(obj: unknown): void {
    if (typeof obj === 'string') {
      this.timeSlots.some((slot: Record<string, string>) => {
        if (slot?.['value'] === obj) {
          this.customFormControl.setValue(slot);
          return true;
        }

        return false;
      });
    }
    this.customFormControl.setValue(obj);
  }
  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  change(obj: unknown): void {
    const value = obj ? (obj as Record<string, string>)['value'] : null;
    this._onChange(value);
    this._onTouch(value);
    this.itemSelected.emit(value);
  }

  generateTimeSlots(startHour: number, endHour: number, interval: number) {
    if (
      startHour > endHour ||
      startHour < 0 ||
      endHour > 23 ||
      interval > 60 ||
      interval <= 0
    ) {
      throw new Error('Invalid time slot parameters');
    }

    let hourCounter = startHour;
    while (hourCounter <= endHour) {
      for (let minute = 0; minute < 60; minute += interval) {
        const hour = hourCounter % 12 || 12;
        const ampm = hourCounter < 12 ? 'AM' : 'PM';
        const timeString = `${hour}:${minute
          .toString()
          .padStart(2, '0')} ${ampm}`;
        this.timeSlots.push({ value: timeString, label: timeString });
      }
      hourCounter++;
    }
  }
}
