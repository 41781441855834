import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription, finalize } from 'rxjs';
import { Business } from '../../../../models/business/business.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IBaseBusinessPermissionAndGroupEntity } from 'src/app/models/business/business.enum';
import { BusinessService } from '../../../../services/business.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { HttpErrorResponse } from '@angular/common/http';
import { ERROR_MAP } from '../../../../data/errorMap';
import { UserBusinessService } from '../../../../services/user-business.service';

@Component({
  selector: 'irembogov-manage-business-teams',
  templateUrl: './manage-business-teams.component.html',
  styleUrls: ['./manage-business-teams.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageBusinessTeamsComponent implements OnInit, OnDestroy {
  businessId!: string;
  business?: Business;

  isInvitingNewUser = false;

  iremboBusinessPermissionGroups: IBaseBusinessPermissionAndGroupEntity[] = [];
  reloadBusinessUsersSubject: Subject<boolean> = new Subject<boolean>();

  showUserInvitedMessage = false;

  private subscriptions = new Subscription();

  @ViewChild('inviteNewBusinessUserModal')
  inviteNewBusinessUserModal!: TemplateRef<unknown>;

  emailOrPhoneNoregexp =
    /^(07[2-9][0-9]{7}|[^<>()[\]\\,;:%#^\s"$&!@]+@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,})))$/;

  newUserForm: FormGroup = new FormGroup({
    permissionGroup: new FormControl('', [Validators.required]),
    username: new FormControl('', [
      Validators.required,
      Validators.pattern(this.emailOrPhoneNoregexp),
    ]),
  });

  constructor(
    private businessService: BusinessService,
    private userBusinessService: UserBusinessService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {
    this.businessService.fetchAllIremboPermissionsAndPermissionGroups();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: any) => {
      this.businessId = params.get('businessId') ?? '';
    });

    this.subscriptions.add(
      this.userBusinessService.userBusinessesSubjectObservable.subscribe(() => {
        this.business =
          this.userBusinessService.findUserBusinessInBusinessesById(
            this.businessId
          );

        if (
          this.business?.guid &&
          !(
            this.business?.availablePermissions?.includes(
              'APPROVE_BUSINESS_USER_REQUEST'
            ) ||
            this.business?.availablePermissions?.includes(
              'REJECT_BUSINESS_USER_REQUEST'
            )
          )
        ) {
          this.router.navigate(['profile'], {
            queryParams: { section: 'business' },
          });
        }
      })
    );

    this.subscriptions.add(
      this.businessService.iremboBusinessPermissionGroupsObservable.subscribe(
        (permissionGroups: IBaseBusinessPermissionAndGroupEntity[]) => {
          this.iremboBusinessPermissionGroups = permissionGroups;
        }
      )
    );
  }

  initiateNewMemberInvite(): void {
    const options: NgbModalOptions = {
      ariaLabelledBy: 'invite-business-user',
      windowClass: 'invite-business-user-modal',
      centered: true,
      container: '.irembogov-manage-business-teams',
      backdrop: 'static',
    };
    this.showUserInvitedMessage = false;
    this.modalService
      .open(this.inviteNewBusinessUserModal, options)
      .result.then(
        () => {
          this.newUserForm.reset();
          this.showUserInvitedMessage = false;
        },
        () => {
          this.newUserForm.reset();
          this.showUserInvitedMessage = false;
        }
      );
  }

  inviteNewUser(): void {
    if (this.newUserForm.invalid) {
      return;
    }

    this.isInvitingNewUser = true;
    this.subscriptions.add(
      this.businessService
        .addNewBusinessUser(
          this.businessId,
          this.newUserForm.get('username')?.value,
          this.newUserForm.get('permissionGroup')?.value
        )
        .pipe(
          finalize(() => {
            this.isInvitingNewUser = false;
          })
        )
        .subscribe({
          next: () => {
            this.toastService.show({
              body: this.translateService.instant('New team member invited!'),
              type: 'success',
              delay: 5000,
            });

            this.newUserForm.reset();

            this.reloadBusinessUsersSubject.next(true);

            this.modalService.dismissAll();
          },
          error: (error: HttpErrorResponse) => {
            const responseCode = <string>error.error.responseCode;

            if (responseCode === 'CITIZEN_USER_NOT_FOUND') {
              this.showUserInvitedMessage = true;
              return;
            }
            const errorMessage =
              ERROR_MAP[responseCode] ??
              error?.error?.responseMessage ??
              error?.error?.message ??
              'The new team member has not been invited.';
            this.toastService.show({
              title: this.translateService.instant('Team member invite failed'),
              body: errorMessage,
              type: 'error',
              delay: 5000,
            });
          },
        })
    );
  }

  setSelectedPermissionGroup(permissionGroup: string): void {
    this.newUserForm.get('permissionGroup')?.setValue(permissionGroup);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
