<div class="services-container text-center">
  <h3 class="heading">{{ 'Browse by category' | translate }}</h3>
  <div
    *ngIf="isLoadingCategoryServices"
    class="w-99 d-flex align-items-center justify-content-center text-center top-margin-spinner">
    <div
      class="spinner-grow text-primary"
      style="width: 3rem; height: 3rem"
      role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <!-- Category names slider  -->
  <div class="item-slider d-none d-lg-inline-flex d-md-inline-flex">
    <div class="float-start ms-2" *ngIf="scrollX > 0">
      <button class="prev-button" (click)="scroll(-150)">
        <i class="fa-solid fa-angle-left"></i>
      </button>
    </div>
    <div
      #scrlCategory
      (scroll)="scrollCheck()"
      class="item-container"
      id="pills-tab"
      [ngStyle]="{ 'margin-left': scrollX > 0 ? '4rem' : '0px' }"
      role="tablist">
      <div
        class="nav-item me-3"
        [class.active]="
          defaultCategory.categoryId === selectedCategory.categoryId
        ">
        <button
          class="nav-link btn"
          id="{{ defaultCategory.categoryId }}-tab"
          data-toggle="pill"
          (click)="selectCategory(defaultCategory)">
          {{ defaultCategory.categoryName | translate }}
        </button>
      </div>
      <div
        class="nav-item mx-2"
        [class.active]="selectedCategory.categoryId === category.categoryId"
        *ngFor="let category of categoryServices">
        <button
          class="nav-link btn"
          id="{{ category.categoryId }}-tab"
          data-toggle="pill"
          (click)="selectCategory(category)">
          {{ category.categoryName | customTranslate }}
        </button>
      </div>
    </div>
    <div class="float-end" *ngIf="!scrollEnd">
      <button class="next-button" (click)="scroll(150)">
        <i class="fa-solid fa-angle-right"></i>
      </button>
    </div>
  </div>

  <div class="tab-content" id="pills-tabContent">
    <!-- Category services accordions mobile -->
    <div *ngIf="isMobile$ | async">
      <div class="accordion" id="accordionServices">
        <div
          *ngIf="categoryServices.length === 0"
          class="no-applications d-flex flex-column justify-content-center align-items-center col-12 h-100 w-100">
          <div class="icons-container">
            <i class="icon-file-question-01"></i>
          </div>
          <div class="title">{{ 'No services available' | translate }}</div>
          <div class="description col-10 col-lg-4 col-md-4">
            {{
              'There are currently no services available in this category.'
                | translate
            }}
          </div>
          <div class="description col-10 col-lg-4 col-md-4">
            {{
              'Please check back later or select a different category.'
                | translate
            }}
          </div>
        </div>
        <div *ngFor="let category of categoryServices; let i = index">
          <button
            id="heading{{ i }}"
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            attr.data-bs-target="#collapse{{ i }}"
            aria-expanded="false"
            attr.aria-controls="collapse{{ i }}">
            {{ category.categoryName | customTranslate }}
          </button>
          <div
            id="collapse{{ i }}"
            class="accordion-collapse collapse"
            attr.aria-labelledby="heading{{ i }}"
            data-bs-parent="#accordionServices">
            <div
              class="d-flex justify-content-start align-items-start text-start">
              <irembogov-service-view
                [categoryServices]="category.services"
                (serviceClicked)="onServiceClicked($event)">
              </irembogov-service-view>
            </div>
          </div>
          <div class="divider"></div>
        </div>
      </div>
    </div>
    <!-- End mobile accordion -->
    <div
      *ngIf="
        selectedCategory.categoryId === defaultCategory.categoryId;
        else notDefaultCategory
      "
      class="d-none d-lg-block d-md-block">
      <div
        *ngIf="categoryServices.length === 0"
        class="no-applications d-flex flex-column justify-content-center align-items-center col-12 h-100 w-100">
        <div class="icons-container">
          <i class="icon-file-question-01"></i>
        </div>
        <div class="title">{{ 'No services available' | translate }}</div>
        <div class="description col-10 col-lg-4 col-md-4">
          {{
            'There are currently no services available in this category.'
              | translate
          }}
        </div>
        <div class="description col-10 col-lg-4 col-md-4">
          {{
            'Please check back later or select a different category.'
              | translate
          }}
        </div>
      </div>
      <div
        *ngFor="let category of categoryServices; let i = index"
        class="text-start">
        <div class="category-name mb-lg-3 mb-md-3 mb-sm-2">
          <h4>{{ category.categoryName | customTranslate }}</h4>
        </div>
        <br />
        <irembogov-service-view
          [categoryServices]="category.services"
          (serviceClicked)="onServiceClicked($event)"></irembogov-service-view>
        <div class="divider"></div>
      </div>
    </div>

    <ng-template #notDefaultCategory>
      <!-- Mobile view -->
      <div
        *ngIf="
          selectedCategory.categoryId !== defaultCategory.categoryId &&
          (isMobile$ | async)
        "
        class="text-start d-lg-none d-md-none">
        <div class="accordion" id="servicesAccordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne">
                <span class="me-auto">{{
                  selectedCategory.categoryName | customTranslate
                }}</span>
                <i class="bi bi-chevron-down"></i>
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#servicesAccordion">
              <div class="accordion-body">
                <irembogov-service-view
                  [categoryServices]="selectedCategoryServices"
                  (serviceClicked)="
                    onServiceClicked($event)
                  "></irembogov-service-view>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End mobile view -->

      <!-- Large screens -->
      <div
        *ngIf="selectedCategory.categoryId !== defaultCategory.categoryId"
        class="d-none d-lg-block d-md-block">
        <div class="category-name mb-3 text-start">
          <h4>{{ selectedCategory.categoryName | customTranslate }}</h4>
        </div>
        <irembogov-service-view
          [categoryServices]="selectedCategoryServices"
          (serviceClicked)="onServiceClicked($event)"></irembogov-service-view>
      </div>
      <!-- End large screens view -->
    </ng-template>
  </div>
</div>
