<div class="irembogov-list-business-users">
  <div
    *ngIf="isLoadingBusinessUsers"
    class="w-99 d-flex align-items-center justify-content-center text-center top-margin-spinner">
    <div
      class="spinner-grow text-primary"
      style="width: 3rem; height: 3rem"
      role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <ng-container *ngIf="!isLoadingBusinessUsers">
    <ng-container *ngIf="businessUsers.length > 0; else noBusinessUser">
      <div class="business-users-list">
        <div
          class="results col-12 tab-pane fade show active"
          id="nav-apps"
          role="tabpanel">
          <!-- Business  View -->
          <div class="content-list d-flex flex-column">
            <div class="fluid-table-wrapper">
              <div class="fluid-table-table-header-row">
                <div class="fluid-table-header-cell name-column-header col-7">
                  {{ 'Name' | translate }}
                </div>
                <div class="fluid-table-header-cell role-column-header col-5">
                  {{ 'Role' | translate }}
                </div>
              </div>
              <div class="fluid-table-body">
                <div
                  *ngFor="let user of businessUsers"
                  class="fluid-table-table-item-row d-flex">
                  <div
                    class="fluid-table-item-cell name-item-cell col-12 col-lg-7">
                    <irembogov-username-and-email-circle-icon
                      *ngIf="user.firstName || user.lastName"
                      [email]="user.username"
                      fullName="{{ user.firstName }} {{
                        user.lastName
                      }}"></irembogov-username-and-email-circle-icon>

                    <irembogov-username-and-email-circle-icon
                      *ngIf="!(user.firstName || user.lastName)"
                      noEmailPlaceholder=""
                      [fullName]="
                        user.username
                      "></irembogov-username-and-email-circle-icon>
                  </div>
                  <div
                    class="fluid-table-item-cell role-item-cell col-12 col-lg-5 d-flex flex-column flex-md-row">
                    <div class="col-12 col-md-5 role-info">
                      <span class="role">{{
                        user.permissionGroups[0]
                          | businessPermissionGroupCodeToLabel
                          | translate
                      }}</span>
                    </div>
                    <div class="col-12 col-md-7 action-buttons d-flex gap-2">
                      <button
                        *ngIf="
                          business?.availablePermissions?.includes(
                            'REMOVE_BUSINESS_USER'
                          ) && user.userId !== userProfile?.userId
                        "
                        class="btn btn-red px-0 px-md-3"
                        type="button"
                        (click)="initRemoveBusinessUser(user)">
                        {{ 'Remove team member' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- No Business User Template -->
<ng-template #noBusinessUser>
  <div
    class="no-business-user d-flex flex-column py-4 px-3 border rounded justify-content-center align-items-center w-100 h-100">
    <div class="icons-container">
      <i class="icon-layers-three-01"></i>
    </div>
    <div class="title">{{ 'No business user!' | translate }}</div>
    <div class="description col-10 col-md-4 col-lg-4">
      {{ 'No business user is linked to this business.' | translate }}
    </div>
  </div>
</ng-template>

<ng-template #removeBusinessUserModal let-modal>
  <div class="modal-header d-flex align-items-start border-0">
    <div class="d-flex flex-column">
      <h4 class="modal-title mt-2" id="modal-invite-business-user-title">
        {{ 'Confirm removal of ' | translate }}
        {{ this.userToBeRemoved?.permissionGroups?.[0] | businessPermissionGroupCodeToLabel | translate | titlecase }}
      </h4>
    </div>
    <button
      type="button"
      class="btn mt-1 btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body pt-0">
    <div
      *ngIf="isFetchingUserPermissions"
      class="w-99 d-flex align-items-center justify-content-center text-center top-margin-spinner fetching-available-permissions-loader">
      <div
        class="spinner-grow text-primary"
        style="width: 3rem; height: 3rem"
        role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      *ngIf="!isFetchingUserPermissions"
      class="removed-user-confirmation-text w-100">
      <p>
        {{ 'Are you sure you want to remove' | translate }} {{ ' ' }}
        <strong>
          {{
            userToBeRemoved?.firstName || userToBeRemoved?.lastName
              ? userToBeRemoved?.firstName + ' ' + userToBeRemoved?.lastName
              : userToBeRemoved?.username
          }}
        </strong>
        {{ ' ' }}{{ 'as a' }} {{ ' ' }} {{ 'Business' }} {{ ' ' }}
        {{
          userToBeRemoved?.permissionGroups?.[0]
            | businessPermissionGroupCodeToLabel | translate
        }}
        {{ ' ' }}{{ 'for' }}{{ ' ' }} <strong>{{ business?.name }}</strong
        >. {{ ' ' }}{{ 'This action cannot be undone' }}.
      </p>

      <p>
        {{
          'If removed, this user will lose these listed permissions'
            | translate
        }}:
      </p>
      <div class="permission-and-groups">
        <div
          class="remove-permission-desc remove-permission-groups-desc"
          *ngFor="let permissionEntity of userAvailablePermissions">
          {{ permissionEntity.description | translate }}
        </div>
        <div
          class="remove-permission-desc remove-permissions-desc"
          *ngFor="let permissionLabel of userToBeRemoved?.permissions">
          {{ permissionLabel | businessPermissionCodeToLabel : 'desc' }}
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="!isFetchingUserPermissions"
    class="modal-footer d-flex flex-column-reverse flex-md-row gap-2 border-0 align-items-stretch">
    <button
      type="button"
      [disabled]="isRemovingBusinessUser"
      class="btn btn-lg cancel-btn btn-outline-dark text-decoration-none m-0"
      (click)="modal.dismiss('Cancel')">
      {{ 'Cancel' | translate }}
    </button>
    <button
      [disabled]="isRemovingBusinessUser || !this.userToBeRemoved?.guid"
      type="button"
      class="btn btn-lg btn-danger text-decoration-none m-0 px-1 text-nowrap"
      (click)="removeBusinessUser(this.userToBeRemoved?.guid)">
      {{ 'Remove' | translate }}{{ ' '
      }}{{ this.userToBeRemoved?.permissionGroups?.[0] | businessPermissionGroupCodeToLabel | translate | lowercase }}
      <div
        *ngIf="isRemovingBusinessUser"
        class="spinner-border spinner-border-sm text-light"
        role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
  </div>
</ng-template>
