import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  applicationFormBuildPreviewData,
  applicationStatusEnumToColorUtil,
  applicationStatusEnumToLabelUtil,
  applicationStatusValueOf,
  IApplication,
  IApplicationDetails,
  IApplicationFormData,
  IFeedbackResponseDetails,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import {
  Business,
  IBusinessApplication,
} from '../../../../models/business/business.model';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { finalize, Subscription } from 'rxjs';
import { ApplicationsService } from '../../../../../app/services/applications.service';
import { PaymentService } from '../../../../../app/services/payment.service';
import { environment } from '../../../../../environments/environment';
import { UserBusinessService } from '../../../../../app/services/user-business.service';

declare const IremboPay: any;
@Component({
  selector: 'irembogov-business-applications',
  templateUrl: './business-applications.component.html',
  styleUrls: ['./business-applications.component.scss'],
})
export class BusinessApplicationsComponent implements OnInit {
  businessId!: string;
  business?: Business;

  applicationsList: IBusinessApplication[] = [];
  totalApplications = 0;
  isLoadingApplications = false;
  applicationDetails: IApplicationDetails | undefined;
  isLoadingApplicationDetails = false;
  identificationNumber = '';
  doneLoadingProfile = false;
  appHasTracker = false;
  selectedApplication: IApplication | undefined;
  downloadingReceipt = false;
  searchTerm = '';
  applicationSummary: IApplicationFormData[] = [];
  onlinePaymentActivated = false;
  paymentComplete = false;
  billId: string | undefined = '';
  applicationBaseUrl = environment.apiGatewayBaseUrl;
  applicationPath = '/application/v1/file-manager/application/attachment';

  showMobileApplicationList = false;
  viewSummary = false;
  viewSummaryText = 'View application summary';

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private applicationsService: ApplicationsService,
    private breakpointObserver: BreakpointObserver,
    private userBusinessService: UserBusinessService,
    private paymentService: PaymentService,
    private toastService: ToastService,
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    private offcanvasService: NgbOffcanvas
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.businessId = params.get('businessId') ?? '';
      this.getApplications(0, 10, 'dateCreated,desc', this.searchTerm);
    });

    this.subscriptions.add(
      this.userBusinessService.userBusinessesSubjectObservable.subscribe(() => {
        this.business =
          this.userBusinessService.findUserBusinessInBusinessesById(
            this.businessId
          );
      })
    );

    this.subscriptions.add(
      this.breakpointObserver
        .observe(['(min-width: 576px)'])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.showMobileApplicationList = false;
          } else {
            this.showMobileApplicationList = true;
          }
        })
    );
  }

  getApplications(
    page: number,
    size: number,
    sort: string,
    searchTerm: string
  ) {
    this.isLoadingApplications = true;
    this.subscriptions.add(
      this.applicationsService
        .getBusinessApplications(this.businessId, {
          page,
          size,
          sort,
          searchTerm,
        })
        .pipe(finalize(() => (this.isLoadingApplications = false)))
        .subscribe({
          next: res => {
            this.applicationsList = res.data.content;
            this.totalApplications = res.data.totalElements;
          },
          error: () => {
            this.toastService.show({
              body: 'Error loading applications',
              type: 'error',
            });
            this.isLoadingApplications = false;
          },
        })
    );
  }

  getApplicationStatusToLabel(applicationStatus: string): string {
    return applicationStatusEnumToLabelUtil(
      applicationStatusValueOf(applicationStatus)
    );
  }

  handlePaginate(event: { pageNumber: number; pageSize: number }) {
    this.getApplications(
      event.pageNumber - 1,
      event.pageSize,
      'dateCreated,desc',
      this.searchTerm
    );
  }

  onEnterInSearchInput() {
    this.getApplications(0, 10, 'dateCreated,desc', this.searchTerm);
  }

  getApplicationStatusToColor(applicationStatus: string): string {
    return applicationStatusEnumToColorUtil(
      applicationStatusValueOf(applicationStatus)
    );
  }

  getApplicationStatusPulseToBackgroundColor(applicationStatus: string) {
    const color = applicationStatusEnumToColorUtil(
      applicationStatusValueOf(applicationStatus)
    );
    return `bg-${color}`;
  }

  //view applications details
  openApplicationDetails(
    content: TemplateRef<unknown>,
    application: IApplication
  ) {
    this.selectedApplication = application;
    this.getApplicationDetails(application?.applicationId);
    this.setPaymentV2Script();
    this.offcanvasService.open(content, {
      position: 'end',
      panelClass: 'offcanvas-application',
    });
  }

  getApplicationDetails(id: string | undefined) {
    this.applicationDetails = undefined;
    if (id !== undefined) {
      this.isLoadingApplicationDetails = true;
      this.subscriptions.add(
        this.applicationsService.getApplicationDetailsById(id).subscribe({
          next: res => {
            this.applicationDetails = res;
            this.billId = this.applicationDetails?.billId;
            if (this.applicationDetails) {
              if (
                this.applicationDetails?.applicationFeedbacks
                  ?.feedbackResponseDetails
              ) {
                this.applicationDetails.applicationFeedbacks[
                  'feedbackResponseDetails'
                ] =
                  this.applicationDetails?.applicationFeedbacks?.feedbackResponseDetails?.filter(
                    feedBack => feedBack.comment != null
                  );
              }

              this.applicationSummary = this.getApplicationSummary(
                this.applicationDetails
              );
            }
            this.isLoadingApplicationDetails = false;
          },
          error: () => {
            this.toastService.show({
              body: 'Error loading application details',
              type: 'error',
            });
            this.isLoadingApplicationDetails = false;
          },
        })
      );
    }
  }

  viewSummaryToggle() {
    this.viewSummary = !this.viewSummary;
    if (this.viewSummary) {
      this.viewSummaryText = 'Hide application summary';
    } else {
      this.viewSummaryText = 'View application summary';
    }
  }

  editApplication(
    applicationId: string,
    applicationStatus: string,
    applicationNumber: string,
    serviceId: string,
    serviceName: string
  ) {
    const id = serviceId ?? '';
    const keyword = serviceName ?? '';
    const appId = applicationId ?? '';
    const appStatus = applicationStatus ?? '';
    const appNumber = applicationNumber ?? '';
    this.offcanvasService.dismiss();
    this.router.navigate(['application/apply'], {
      queryParams: { id, keyword, appId, appStatus, appNumber },
    });
  }

  getPaymentReceipt(applicationNumber: string | undefined) {
    if (applicationNumber !== undefined) {
      this.downloadingReceipt = true;
      this.subscriptions.add(
        this.paymentService.getPaymentReceipt(applicationNumber).subscribe({
          next: res => {
            this.downloadingReceipt = false;
            const source = `data:` + res?.contentType + `;base64,${res?.file}`;
            const link = document.createElement('a');
            link.href = source;
            link.download = applicationNumber + `_payment_receipt.pdf`;
            link.click();
          },
          error: () => {
            this.downloadingReceipt = false;
            this.toastService.show({
              body: 'Error downloading payment receipt',
              type: 'error',
            });
          },
        })
      );
    }
  }

  setPaymentV2Script(): void {
    if (this._document.getElementById('iremboPay')) {
      this.onlinePaymentActivated = true;
      return;
    }
    const iremboPayCdnScript = this._document.createElement('script');
    iremboPayCdnScript.id = 'iremboPay';
    iremboPayCdnScript.src = environment.IREMBOPAY_CDN;
    iremboPayCdnScript.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(iremboPayCdnScript);
    iremboPayCdnScript.onload = () => {
      this.onlinePaymentActivated = true;
    };
  }

  makePayment() {
    this.getPaymentPublicKey(this.applicationDetails?.merchant).then(
      publicKey => {
        if (publicKey) {
          IremboPay.initiate({
            publicKey: publicKey,
            invoiceNumber: this.billId,
            locale: IremboPay.locale.EN,
            isTest: environment.IREMBOPAY_TEST,
            callback: (err: unknown, resp: Record<string, string>) => {
              if (!err) {
                IremboPay.closeModal();
                this.paymentComplete = true;
                this.toastService.show({
                  body: resp['message'],
                  type: 'success',
                });
                this.getApplicationDetails(
                  this.selectedApplication?.applicationId
                );
                this.getApplicationDetails(
                  this.selectedApplication?.applicationId
                );
              }
            },
          });
        }
      }
    );
  }

  getPaymentPublicKey(merchant: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      if (merchant !== undefined) {
        this.subscriptions.add(
          this.applicationsService.getPaymentPublicKey(merchant).subscribe({
            next: res => {
              const publicKey = res.data.publicKey;
              resolve(publicKey);
            },
          })
        );
      } else {
        reject();
      }
    });
  }

  convertDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/');
    return new Date(+year, +month - 1, +day);
  }

  getApplicationSummary(
    applicationDetails: IApplicationDetails
  ): IApplicationFormData[] {
    return applicationFormBuildPreviewData(
      applicationDetails.formDefinition.fields,
      applicationDetails.applicationSummary
    );
  }

  getActiveFeedback(
    feedBack: IFeedbackResponseDetails[]
  ): IFeedbackResponseDetails[] {
    return feedBack.filter(x => x.activeFeedback);
  }
}
