<div [ngStyle]="{ 'background-color': backgroundColor }" class="info-banner">
  <span class="info-banner-title">{{ bannerTitle | translate }}</span>
  <span class="info-banner-text">{{ bannerText | translate }}</span>
  <ng-container *ngIf="linkText">
    <a
      [href]="linkUrl"
      class="info-banner-link text-lowercase"
      target="_blank"
      >{{ linkText | translate }}</a
    >
  </ng-container>
</div>
