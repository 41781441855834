<div class="position-relative w-100 h-100 d-flex flex-column">
  <div class="fixed-top-container">
    <irembogov-info-banner
      *ngIf="activeBanner"
      [bannerTitle]="activeBanner.bannerTitle"
      [bannerText]="activeBanner.bannerText"
      [linkText]="activeBanner.linkText"
      [linkUrl]="activeBanner.linkUrl"></irembogov-info-banner>
    <irembogov-citizen-navbar></irembogov-citizen-navbar>
  </div>
  <div
    [ngStyle]="{
      'margin-top.px':
        activeBanner && showIdentityVerification
          ? 190
          : activeBanner
          ? 132
          : showIdentityVerification
          ? 132
          : 80
    }"
    class="flex-fill">
    <router-outlet></router-outlet>
  </div>
  <irembogov-portal-footer></irembogov-portal-footer>
</div>
