import { BannerConfig } from '../app/models/info-banner.model';

export const environment = {
  production: true,
  authServiceUrl: 'https://id.irembohub.com',
  authRealm: 'irembo',
  authClientId: 'citizens-portal',
  authSuccessRedirectUrl: 'https://new.irembo.gov.rw',
  apiGatewayBaseUrl: 'https://api.irembohub.com',
  idleTimeOutDuration: 1800,
  idleTimeOutCountDown: 30,
  IREMBOPAY_CDN: 'https://dashboard.irembopay.com/assets/payment/inline.js',
  IREMBOPAY_TEST: false,
  locale: 'IremboPay.locale.EN',
  knowledgeBaseUrl: 'https://newirembogov.freshdesk.com/en/support/home',
  DEFAULT_LOCALE: 'en-US',
  iremboPrivacyPolicyUrl: '/legal-information',
  SURVEY_SPARROW_WIDGET_TOKEN: 'tt-a87b9dd981',
  SURVEY_SPARROW_ACCOUNT: 'irembo.surveysparrow.com',
  SURVEY_SPARROW_SURVEY_NAME: 'irembogov-website-survey',
  PHONE_NUMBER_REGEX: '^07[2-9][0-9]{7}$',
  translationSourceUrl: 'https://api.irembohub.com',
  signupRedirectUrl: 'https://irembo.gov.rw/auth/register',
  pageBanners: {
    '/profile': {
      bannerTitle:
        'You can only view applications made on this upgraded version of IremboGov.',
      bannerText: 'To view other applications,',
      linkText: 'click here',
      linkUrl: 'https://irembo.gov.rw/home/citizen/all_services',
      backgroundColor: '#F3F6FB',
    },
  } as { [key: string]: BannerConfig },
  homePage: {
    title: 'Welcome to the upgraded IremboGov',
    subtitle:
      'Currently, only ePoBox registration through Iposita is available',
    callToAction: 'For all other services,',
    actionLink:
      '<a class="subtitle-link" href="https://irembo.gov.rw/home/citizen/all_services" target="_blank">click here</a>',
  },
};
