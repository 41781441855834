import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { finalize, Subscription } from 'rxjs';
import { ICertificateDownload } from '../../../../models/certificate-download.model';
import { ICertificate } from '../../../../models/certificates.model';
import { ILocale } from '../../../../models/locale/locale.model';
import { IOfficerAttachment } from '../../../../models/officer-attachment.model';
import { ApplicationsService } from '../../../../services/applications.service';
import { DocumentsService } from '../../../../services/documents.service';
import { LocaleService } from '../../../../services/locale.service';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { Business } from '../../../../models/business/business.model';
import { UserBusinessService } from '../../../../services/user-business.service';
import { BusinessService } from '../../../../services/business.service';
import { environment } from '../../../../../environments/environment';

enum documentMode {
  preview = 'PREVIEW',
  download = 'DOWNLOAD',
}

@Component({
  selector: 'irembogov-business-certificates',
  templateUrl: './business-certificates.component.html',
  styleUrls: ['./business-certificates.component.scss'],
})
export class BusinessCertificatesComponent implements OnInit, OnDestroy {
  businessId!: string;
  business?: Business;

  private subscriptions = new Subscription();
  activeLocale: string;
  totalDocuments = 0;
  pageNumber = 1;
  isCollapsed = false;
  searchTerm = '';
  isLoadingCertificates = false;
  certificatesList: ICertificate[] = [];
  officerAttachmentsList: IOfficerAttachment[] = [];
  selectedCertificate: ICertificate | undefined;
  selectedAttachment: IOfficerAttachment | undefined;
  filePath: string | undefined;
  isPreviewingCertificate = false;
  isDownloadingCertificate = false;
  downloadedCertificate: ICertificateDownload | undefined;
  downloadedAttachment: ICertificateDownload | undefined;

  showMobileApplicationList = false;
  base64Data: string | undefined;
  supportedLocales: ILocale[] = [];
  selectedCertificateNames: any[] = [];
  certificateNames: Record<string, Record<string, string>[]> = {};
  isLoadingDocuments = false;
  documentsList = [];
  documentMode = documentMode;
  selectedLanguage: string | null = null;
  languageMode: documentMode = documentMode.download;
  allowedAttachmentPreviewTypes = ['pdf', 'png', 'jpg', 'jpeg'];

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private businessService: BusinessService,
    private applicationsService: ApplicationsService,
    private documentsService: DocumentsService,
    private toastService: ToastService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private localeService: LocaleService,
    private userBusinessService: UserBusinessService
  ) {
    config.animation = false;
    config.centered = true;

    this.activeLocale =
      localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.businessId = params.get('businessId') ?? '';
      this.getSupportedLocales();
    });

    this.subscriptions.add(
      this.userBusinessService.userBusinessesSubjectObservable.subscribe(() => {
        this.business =
          this.userBusinessService.findUserBusinessInBusinessesById(
            this.businessId
          );
      })
    );

    this.subscriptions.add(
      this.breakpointObserver
        .observe(['(min-width: 576px)'])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.showMobileApplicationList = false;
          } else {
            this.showMobileApplicationList = true;
          }
        })
    );
  }

  getSupportedLocales() {
    this.localeService
      .getSupportedLocales()
      .pipe(finalize(() => this.loadCertificateAndAttachments()))
      .subscribe({
        next: response => {
          this.supportedLocales = response.data.content;
        },
        error: error => {
          this.toastService.show({
            body: error.error.responseMessage ?? error.error.message,
            type: 'error',
            delay: 5000,
          });
        },
      });
  }

  loadCertificateAndAttachments() {
    this.getCertificates(0, 10, 'date_created', this.searchTerm);
    this.getOfficersAttachments(0, 10, 'dateCreated,desc', this.searchTerm);
  }

  getOfficersAttachments(
    page: number,
    size: number,
    sort: string,
    searchTerm: string
  ) {
    this.isLoadingCertificates = true;
    this.subscriptions.add(
      this.applicationsService
        .getBusinessOfficersAttachments(this.businessId, {
          page,
          size,
          sort,
          searchTerm,
        })
        .subscribe({
          next: res => {
            this.isLoadingCertificates = false;
            this.officerAttachmentsList = res.data.content;
          },
          error: () => {
            this.toastService.show({
              body: 'Error loading officers attachments',
              type: 'error',
            });
            this.isLoadingCertificates = false;
          },
        })
    );
  }

  getCertificates(
    page: number,
    size: number,
    sort: string,
    searchTerm: string
  ) {
    this.isLoadingCertificates = true;
    this.subscriptions.add(
      this.applicationsService
        .getBusinessCertificates(this.businessId, {
          page,
          size,
          sort,
          searchTerm,
        })
        .subscribe({
          next: res => {
            this.isLoadingCertificates = false;
            this.certificatesList = res.data.content;
            this.generateActiveCertificateNamesAndLocales();
          },
          error: () => {
            this.toastService.show({
              body: 'Error loading active certificates',
              type: 'error',
            });
            this.isLoadingCertificates = false;
          },
        })
    );
  }

  handlePaginate(event: { pageNumber: number; pageSize: number }) {
    this.getCertificates(
      event.pageNumber - 1,
      event.pageSize,
      'date_created',
      this.searchTerm
    );
    this.getOfficersAttachments(
      event.pageNumber - 1,
      event.pageSize,
      'dateCreated,desc',
      this.searchTerm
    );
  }

  onEnterInSearchDocInput() {
    this.loadCertificateAndAttachments();
  }

  previewCertificate(
    content: TemplateRef<unknown>,
    certificate: ICertificate,
    locale: string
  ) {
    this.selectedCertificate = certificate;
    this.isPreviewingCertificate = true;
    this.subscriptions.add(
      this.documentsService
        .downloadCertificate(certificate?.certificateNames[locale])
        .subscribe({
          next: res => {
            this.isPreviewingCertificate = false;
            this.downloadedCertificate = res;
            const reader = new FileReader();
            reader.readAsDataURL(
              this.base64ToBlob(res?.file, res?.contentType)
            );
            reader.onload = () => {
              this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(
                reader.result as string
              ) as string;
              this.base64Data = reader.result as string;
            };
          },
          error: () => {
            this.toastService.show({
              body: 'Error previewing certificate',
              type: 'error',
            });
            this.isPreviewingCertificate = false;
          },
        })
    );
    this.modalService.open(content, {
      ariaLabelledBy: 'preview-document',
      size: 'xl',
    });
  }

  previewAttachment(
    content: TemplateRef<unknown>,
    attachment: IOfficerAttachment
  ) {
    this.selectedAttachment = attachment;
    this.isPreviewingCertificate = true;
    this.subscriptions.add(
      this.applicationsService
        .downloadAttachment(attachment.applicationId, attachment.fileName)
        .subscribe({
          next: res => {
            this.isPreviewingCertificate = false;
            this.downloadedAttachment = {
              file: '',
              contentType: res.headers.get('Content-Type') ?? 'application/pdf',
            };

            const reader = new FileReader();
            reader.readAsDataURL(res.body as Blob);
            reader.onload = () => {
              this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(
                reader.result as string
              ) as string;
              this.base64Data = reader.result as string;
            };
          },
          error: () => {
            this.toastService.show({
              body: 'Error previewing attachment',
              type: 'error',
            });
            this.isPreviewingCertificate = false;
          },
        })
    );
    this.modalService.open(content, {
      ariaLabelledBy: 'preview-document',
      size: 'xl',
    });
  }

  downloadAttachment(attachment: IOfficerAttachment) {
    this.selectedAttachment = attachment;
    this.isDownloadingCertificate = true;
    this.subscriptions.add(
      this.applicationsService
        .downloadAttachment(attachment.applicationId, attachment.fileName)
        .subscribe({
          next: res => {
            this.downloadedAttachment = {
              file: '',
              contentType: res.headers.get('Content-Type') ?? 'application/pdf',
            };
            this.downloadBlob(
              attachment?.serviceName +
                ' - ' +
                attachment.applicationNumber +
                attachment.fileName.split('.').pop(),
              res.body as Blob
            );
          },
          error: () => {
            this.toastService.show({
              body: 'Error downloading attachment',
              type: 'error',
            });
            this.isDownloadingCertificate = false;
          },
        })
    );
  }

  base64ToBlob(str: string, type: string) {
    // decode base64
    const content = atob(str);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const buffer = new ArrayBuffer(content.length);
    const view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (let n = 0; n < content.length; n++) {
      view[n] = content.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    const blob = new Blob([buffer], { type: type });

    return blob;
  }

  downloadCertificate(certificate: ICertificate, locale: string) {
    this.isDownloadingCertificate = true;
    this.subscriptions.add(
      this.documentsService
        .downloadCertificate(certificate?.certificateNames[locale])
        .subscribe({
          next: res => {
            this.downloadedCertificate = res;
            this.downloadBlob(
              certificate?.serviceName +
                ' - ' +
                certificate.applicationNumber +
                '.pdf',
              this.base64ToBlob(res?.file, res?.contentType)
            );
            this.isDownloadingCertificate = false;
            this.modalService.dismissAll();
            this.selectedLanguage = null;
          },
          error: () => {
            this.toastService.show({
              body: 'Error downloading certificate',
              type: 'error',
            });
            this.isDownloadingCertificate = false;
            this.modalService.dismissAll();
            this.selectedLanguage = null;
          },
        })
    );
  }

  downloadBlob(fileName: string, blob: Blob): void {
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
    this.isDownloadingCertificate = false;
  }

  openLanguageSelectorModal(
    content: TemplateRef<unknown>,
    certificate: ICertificate,
    mode: documentMode,
    previewContent?: TemplateRef<unknown | undefined>
  ) {
    this.selectedCertificate = certificate;
    this.languageMode = mode;
    if (mode === this.documentMode.download) {
      this.modalService.open(content);
    }
    if (mode === this.documentMode.preview) {
      this.modalService
        .open(content)
        .result.then((result: string) => {
          if (result) {
            this.selectedLanguage = null;
            this.modalService.dismissAll();
            if (previewContent) {
              this.previewCertificate(previewContent, certificate, result);
            }
          }
        })
        .catch(() => {
          // Modal was dismissed
          this.selectedLanguage = null;
        });
    }
  }

  generateCertificateNamesAndLocales(certificate: ICertificate) {
    const selectedCertificateNames: Record<string, string>[] = [];
    Object.entries(certificate.certificateNames).forEach(element => {
      selectedCertificateNames.push({
        locale: element[0],
        localeFlag: this.getLocaleIcon(element[0]),
        localeName: this.getLocaleNameByCode(element[0]),
        certificateName: element[1],
      });
    });

    return selectedCertificateNames;
  }

  generateActiveCertificateNamesAndLocales() {
    this.certificatesList.forEach(certificate => {
      this.certificateNames[certificate.applicationNumber] =
        this.generateCertificateNamesAndLocales(certificate);
    });
  }

  getLocaleIcon(locale: string): string {
    return getUnicodeFlagIcon(
      locale === 'en'
        ? 'GB'
        : locale.includes('-')
        ? locale.split('-')[1]
        : locale
    );
  }

  getLocaleNameByCode(code: string) {
    if (
      this.supportedLocales.filter(element => element.locale === code).length >
      0
    ) {
      return this.supportedLocales.filter(element => element.locale === code)[0]
        .name;
    }
    return code;
  }

  setSelectedLanguage(locale: string) {
    this.selectedLanguage = locale;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
