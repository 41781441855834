import { Pipe, PipeTransform } from '@angular/core';
import { BusinessService } from '../../services/business.service';
import { TranslateService } from '@ngx-translate/core';
import { IBaseBusinessPermissionAndGroupEntity } from '../../models/business/business.enum';

@Pipe({
  name: 'businessPermissionGroupCodeToLabel',
  pure: false,
})
export class BusinessPermissionGroupCodeToLabelPipe implements PipeTransform {
  constructor(
    private businessService: BusinessService,
    private translateService: TranslateService
  ) {}

  transform(groupCode?: string, props?: 'desc'): string {
    if (!groupCode) {
      return '';
    }
    let term: string | undefined = undefined;

    const permissionGroup: IBaseBusinessPermissionAndGroupEntity | undefined =
      this.businessService.findIremboPermissionGroupEntityByCode(groupCode);

    if (props === 'desc') {
      term = permissionGroup?.description;
    } else {
      term = permissionGroup?.label;
    }
    return term ? this.translateService.instant(term) : '';
  }
}

@Pipe({
  name: 'businessPermissionCodeToLabel',
})
export class BusinessPermissionCodeToLabelPipe implements PipeTransform {
  constructor(
    private businessService: BusinessService,
    private translateService: TranslateService
  ) {}

  transform(permissionCode?: string, props?: 'desc'): string {
    if (!permissionCode) {
      return '';
    }
    let term: string | undefined = undefined;

    const permission: IBaseBusinessPermissionAndGroupEntity | undefined =
      this.businessService.findIremboPermissionEntityByCode(permissionCode);

    if (props === 'desc') {
      term = permission?.description;
    } else {
      term = permission?.label;
    }
    return term ? this.translateService.instant(term) : '';
  }
}
