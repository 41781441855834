import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import {
  NgbModal,
  NgbModalConfig,
  NgbOffcanvas,
} from '@ng-bootstrap/ng-bootstrap';
import { finalize, Subscription } from 'rxjs';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';
import { ApplicationsService } from '../../services/applications.service';
import {
  applicationFormBuildPreviewData,
  applicationStatusEnumToColorUtil,
  applicationStatusEnumToLabelUtil,
  applicationStatusValueOf,
  IApplication,
  IApplicationDetails,
  IApplicationFormData,
  IFeedbackResponseDetails,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { UserProfileService } from '../../services/user-profile.service';
import {
  IdentificationType,
  IUserProfile,
} from '../../models/user-profile.model';
import { environment } from '../../../environments/environment';
import { VerifyIdentityComponent } from '../verify-identity/verify-identity.component';
import { IChangePasswordRequest } from '../../models/changePassword/change-password-request';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ICertificate } from '../../models/certificates.model';
import { DocumentsService } from '../../services/documents.service';
import { ICertificateDownload } from '../../models/certificate-download.model';
import { DomSanitizer } from '@angular/platform-browser';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ILocale } from '../../models/locale/locale.model';
import { LocaleService } from '../../services/locale.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { PaymentService } from '../../services/payment.service';
import { DOCUMENT } from '@angular/common';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { IOfficerAttachment } from '../../models/officer-attachment.model';
import { VerifyBusinesResponseDTO } from '../../models/business/business.model';
import { UserBusinessService } from '../../services/user-business.service';

declare const IremboPay: any;

enum documentMode {
  preview = 'PREVIEW',
  download = 'DOWNLOAD',
}

@Component({
  selector: 'irembogov-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  keycloakProfile: KeycloakProfile = {};
  isLoggedIn = false;
  isCollapsed = false;
  applicationsList: IApplication[] = [];
  totalApplications = 0;
  isLoadingApplications = false;
  applicationDetails: IApplicationDetails | undefined;
  isLoadingApplicationDetails = false;
  identificationNumber = '';
  doneLoadingProfile = false;
  appHasTracker = false;
  selectedApplication: IApplication | undefined;
  downloadingReceipt = false;
  searchTerm = '';
  isLoadingCertificates = false;
  certificatesList: Record<string, ICertificate[]> = {};
  expiredCertificatesList: ICertificate[] = [];
  expiringCertificatesList: ICertificate[] = [];
  showCategoryCertificates: Record<string, boolean> = {};
  selectedCertificate: ICertificate | undefined;
  selectedAttachment: IOfficerAttachment | undefined;
  filePath: string | undefined;
  isPreviewingCertificate = false;
  isDownloadingCertificate = false;
  downloadedCertificate: ICertificateDownload | undefined;
  downloadedAttachment: ICertificateDownload | undefined;
  applicationSummary: IApplicationFormData[] = [];

  userProfile: IUserProfile = {
    userId: '',
    identificationType: IdentificationType.CITIZEN_APPLICATION_NUMBER,
    identificationNumber: '',
    accountVerified: false,
    notificationPhoneNumber: '',
    notificationEmail: '',
    notificationType: {
      PHONE_NUMBER: true,
      EMAIL: false,
    },
  };
  onlinePaymentActivated = false;
  paymentComplete = false;
  billId: string | undefined = '';
  applicationBaseUrl = environment.apiGatewayBaseUrl;
  applicationPath = '/application/v1/file-manager/application/attachment';

  showMobileApplicationList = false;
  selectedTab = 'apps';
  base64Data: string | undefined;
  supportedLocales: ILocale[] = [];
  selectedCertificateNames: any[] = [];
  certificateNames: Record<string, Record<string, string>[]> = {};
  searchDocTerm = '';
  isLoadingDocuments = false;
  documentsList = [];
  updatePasswordForm!: FormGroup;
  changingPassword = false;
  showCurrentPassword = false;
  showNewPassword = false;
  showConfirmPassword = false;
  viewSummary = false;
  viewSummaryText = 'View application summary';
  categories: string[] = [];
  selectedCategory = 'View all';
  filteredCertificates: ICertificate[] = [];
  filteredAttachments: IOfficerAttachment[] = [];
  showVerifyBanner = true;
  documentMode = documentMode;
  selectedLanguage: string | null = null;
  languageMode: documentMode = documentMode.download;
  officerAttachmentsList: Record<string, IOfficerAttachment[]> = {};
  allowedAttachmentPreviewTypes = ['pdf', 'png', 'jpg', 'jpeg'];
  selectedApplicationTypeTab = 'personal';
  locale = '';

  constructor(
    private modalService: NgbModal,
    config: NgbModalConfig,
    private keycloak: KeycloakService,
    private applicationsService: ApplicationsService,
    private documentsService: DocumentsService,
    private toastService: ToastService,
    private userProfileService: UserProfileService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private localeService: LocaleService,
    private fb: FormBuilder,
    private paymentService: PaymentService,
    @Inject(DOCUMENT) private _document: Document,
    private offcanvasService: NgbOffcanvas,
    private userBusinessService: UserBusinessService
  ) {
    config.animation = false;
    config.centered = true;

    this.route.queryParams.subscribe(params => {
      const paramSection: string | null = params['section'];
      if (paramSection && ['personal', 'business'].includes(paramSection)) {
        this.selectedApplicationTypeTab = paramSection;
      }
    });
  }

  viewSummaryToggle() {
    this.viewSummary = !this.viewSummary;
    if (this.viewSummary) {
      this.viewSummaryText = 'Hide application summary';
    } else {
      this.viewSummaryText = 'View application summary';
    }
  }

  get userInitialsAndName() {
    const userDetails = {
      initials: '',
      names: '',
    };
    if (!this.keycloakProfile.firstName && !this.keycloakProfile.lastName) {
      if (this.keycloakProfile.username) {
        userDetails.initials = this.keycloakProfile.username.charAt(0);
        userDetails.names = this.keycloakProfile.username;
      }
    }

    if (this.keycloakProfile.firstName) {
      userDetails.initials = this.keycloakProfile.firstName.charAt(0);
      userDetails.names = this.keycloakProfile.firstName + ' ';
    }

    if (this.keycloakProfile.lastName) {
      userDetails.initials =
        userDetails.initials + this.keycloakProfile.lastName.charAt(0);
      userDetails.names = userDetails.names + this.keycloakProfile.lastName;
    }

    const formattedName = userDetails.names.split(' ');
    userDetails.names =
      formattedName.length == 1
        ? formattedName[0]
        : `${formattedName[0]} ${formattedName[1]}`;

    return userDetails;
  }

  async ngOnInit(): Promise<void> {
    this.userBusinessService.fetchAndSetUserServices();
    this.locale = localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    if (this.isLoggedIn)
      this.keycloakProfile = await this.keycloak.loadUserProfile();
    this.getSupportedLocales();
    this.getUserProfile();
    this.getApplications(1, 10, 'dateCreated,desc', this.searchTerm);
    this.getActiveCertificates();
    this.getExpiredCertificates();
    this.getOfficersAttachments();

    this.updatePasswordForm = new FormGroup(
      {
        currentPassword: new FormControl('', Validators.required),
        newPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
        confirmPassword: new FormControl('', Validators.required),
      },
      { validators: this.passwordMatchValidator }
    );

    this.subscriptions.add(
      this.breakpointObserver
        .observe(['(min-width: 576px)'])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.showMobileApplicationList = false;
          } else {
            this.showMobileApplicationList = true;
          }
        })
    );
  }

  passwordMatchValidator: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    const newPassword = group.get('newPassword')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return newPassword === confirmPassword ? null : { passwordMismatch: true };
  };

  togglePasswordVisibility(field: string) {
    if (field === 'currentPassword') {
      this.showCurrentPassword = !this.showCurrentPassword;
    } else if (field === 'newPassword') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'confirmPassword') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }

  onSubmit() {
    if (this.updatePasswordForm.valid) {
      const currentPassword =
        this.updatePasswordForm.get('currentPassword')?.value;
      const newPassword = this.updatePasswordForm.get('newPassword')?.value;
      this.changingPassword = true;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      const changePasswordRequest: IChangePasswordRequest = {
        username: this.keycloakProfile.username as string,
        currentPassword,
        newPassword,
        usernameType: emailRegex.test(this.keycloakProfile.username || '')
          ? 'EMAIL_ADDRESS'
          : 'PHONE_NUMBER',
        userType: 'CITIZEN',
        clientId: environment.authClientId,
        locale: this.locale,
      };
      this.userProfileService.changePassword(changePasswordRequest).subscribe({
        next: () => {
          this.toastService.show({
            type: 'success',
            body: 'Your password has been succesfully updated.',
            delay: 5000,
          });
          this.changingPassword = false;
          this.updatePasswordForm.reset();
          this.modalService.dismissAll();
        },
        error: error => {
          if (error.error.responseCode === 'INVALID_CREDENTIALS') {
            this.toastService.show({
              body: 'Password change failed. Ensure the existing password is correct and the new password is different.',
              type: 'error',
              delay: 5000,
            });
          } else if (error.error.responseCode === 'PASSWORD_POLICY_ERROR') {
            this.toastService.show({
              body: 'Invalid password: It must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a special character. It should also not be the same as a previously used password.',
              type: 'error',
              delay: 5000,
            });
          } else {
            this.toastService.show({
              type: 'error',
              body:
                error.error.message || 'Error occured while updating password.',
              delay: 5000,
            });
          }
          this.changingPassword = false;
        },
      });
    }
  }

  getSupportedLocales() {
    this.localeService.getSupportedLocales().subscribe({
      next: response => {
        this.supportedLocales = response.data.content;
      },
      error: error => {
        this.toastService.show({
          body: error.error.responseMessage ?? error.error.message,
          type: 'error',
          delay: 5000,
        });
      },
    });
  }

  getUserProfile() {
    this.subscriptions.add(
      this.userProfileService
        .getUserProfile()
        .pipe(finalize(() => (this.doneLoadingProfile = true)))
        .subscribe({
          next: res => {
            this.userProfile = res;
          },
          error: () => {
            this.toastService.show({
              body: 'Error loading notification preferences',
              type: 'error',
            });
          },
        })
    );
  }

  trackByApplicationId(index: number, item: IApplication) {
    return item.applicationId;
  }

  getApplications(
    page: number,
    size: number,
    sort: string,
    searchTerm: string
  ) {
    this.isLoadingApplications = true;
    this.subscriptions.add(
      this.applicationsService
        .getApplications({ page, size, sort, searchTerm })
        .subscribe({
          next: res => {
            this.isLoadingApplications = false;
            this.applicationsList = res.data.content;
            this.totalApplications = res.data.totalElements;
          },
          error: () => {
            this.toastService.show({
              body: 'Error loading applications',
              type: 'error',
            });
            this.isLoadingApplications = false;
          },
        })
    );
  }

  getOfficersAttachments() {
    this.isLoadingCertificates = true;
    this.subscriptions.add(
      this.applicationsService.getOfficersAttachments().subscribe({
        next: res => {
          this.isLoadingCertificates = false;
          this.officerAttachmentsList = res.data;
          this.categories = [
            ...new Set([
              ...this.categories,
              ...Object.keys(this.officerAttachmentsList),
            ]),
          ];
          this.filterDocuments(this.selectedCategory || 'View all');
        },
        error: () => {
          this.toastService.show({
            body: 'Error loading officers attachments',
            type: 'error',
          });
          this.isLoadingCertificates = false;
        },
      })
    );
  }

  getActiveCertificates() {
    this.isLoadingCertificates = true;
    this.subscriptions.add(
      this.applicationsService.getActiveCertificates().subscribe({
        next: res => {
          this.isLoadingCertificates = false;
          this.certificatesList = res.data;
          this.generateActiveCertificateNamesAndLocales();
          this.categories = [
            ...new Set([
              'View all',
              ...this.categories,
              ...Object.keys(this.certificatesList),
            ]),
          ];

          this.filterDocuments(this.selectedCategory || 'View all');
        },
        error: () => {
          this.toastService.show({
            body: 'Error loading active certificates',
            type: 'error',
          });
          this.isLoadingCertificates = false;
        },
      })
    );
  }

  getExpiredCertificates() {
    this.isLoadingCertificates = true;
    this.subscriptions.add(
      this.applicationsService.getExpiredCertificates().subscribe({
        next: res => {
          this.isLoadingCertificates = false;
          this.certificatesList['expired'] = res.data;
          this.filterDocuments(this.selectedCategory || 'View all');
        },
        error: () => {
          this.toastService.show({
            body: 'Error loading expired certificates',
            type: 'error',
          });
          this.isLoadingCertificates = false;
        },
      })
    );
  }

  filterDocuments(category: string) {
    this.selectedCategory = category;
    if (this.selectedCategory === 'View all') {
      this.filteredCertificates = Object.values(this.certificatesList).flat();
      this.filteredAttachments = Object.values(
        this.officerAttachmentsList
      ).flat();
    } else {
      this.filteredCertificates =
        this.certificatesList[this.selectedCategory] || [];
      this.filteredAttachments =
        this.officerAttachmentsList[this.selectedCategory] || [];
    }
  }

  onEnterInSearchInput() {
    this.getApplications(0, 10, 'dateCreated,desc', this.searchTerm);
  }

  //TODO: Search doc
  onEnterInSearchDocInput() {
    console.log('search');
  }

  getApplicationStatusToLabel(applicationStatus: string): string {
    return applicationStatusEnumToLabelUtil(
      applicationStatusValueOf(applicationStatus)
    );
  }

  handlePaginate(event: { pageNumber: number; pageSize: number }) {
    this.getApplications(
      event.pageNumber - 1,
      event.pageSize,
      'dateCreated,desc',
      this.searchTerm
    );
  }

  getApplicationStatusToColor(applicationStatus: string): string {
    return applicationStatusEnumToColorUtil(
      applicationStatusValueOf(applicationStatus)
    );
  }

  getApplicationStatusPulseToBackgroundColor(applicationStatus: string) {
    const color = applicationStatusEnumToColorUtil(
      applicationStatusValueOf(applicationStatus)
    );
    return `bg-${color}`;
  }

  previewCertificate(
    content: TemplateRef<unknown>,
    certificate: ICertificate,
    locale: string
  ) {
    this.selectedCertificate = certificate;
    this.isPreviewingCertificate = true;
    this.subscriptions.add(
      this.documentsService
        .downloadCertificate(certificate?.certificateNames[locale])
        .subscribe({
          next: res => {
            this.isPreviewingCertificate = false;
            this.downloadedCertificate = res;
            const reader = new FileReader();
            reader.readAsDataURL(
              this.base64ToBlob(res?.file, res?.contentType)
            );
            reader.onload = () => {
              this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(
                reader.result as string
              ) as string;
              this.base64Data = reader.result as string;
            };
          },
          error: () => {
            this.toastService.show({
              body: 'Error previewing certificate',
              type: 'error',
            });
            this.isPreviewingCertificate = false;
          },
        })
    );
    this.modalService.open(content, {
      ariaLabelledBy: 'preview-document',
      size: 'xl',
    });
  }

  previewAttachment(
    content: TemplateRef<unknown>,
    attachmentObj: Record<string, IOfficerAttachment>
  ) {
    this.selectedAttachment = attachmentObj['attachment'];
    this.isPreviewingCertificate = true;
    this.subscriptions.add(
      this.applicationsService
        .downloadAttachment(
          this.selectedAttachment.applicationId,
          this.selectedAttachment.fileName
        )
        .subscribe({
          next: res => {
            this.isPreviewingCertificate = false;
            this.downloadedAttachment = {
              file: '',
              contentType: res.headers.get('Content-Type') ?? 'application/pdf',
            };

            const reader = new FileReader();
            reader.readAsDataURL(res.body as Blob);
            reader.onload = () => {
              this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(
                reader.result as string
              ) as string;
              this.base64Data = reader.result as string;
            };
          },
          error: () => {
            this.toastService.show({
              body: 'Error previewing attachment',
              type: 'error',
            });
            this.isPreviewingCertificate = false;
          },
        })
    );
    this.modalService.open(content, {
      ariaLabelledBy: 'preview-document',
      size: 'xl',
    });
  }

  downloadAttachment(attachment: Record<string, IOfficerAttachment>) {
    this.selectedAttachment = attachment['attachment'];
    this.isDownloadingCertificate = true;
    this.subscriptions.add(
      this.applicationsService
        .downloadAttachment(
          this.selectedAttachment.applicationId,
          this.selectedAttachment.fileName
        )
        .subscribe({
          next: res => {
            this.downloadedAttachment = {
              file: '',
              contentType: res.headers.get('Content-Type') ?? 'application/pdf',
            };
            this.downloadBlob(
              this.selectedAttachment?.serviceName +
                ' - ' +
                this.selectedAttachment?.applicationNumber +
                this.selectedAttachment?.fileName.split('.').pop(),
              res.body as Blob
            );
          },
          error: () => {
            this.toastService.show({
              body: 'Error downloading attachment',
              type: 'error',
            });
            this.isDownloadingCertificate = false;
          },
        })
    );
  }

  base64ToBlob(str: string, type: string) {
    // decode base64
    const content = atob(str);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const buffer = new ArrayBuffer(content.length);
    const view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (let n = 0; n < content.length; n++) {
      view[n] = content.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    const blob = new Blob([buffer], { type: type });

    return blob;
  }

  downloadCertificate(certificate: ICertificate, locale: string) {
    this.isDownloadingCertificate = true;
    this.subscriptions.add(
      this.documentsService
        .downloadCertificate(certificate?.certificateNames[locale])
        .subscribe({
          next: res => {
            this.downloadedCertificate = res;
            this.downloadBlob(
              certificate?.serviceName +
                ' - ' +
                certificate.applicationNumber +
                '.pdf',
              this.base64ToBlob(res?.file, res?.contentType)
            );
            this.isDownloadingCertificate = false;
            this.modalService.dismissAll();
            this.selectedLanguage = null;
          },
          error: () => {
            this.toastService.show({
              body: 'Error downloading certificate',
              type: 'error',
            });
            this.isDownloadingCertificate = false;
            this.modalService.dismissAll();
            this.selectedLanguage = null;
          },
        })
    );
  }

  downloadBlob(fileName: string, blob: Blob): void {
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
    this.isDownloadingCertificate = false;
  }

  // Allow user to verify account
  openVerifyModal(userId: string) {
    const openModalRef = this.modalService.open(VerifyIdentityComponent, {
      backdrop: 'static',
      fullscreen: true,
    });
    openModalRef.componentInstance.userId = userId;

    openModalRef.result
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        console.log('There was an error: ', error);
      });
  }

  //change password
  openChangePassword(content: TemplateRef<unknown>) {
    this.modalService.open(content);
  }

  //view applications details
  openApplicationDetails(
    content: TemplateRef<unknown>,
    application: IApplication
  ) {
    this.selectedApplication = application;
    this.getApplicationDetails(application?.applicationId);
    this.setPaymentV2Script();
    this.offcanvasService.open(content, {
      position: 'end',
      panelClass: 'offcanvas-application',
    });
  }

  getApplicationDetails(id: string | undefined) {
    this.applicationDetails = undefined;
    if (id !== undefined) {
      this.isLoadingApplicationDetails = true;
      this.subscriptions.add(
        this.applicationsService.getApplicationDetailsById(id).subscribe({
          next: res => {
            this.applicationDetails = res;
            this.billId = this.applicationDetails?.billId;
            if (this.applicationDetails) {
              if (
                this.applicationDetails?.applicationFeedbacks
                  ?.feedbackResponseDetails
              ) {
                this.applicationDetails.applicationFeedbacks[
                  'feedbackResponseDetails'
                ] =
                  this.applicationDetails?.applicationFeedbacks?.feedbackResponseDetails?.filter(
                    feedBack => feedBack.comment != null
                  );
              }

              this.applicationSummary = this.getApplicationSummary(
                this.applicationDetails
              );
            }
            this.isLoadingApplicationDetails = false;
          },
          error: () => {
            this.toastService.show({
              body: 'Error loading application details',
              type: 'error',
            });
            this.isLoadingApplicationDetails = false;
          },
        })
      );
    }
  }

  getPaymentReceipt(applicationNumber: string | undefined) {
    if (applicationNumber !== undefined) {
      this.downloadingReceipt = true;
      this.subscriptions.add(
        this.paymentService.getPaymentReceipt(applicationNumber).subscribe({
          next: res => {
            this.downloadingReceipt = false;
            const source = `data:` + res?.contentType + `;base64,${res?.file}`;
            const link = document.createElement('a');
            link.href = source;
            link.download = applicationNumber + `_payment_receipt.pdf`;
            link.click();
          },
          error: () => {
            this.downloadingReceipt = false;
            this.toastService.show({
              body: 'Error downloading payment receipt',
              type: 'error',
            });
          },
        })
      );
    }
  }

  setPaymentV2Script(): void {
    if (this._document.getElementById('iremboPay')) {
      this.onlinePaymentActivated = true;
      return;
    }
    const iremboPayCdnScript = this._document.createElement('script');
    iremboPayCdnScript.id = 'iremboPay';
    iremboPayCdnScript.src = environment.IREMBOPAY_CDN;
    iremboPayCdnScript.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(iremboPayCdnScript);
    iremboPayCdnScript.onload = () => {
      this.onlinePaymentActivated = true;
    };
  }

  makePayment() {
    this.getPaymentPublicKey(this.applicationDetails?.merchant).then(
      publicKey => {
        if (publicKey) {
          IremboPay.initiate({
            publicKey: publicKey,
            invoiceNumber: this.billId,
            locale: IremboPay.locale.EN,
            isTest: environment.IREMBOPAY_TEST,
            callback: (err: unknown, resp: Record<string, string>) => {
              if (!err) {
                IremboPay.closeModal();
                this.paymentComplete = true;
                this.toastService.show({
                  body: resp['message'],
                  type: 'success',
                });
                this.getApplicationDetails(
                  this.selectedApplication?.applicationId
                );
                this.getApplicationDetails(
                  this.selectedApplication?.applicationId
                );
              }
            },
          });
        }
      }
    );
  }

  getPaymentPublicKey(merchant: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      if (merchant !== undefined) {
        this.subscriptions.add(
          this.applicationsService.getPaymentPublicKey(merchant).subscribe({
            next: res => {
              const publicKey = res.data.publicKey;
              resolve(publicKey);
            },
          })
        );
      } else {
        reject();
      }
    });
  }

  convertDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/');
    return new Date(+year, +month - 1, +day);
  }

  getApplicationSummary(
    applicationDetails: IApplicationDetails
  ): IApplicationFormData[] {
    return applicationFormBuildPreviewData(
      applicationDetails.formDefinition.fields,
      applicationDetails.applicationSummary
    );
  }

  getActiveFeedback(
    feedBack: IFeedbackResponseDetails[]
  ): IFeedbackResponseDetails[] {
    return feedBack.filter(x => x.activeFeedback);
  }

  openLanguageSelectorModal(
    content: TemplateRef<unknown>,
    certificate: ICertificate,
    mode: documentMode,
    previewContent?: TemplateRef<unknown | undefined>
  ) {
    this.selectedCertificate = certificate;
    this.languageMode = mode;
    if (mode === this.documentMode.download) {
      this.modalService.open(content);
    }
    if (mode === this.documentMode.preview) {
      this.modalService
        .open(content)
        .result.then((result: string) => {
          if (result) {
            this.selectedLanguage = null;
            this.modalService.dismissAll();
            if (previewContent) {
              this.previewCertificate(previewContent, certificate, result);
            }
          }
        })
        .catch(() => {
          // Modal was dismissed
          this.selectedLanguage = null;
        });
    }
  }

  generateCertificateNamesAndLocales(certificate: ICertificate) {
    const selectedCertificateNames: Record<string, string>[] = [];
    const certificateNames = certificate.certificateNames;
    if (
      certificateNames !== null &&
      typeof certificateNames === 'object' &&
      !Array.isArray(certificateNames)
    ) {
      Object.entries(certificate.certificateNames).forEach(element => {
        selectedCertificateNames.push({
          locale: element[0],
          localeFlag: this.getLocaleIcon(element[0]),
          localeName: this.getLocaleNameByCode(element[0]),
          certificateName: element[1],
        });
      });
    }
    return selectedCertificateNames;
  }

  generateActiveCertificateNamesAndLocales() {
    Object.entries(this.certificatesList).forEach(entry => {
      entry[1].forEach(certificate => {
        this.certificateNames[certificate.applicationNumber] =
          this.generateCertificateNamesAndLocales(certificate);
      });
    });
  }

  getLocaleIcon(locale: string): string {
    return getUnicodeFlagIcon(
      locale === 'en'
        ? 'GB'
        : locale.includes('-')
        ? locale.split('-')[1]
        : locale
    );
  }

  getLocaleNameByCode(code: string) {
    this.supportedLocales.find(element => element.locale === code)?.name ??
      code;

    if (
      this.supportedLocales.filter(element => element.locale === code).length >
      0
    ) {
      return this.supportedLocales.filter(element => element.locale === code)[0]
        .name;
    }
    return code;
  }

  setSelectedLanguage(locale: string) {
    this.selectedLanguage = locale;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  editApplication(
    applicationId: string,
    applicationStatus: string,
    applicationNumber: string,
    serviceId: string,
    serviceName: string
  ) {
    const id = serviceId ?? '';
    const keyword = serviceName ?? '';
    const appId = applicationId ?? '';
    const appStatus = applicationStatus ?? '';
    const appNumber = applicationNumber ?? '';
    this.offcanvasService.dismiss();
    this.router.navigate(['application/apply'], {
      queryParams: { id, keyword, appId, appStatus, appNumber },
    });
  }

  onAddBusinessSuccess($event: VerifyBusinesResponseDTO) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        businessId: $event.id ?? '0',
        businessName: $event.name,
      },
    };
    this.router.navigate(['business', 'success'], navigationExtras);
  }

  onCancelLanguageSelector() {
    this.selectedLanguage = null;
    this.modalService.dismissAll();
  }
}

function getPaymentPublicKey(merchant: any, arg1: number) {
  throw new Error('Function not implemented.');
}

function merchant(merchant: any, arg1: number) {
  throw new Error('Function not implemented.');
}
