<div class="irembogov-manage-business-teams">
  <div class="inner-container mb-4">
    <div class="row mt-4">
      <div class="col-md-12">
        <a
          class="back-button text-decoration-none d-flex flex-row align-items-center mb-2"
          [routerLink]="['/profile']"
          [queryParams]="{ section: 'business' }">
          <i class="icon-chevron-left me-2"></i>
          <span class="d-inline d-md-none">{{ 'Back' | translate }}</span>
          <span class="d-none d-md-inline">{{
            'Back to profile' | translate
          }}</span>
        </a>

        <div
          class="page-header d-flex flex-column flex-md-row justify-content-start justify-content-md-between">
          <span class="h3">{{ 'Team Management' | translate }}</span>
          <button
            *ngIf="
              business?.availablePermissions?.includes('ADD_BUSINESS_USER')
            "
            class="btn btn-primary px-3 align-items-center invite-button"
            (click)="initiateNewMemberInvite()">
            <i class="icon-user-plus-01 me-1"></i>
            Invite new team member
          </button>
        </div>
      </div>
    </div>
    <div class="row business-teams-manager">
      <div class="col-md-12">
        <div class="business-teams-manager-view col-12">
          *<ng-container
            *ngIf="
              business?.availablePermissions?.includes(
                'APPROVE_BUSINESS_USER_REQUEST'
              ) ||
              business?.availablePermissions?.includes(
                'REJECT_BUSINESS_USER_REQUEST'
              )
            ">
            <!-- Manage User Requests -->
            <div
              class="manage-section mb-3 mt-0 mt-md-3 py-3 py-md-5 d-flex flex-column flex-md-row gap-3">
              <div class="manage-section-summary col-12 col-md-3">
                <div class="title">Pending access requests</div>
                <div class="description">
                  You can review each request, approve or deny it.
                </div>
              </div>
              <div class="manage-section-list col-12 col-md-9">
                <irembogov-list-business-user-requests
                  [businessId]="
                    businessId
                  "></irembogov-list-business-user-requests>
              </div>
            </div>
          </ng-container>
          <!-- Manage Team members -->
          <div
            class="manage-section my-3 py-3 py-md-5 d-flex flex-column flex-md-row gap-3">
            <div class="manage-section-summary col-12 col-md-3">
              <div class="title">Team members</div>
              <div class="description">
                Manage your existing team members and change roles/permissions.
              </div>
            </div>
            <div class="manage-section-list col-12 col-md-9">
              <irembogov-list-business-users
                [parentReloadSubject]="reloadBusinessUsersSubject"
                [businessId]="businessId"></irembogov-list-business-users>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #inviteNewBusinessUserModal let-modal>
  <form [formGroup]="newUserForm">
    <div class="modal-header d-flex align-items-start border-0">
      <div class="d-flex flex-column">
        <h4 class="modal-title mt-2" id="modal-invite-business-user-title">
          {{ 'Invite team member' | translate }}
        </h4>
      </div>
      <button
        type="button"
        class="btn mt-1 btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body pt-0">
      <irembogov-irembo-section-message
        *ngIf="showUserInvitedMessage"
        title="{{ 'User has been invited.' | translate }}"
        message="{{
          'The user you are trying to add does not have an account on Irembo. An invitation to create an account has been sent to them.'
            | translate
        }}"
        type="info"></irembogov-irembo-section-message>

      <ng-container *ngIf="!showUserInvitedMessage">
        <div class="form-group username-form-group w-100">
          <label for="username">{{
            'Email or phone number' | translate
          }}</label>
          <input
            type="text"
            class="form-control w-100"
            id="username"
            formControlName="username"
            placeholder="{{
              'Enter a phone number or email address' | translate
            }}" />
          <small
            class="w-100 text-danger"
            *ngIf="
              newUserForm.get('username')?.invalid &&
              (newUserForm.get('username')?.touched ||
                newUserForm.get('username')?.dirty)
            ">
            {{
              'Enter a phone number ( 078 XXX XXX) or email address' | translate
            }}
          </small>
        </div>

        <div class="permission-groups-form-group">
          <div class="permission-groups-top-section">
            <div class="main-text w-100">{{ 'User Permissions' }}</div>
            <small class="sub-text w-100">{{
              'Select the permission level for the new user' | translate
            }}</small>
          </div>

          <div class="w-100 permission-group-options d-flex flex-column gap-2">
            <div
              *ngFor="let permissionGroup of iremboBusinessPermissionGroups"
              class="permission-group-option-item w-100 my-1 d-flex align-items-start gap-2">
              <button
                class="button-circle p-0"
                type="button"
                (click)="setSelectedPermissionGroup(permissionGroup.code)">
                <i
                  class="cursor-pointer fa-regular {{
                    newUserForm.get('permissionGroup')?.value ===
                    permissionGroup.code
                      ? 'fa-circle-dot selected'
                      : 'fa-circle'
                  }}"></i>
              </button>
              <div
                class="option-label-details d-flex flex-column align-items-start">
                <div class="permission-group-label">
                  {{ permissionGroup.label | translate }}
                </div>
                <div class="permission-group-description">
                  {{ permissionGroup.description | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div
      *ngIf="!showUserInvitedMessage"
      class="modal-footer d-flex flex-column-reverse flex-md-row gap-2 border-0 align-items-stretch">
      <button
        type="button"
        [disabled]="isInvitingNewUser"
        class="btn btn-lg cancel-btn btn-outline-dark text-decoration-none m-0"
        (click)="modal.dismiss('Cancel')">
        {{ 'Cancel' | translate }}
      </button>
      <button
        [disabled]="isInvitingNewUser || !newUserForm.valid"
        type="button"
        class="btn btn-lg btn-primary text-decoration-none m-0"
        (click)="inviteNewUser()">
        {{ 'Send invite' | translate }}
        <div
          *ngIf="isInvitingNewUser"
          class="spinner-border spinner-border-sm text-light"
          role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
    </div>
  </form>
</ng-template>
