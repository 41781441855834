<div class="w-100">
  <irembogov-verify-identity-banner
    *ngIf="showIdentityVerification"
    (cancelClicked)="cancelIdentityVerification()"
    (verifyUser)="verifyUserIdentity()"></irembogov-verify-identity-banner>
  <nav class="navbar navbar-expand-lg bg-blue-gradient citizen-navbar">
    <div class="container-fluid">
      <!-- Large devices (desktops, 992px and up) -->
      <a
        class="navbar-brand align-items-center d-none d-md-none d-lg-inline-flex d-xl-inline-flex"
        routerLink="/">
        <img
          height="25"
          width="109.37"
          src="../../../assets/img/irembogov-logo-new.svg"
          alt="Irembo logo" />
      </a>
      <!-- Small devices (landscape phones, 576px and up) -->
      <div
        class="d-flex flex-row-reverse d-md-flex flex-md-row-reverse justify-content-between align-items-centere w-100 d-lg-none d-xl-none">
        <a class="" routerLink="/">
          <img
            height="20"
            width="109.37"
            src="../../../assets/img/irembogov-logo-new.svg"
            alt="Irembo logo" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          (click)="open(mobileMenu, 'start')"
          aria-controls="irembo-navbar-1"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <i class="fa-solid fa-bars"></i>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="irembo-navbar-1">
        <ul class="navbar-nav mb-lg-0 nav-main-links">
          <li
            class="nav-item m-0 d-flex align-items-stretch justify-content-center"
            *ngFor="let item of navbarItems">
            <a
              class="toplink nav-link m-0 d-flex align-items-center gap-2"
              routerLink="{{ item.routerLink }}"
              [queryParams]="{ type: item.queryParams }"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }">
              <i class="{{ item.icon }}"></i>
              {{ item.name | translate }}
            </a>
          </li>
        </ul>

        <ul class="navbar-nav ms-auto mb-lg-0 d-flex gap-2">
          <li
            class="nav-item m-0 px-0 d-flex align-items-stretch justify-content-center">
            <a
              target="_blank"
              [href]="env.knowledgeBaseUrl"
              class="d-flex align-items-center text-decoration-none">
              <i class="icon-message-question-circle me-2"></i>
              {{ 'Support Center' | translate }}
            </a>
          </li>

          <li
            class="nav-item m-0 px-0 d-flex align-items-stretch justify-content-center">
            <irembogov-language-selector></irembogov-language-selector>
          </li>
          <ng-container *ngIf="!isLoggedIn">
            <li
              class="nav-item m-0 d-flex align-items-stretch justify-content-center login">
              <a
                (click)="onLogin()"
                class="btn btn-nav-primary btn-link text-decoration-none">
                {{ 'Log in' | translate }}
              </a>
            </li>
            <li
              class="nav-item m-0 d-flex align-items-stretch justify-content-center signup">
              <a
                *ngIf="!isExternalLink(signupLink)"
                [routerLink]="signupLink"
                target="{{ redirectSignup ? '_blank' : '_self' }}"
                class="btn btn-nav-primary text-white btn-link text-decoration-none">
                {{ 'Sign up' | translate }}
              </a>
              <a
                *ngIf="isExternalLink(signupLink)"
                [attr.href]="signupLink"
                target="{{ redirectSignup ? '_blank' : '_self' }}"
                class="btn btn-nav-primary text-white btn-link text-decoration-none">
                {{ 'Sign up' | translate }}
              </a>
            </li>
          </ng-container>
          <ng-container *ngIf="isLoggedIn">
            <li
              ngbDropdown
              class="nav-item d-flex align-items-center justify-content-between gap-2">
              <div class="flex-shrink-0 citizen-initials"></div>
              <div class="text-white">
                {{
                  keycloakProfile.firstName?.length
                    ? keycloakProfile.firstName +
                      ' ' +
                      (keycloakProfile.lastName ?? '')
                    : keycloakProfile.username
                }}
              </div>
              <button
                class="btn btn-icon user-profile-dd"
                id="userProfile_dd"
                ngbDropdownToggle>
                <i class="icon-chevron-down"></i>
              </button>
              <ul
                class="dropdown-menu dropdown-menu-end profile-dd"
                ngbDropdownMenu
                aria-labelledby="userProfile_dd">
                <li ngbDropdownItem>
                  <a [routerLink]="['/profile']" class="dropdown-link">
                    <i aria-hidden="true" class="icon-user-01 me-2"></i>
                    {{ 'View Profile' | translate }}
                  </a>
                </li>

                <hr class="dropdown-divider" />

                <li ngbDropdownItem>
                  <a
                    class="dropdown-link"
                    data-bs-toggle="modal"
                    data-bs-target="#confirmLogout"
                    (click)="logoutUser()">
                    <i class="icon-log-out-01 me-2"></i>
                    {{ 'Log out' | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </nav>
</div>
<ng-template id="logoutWarningContent" #logoutWarningContent let-modal>
  <div class="modal-header align-items-start p-4">
    <div class="check-icon">
      <i aria-hidden="true" class="icon-alert-triangle"></i>
    </div>

    <button
      type="button"
      class="btn-close text-muted"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body p-4 pb-0 pt-0">
    <h4 class="mb-3" id="confirm-modal-title">
      {{ 'Log out' | translate }}
    </h4>
    <p class="logout-warning irembo-dark-grey">
      {{ 'Are you sure you want to logout' | translate }}?
      {{ ' ' }}
      {{ 'All unsaved changes will be lost' | translate }}.
    </p>
  </div>

  <div class="modal-footer p-4 pt-0">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-6">
          <button
            type="button"
            class="btn btn-outline-dark w-100"
            aria-label="close"
            (click)="modal.close('cancel')">
            {{ 'No, Cancel' | translate }}
          </button>
        </div>
        <div class="col-6">
          <button
            type="button"
            class="btn btn-danger w-100"
            (click)="modal.close('logout')">
            {{ 'Yes, log out' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mobileMenu let-offcanvas>
  <div class="d-flex flex-column justify-content-between h-100">
    <div class="mt-3">
      <div
        class="d-flex flex-row justify-content-between align-items-center w-100">
        <div class="logo">
          <img
            height="25"
            src="https://irembo.gov.rw/assets/images/logos/irembo-gov.svg"
            alt="" />
        </div>
        <button
          type="button"
          class="btn-close btn-close-white"
          aria-label="Close"
          (click)="offcanvas.dismiss('Cross click')"></button>
      </div>
      <ul class="navbar-nav list-group mt-4" style="gap: 12px">
        <li *ngFor="let item of navbarItems">
          <a
            class="d-flex text-decoration-none mobile-nav-item"
            routerLink="{{ item.routerLink }}"
            [queryParams]="{ type: item.queryParams }">
            <i class="{{ item.icon }} me-2"></i>
            {{ item.name | translate }}
          </a>
        </li>
      </ul>
    </div>

    <div class="w-100">
      <ul class="navbar-nav list-group" style="gap: 12px">
        <li *ngIf="isLoggedIn">
          <a
            routerLink="/profile"
            class="d-flex text-decoration-none mobile-nav-item"
            ><i class="icon-user-01 me-2"></i>{{ 'Profile' | translate }}</a
          >
        </li>
        <li>
          <a
            [href]="env.knowledgeBaseUrl"
            class="d-flex text-decoration-none mobile-nav-item"
            ><i class="icon-message-question-circle me-2"></i
            >{{ 'Support' | translate }}</a
          >
        </li>
        <li class="p-0 mb-2">
          <irembogov-language-selector
            [styling]="'MOBILE'"></irembogov-language-selector>
        </li>
      </ul>
      <div class="vstack gap-3 mt-2 mb-3" *ngIf="!isLoggedIn">
        <button
          (click)="onRegister()"
          class="btn signup text-white text-center btn-link text-decoration-none"
          style="
            box-shadow: 0px 1px 2px 0px #1018280d;
            border: 1px solid #0063cf;
            height: 44px;
            padding: 10px 18px 10px 18px;
            background-color: #0063cf;
          ">
          {{ 'Sign up' | translate }}
        </button>
        <button
          (click)="onLogin()"
          class="btn text-black bg-white w-100 btn-link text-decoration-none"
          style="
            box-shadow: 0px 1px 2px 0px #1018280d;
            border: 1px solid #d0d5dd;
            height: 44px;
            padding: 10px 18px 10px 18px;
            gap: 8px;
            opacity: 0px;
          ">
          {{ 'Log in' | translate }}
        </button>
      </div>
      <div
        class="logout-section d-flex w-100 justify-content-between align-items-center mb-3"
        *ngIf="isLoggedIn">
        <div
          class="text-white fw-semibold d-flex flex-row align-items-center text-center flex-grow-1">
          <div class="bg-white rounded-circle text-center user-avatar me-2">
            <i class="icon-user-01"></i>
          </div>
          <span>{{
            keycloakProfile.firstName?.length
              ? keycloakProfile.firstName +
                ' ' +
                (keycloakProfile.lastName ?? '')
              : keycloakProfile.username
          }}</span>
        </div>
        <i class="icon-log-out-01" role="button" (click)="logoutUser()"></i>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #userProfileMenu let-offcanvas>
  <div class="user-profile-menu-container">
    <div class="offcanvas-header flex-row-reverse mt-3">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="offcanvas.dismiss('Cross click')"></button>
      <div class="offcanvas-title d-flex flex-row gap-2 align-items-center">
        <span class="fw-semibold citizen-initials">{{
          userInitialsAndName.initials
        }}</span>
        <h4 class="tt_norms500 m-0">
          {{ 'Hello' | translate }},
          {{
            keycloakProfile.firstName?.length
              ? keycloakProfile.firstName
              : keycloakProfile.username
          }}
        </h4>
      </div>
    </div>
    <div class="offcanvas-body">
      <ul class="navbar-nav mt-3 d-flex flex-column">
        <li class="nav-item mb-2">
          <a
            routerLink="/profile"
            class="nav-link px-3 d-flex align-items-center tt_norms500">
            <i aria-hidden="true" class="fa-regular fa-user me-2"></i>
            {{ 'Profile page' | translate }}
          </a>
        </li>

        <li class="nav-item mb-2 ms-4">
          <a
            class="nav-link nav-link-child px-3 d-flex align-items-center tt_norms500"
            routerLink="/profile"
            [queryParams]="{ tab: 'apps' }">
            <i aria-hidden="true" class="fa-regular fa-file me-2"></i>
            {{ 'My Application' | translate }}
          </a>
        </li>
        <li class="nav-item nav-link-child mb-2 ms-4">
          <a
            class="nav-link px-3 d-flex align-items-center tt_norms500"
            routerLink="/profile"
            [queryParams]="{ tab: 'docs' }">
            <i aria-hidden="true" class="fa-regular fa-file-lines me-2"></i>
            {{ 'My Document' | translate }}
          </a>
        </li>

        <hr class="nav-separator" />

        <li class="nav-item nav-link-logout">
          <a
            role="button"
            class="tt_norms500 px-3 d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#confirmLogout"
            (click)="logoutUser()">
            <i class="fa-solid fa-arrow-right-from-bracket me-2"></i>
            {{ 'Logout' | translate }}
          </a>
        </li>

        <hr class="nav-separator" />
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #verificationModal let-modal>
  <div class="verification-card rounded-3 shadow-sm p-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="">
        <img
          src="../../../assets/img/idenity.png"
          alt="identity"
          height="48"
          width="48" />
      </div>
      <div class="btn btn-close" (click)="modal.close('skip')"></div>
    </div>
    <div class="text-left">
      <h2>{{ 'Verifiy your idenity' | translate }}</h2>
      <p>
        {{
          "Once verified, you will be able to access all IremboGov's services."
            | translate
        }}
      </p>
      <div class="mb-3 time-text">
        <img
          src="../../../assets/img/check-green.png"
          alt=""
          class="me-2"
          height="24"
          width="24" />It only takes a minute
      </div>
      <div class="d-flex justify-content-between align-items-center gap-2">
        <button
          class="btn btn-skip bg-white w-50"
          (click)="modal.close('skip')">
          {{ 'Skip and do this later' | translate }}
        </button>
        <button
          class="btn btn-verify w-50"
          (click)="modal.close('start-verification')">
          {{ 'Start verification' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
