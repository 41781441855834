import { Component } from '@angular/core';

@Component({
  selector: 'irembogov-terms-use',
  templateUrl: './irembogov-terms-of-use.component.html',
  styleUrls: ['./irembogov-terms-of-use.component.scss'],
})
export class IrembogovTermsOfUseComponent {
  USER_RESPONSIBILITY_TEXT =
    'You must use <a href=&quot;https://www.irembo.gov.rw&quot;>www.irembo.gov.rw</a> and your account only for lawful purposes and in a manner that does not infringe the rights of or restrict or inhibit the use and enjoyment of the website by any third party.';
}
