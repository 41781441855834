<ng-container *ngTemplateOutlet="!file ? noFile : hasFile"></ng-container>

<ng-template #noFile>
  <div
    [ngClass]="{ 'cta-drag-enter': addDragClass }"
    class="file-upload-container noFile generic"
    (drop)="toogledragClass($event, false)"
    (dragenter)="toogledragClass($event, true)"
    (dragleave)="toogledragClass($event, false)">
    <input
      (click)="onClickFileUpload($event)"
      [accept]="acceptList()"
      type="file"
      (change)="change($event)"
      class="uploader file-upload-input"
      [disabled]="disabled" />

    <ng-container
      *ngTemplateOutlet="genericUploaderBasicTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #genericUploaderBasicTemplate>
  <div
    class="generic-file-uploader {{
      file ? 'hasFile flex-row align-items-center' : 'flex-column'
    }} border p-3 rounded d-flex align-items-center w-100">
    <div
      class="upload-icon d-flex justify-content-center align-items-center mb-2">
      <i
        class="fa-solid {{
          file
            ? 'fa-regular fa-file text-primary'
            : 'fa-cloud-arrow-up text-muted'
        }}"></i>
    </div>
    <div>
      <ng-container *ngIf="file">
        <button
          type="button"
          class="btn btn-link p-0 remove-file-button text-muted"
          ngbTooltip="Remove file"
          (click)="removeFile()">
          <i class="fa-regular fa-trash-alt" aria-hidden="true"></i>
        </button>
        <div class="tt_norms500" [ngClass]="{ hasErros: hasErrors }">
          <span *ngIf="file?.name">
            {{
              file.name.length < 25
                ? file.name
                : (file.name | slice : 0 : 25) + '...'
            }}
          </span>
          <span class="text-danger" *ngIf="hasErrors">
            <i class="fa fa-times-circle"></i>
          </span>
        </div>
        <div>
          {{ formatFileSizeBytesUtil(file?.size) }}
        </div>
      </ng-container>
      <ng-container *ngIf="!file">
        <input
          class="file-upload-input"
          [accept]="acceptList()"
          [id]="id + '_generic'"
          (change)="change($event)"
          type="file"
          [disabled]="disabled" />
        <label
          class="file-upload-input-label text-primary"
          [for]="id + '_generic'"
          >{{ noFileActionLabel | translate }}</label
        >&nbsp;
        <span *ngIf="!file" [innerHTML]="noFileSubLabelHtml"></span>
        <span
          *ngIf="showPlaceholder"
          class="w-100 tiny-placeholder text-center d-block">
          {{ placeholder }}
        </span>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #hasFile>
  <div class="file-upload-container generic">
    <ng-container
      *ngTemplateOutlet="genericUploaderBasicTemplate"></ng-container>
  </div>
</ng-template>
