import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { finalize, Subject, Subscription } from 'rxjs';
import { IEnvironment } from '../../../models/environment.model';
import { ToastService } from '../../../services/toast/toast.service';
import {
  IGroupTypes,
  ISlot,
  ISlotBookingConfig,
} from '../../../models/slot-booking.model';
import { IHttpPagedResponse } from '../../../../utils/models/http-paged-response.model';
import { updateUrlWithApiGatewayBaseUrl } from '../../../../utils/utils/data-fetch-widget-utils';
import moment from 'moment';

const GROUP_TYPES_URL = '/service/api/v1/schedules/{scheduleID}/group-types';
const SLOTS_URL = '/service/api/v1/schedules/{scheduleID}/slots';

@Component({
  selector: 'irembogov-custom-slot-booking',
  templateUrl: './custom-slot-booking.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSlotBookingComponent
  extends FieldType<FieldTypeConfig>
  implements OnDestroy, OnInit
{
  private environment: IEnvironment;
  private subscriptions = new Subscription();
  configs: ISlotBookingConfig[] = [];
  items: Record<string, Record<string, unknown>[] | null> = {};
  loading: Record<string, boolean> = {};
  subscribeSubject: Subject<void> = new Subject();
  scheduleId: string | undefined;
  pagination: Record<string, unknown> = {
    defaultSize: 100,
  };
  remainingSeats = 0;
  selectedGroupId: string | undefined;
  selectedTimeRangeId: string | undefined;
  validSlots: ISlot[] = [];
  invalidSlot = false;

  constructor(
    @Inject('environment') environment: IEnvironment,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private toastService: ToastService
  ) {
    super();
    this.environment = environment;
  }
  ngOnInit(): void {
    this.configs = this.field.props['configs'];
    this.scheduleId = this.field.props['scheduleId'];
  }

  handleActiveDropDown(event: ISlotBookingConfig) {
    this.invalidSlot = false;
    switch (event.index) {
      case 0:
        this.getGroupsWithGroupTypes(event);
        this.getAllValidSlots();
        break;
      case 1:
        this.getGroups(event);
        break;
      case 2:
        if (event?.value) {
          this.selectedGroupId = event?.value['id'] as string;
          this.setTimeRanges(this.selectedGroupId);
        }
        break;
      case 3:
        if (event?.value) {
          this.selectedTimeRangeId = event?.value['id'] as string;
        }
        this.getSlotDetails(this.selectedGroupId, this.selectedTimeRangeId);
        break;
    }
  }

  handleRFAEvent(formData: Record<string, unknown>) {
    let group = this.configs[1];
    const groupTypes = this.configs[0];
    const timeRange = this.configs[2];
    const seat = this.configs[3];

    const groupTypesValue = formData[groupTypes.key] as Record<string, unknown>;
    const timeRangeValue = formData[timeRange.key] as Record<string, unknown>;
    const groupValue = formData[group.key] as Record<string, unknown>;
    const seatValue = Number(formData[seat.key]);
    const convertedSeat = !isNaN(seatValue) ? seatValue : 0;

    group = { ...group, value: groupTypesValue };

    this.items = {
      ...this.items,
      [groupTypes.key]: [groupTypesValue],
    };

    this.getGroups(group);
    this.selectedTimeRangeId = timeRangeValue['id'] as string;
    this.selectedGroupId = groupValue['id'] as string;

    if (this.selectedGroupId && this.selectedTimeRangeId) {
      this.getSlotDetails(
        this.selectedGroupId,
        this.selectedTimeRangeId,
        convertedSeat
      );
    }
  }

  generateUrl(
    url: string,
    params: Record<string, unknown>,
    queryParams?: Record<string, unknown>
  ) {
    Object.keys(params).forEach(key => {
      url = url.replace(`{${key}}`, params[key] as string);
    });

    let formattedQueryParams = '';
    if (queryParams) {
      formattedQueryParams = Object.keys(queryParams)
        .map(key => `${key}=${encodeURIComponent(queryParams[key] as string)}`)
        .join('&');
    }
    const updateUrl = updateUrlWithApiGatewayBaseUrl(url, this.environment);
    return formattedQueryParams
      ? `${updateUrl}?${formattedQueryParams}`
      : `${updateUrl}`;
  }

  getGroupsWithGroupTypes(activeControl: ISlotBookingConfig) {
    if (!this.scheduleId) return;
    this.loading[activeControl.key] = true;
    const groupTypesAPI = this.generateUrl(
      GROUP_TYPES_URL,
      {
        scheduleID: this.scheduleId,
      },
      {
        page: 1,
        size: this.pagination['defaultSize'],
      }
    );

    this.subscriptions.add(
      this.http
        .get<IHttpPagedResponse<IGroupTypes>>(
          `${groupTypesAPI}&sort=dateCreated&sortDirection=DESC`
        )
        .pipe(
          finalize(() => {
            this.loading = { ...this.loading, [activeControl.key]: false };
            this.cd.detectChanges();
          })
        )
        .subscribe({
          next: res => {
            const groupTypes = res.data.content as unknown as Record<
              string,
              unknown
            >[];
            this.items = {
              ...this.items,
              [activeControl.key]: groupTypes,
            };
            this.cd.detectChanges();
          },
          error: () => {
            this.handleErrorMessage(activeControl.label);
            this.cd.detectChanges();
          },
        })
    );
  }

  getGroups(activeControl: ISlotBookingConfig) {
    const groupType = activeControl.parent;
    const selectedGroupType = activeControl.value;
    if (
      groupType &&
      Object.hasOwnProperty.call(this.items, groupType) &&
      selectedGroupType
    ) {
      const groupTypes = this.items[groupType] as unknown as IGroupTypes[];
      const groups = groupTypes.find(
        x => x.id === selectedGroupType['id']
      )?.groups;
      if (groups) {
        this.items = {
          ...this.items,
          [activeControl.key]: groups as unknown as Record<string, unknown>[],
        };
      }
      const groupConfigKey = this.configs[1].key;
      this.loading = { ...this.loading, [groupConfigKey]: false };
      this.cd.detectChanges();
    }
  }

  setTimeRanges(groupId: string) {
    const timeRanges = this.validSlots
      .filter(x => x.group.id === groupId)
      .map(x => {
        return {
          ...x.timeRange,
          name: this.formatDateTimeLabel(
            x.timeRange.fromDateTime,
            x.timeRange.toDateTime,
            x.timeRange.dateTimeFormat
          ),
        };
      })
      .filter(
        (timeRange, index, arr) =>
          index === arr.findIndex(t => t.id === timeRange.id)
      );

    this.invalidSlot = timeRanges.length === 0;
    const activeControl = this.configs[2];
    this.items = {
      ...this.items,
      [activeControl.key]: timeRanges as unknown as Record<string, unknown>[],
    };
  }

  getSlotDetails(groupId?: string, timeRangeId?: string, seatValue?: number) {
    const slotDetails = this.validSlots.find(
      x => x.timeRange.id === timeRangeId && x.group.id === groupId
    );
    this.remainingSeats = 0;
    if (slotDetails) {
      this.remainingSeats = slotDetails.remainingSeats;
      if (seatValue && seatValue > 0) {
        this.remainingSeats = this.remainingSeats + seatValue;
      }
    }
    this.cd.detectChanges();
  }

  getAllValidSlots() {
    if (!this.scheduleId) return;
    const slotsAPI = this.generateUrl(
      SLOTS_URL,
      {
        scheduleID: this.scheduleId,
      },
      {
        page: 0,
        size: this.pagination['defaultSize'],
      }
    );
    this.subscriptions.add(
      this.http
        .get<IHttpPagedResponse<ISlot>>(
          `${slotsAPI}&sort=dateCreated&sortDirection=DESC`
        )
        .subscribe({
          next: res => {
            this.validSlots = this.filterOutValidSlots(res.data.content);
            if (this.selectedGroupId) {
              this.setTimeRanges(this.selectedGroupId);
            }
            this.cd.detectChanges();
          },
          error: () => {
            this.handleErrorMessage('slots');
            this.cd.detectChanges();
          },
        })
    );
  }

  private filterOutValidSlots(slots: ISlot[]) {
    const now = new Date();

    return slots.filter(slot => {
      const start = new Date(slot.validFrom);
      const end = new Date(slot.validTo);

      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
      now.setHours(0, 0, 0, 0);

      return now >= start && now <= end;
    });
  }

  private formatDateTimeLabel(
    fromDateTime: string,
    toDateTime: string,
    dateFormat: string
  ) {
    const fromDate = moment(fromDateTime).format('MMM DD, yyyy');
    const toDate = moment(toDateTime).format('MMM DD, yyyy');
    const fromTime = moment(fromDateTime).format('hh:mm A');
    const toTime = moment(toDateTime).format('hh:mm A');

    const fullDate = `${fromDate}. ${fromTime} - ${toDate}. ${toTime}`;
    const shortDate = `${fromDate} - ${toDate}`;

    return dateFormat === 'dd-MM-yyyy HH:mm' ? fullDate : shortDate;
  }

  private handleErrorMessage(label: string) {
    this.toastService.show({
      body: `Error loading ${label}`,
      type: 'error',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscribeSubject.unsubscribe();
  }
}
