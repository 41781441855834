import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
  Inject,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { Subscription, distinctUntilChanged, fromEvent, finalize } from 'rxjs';
import { ExtractHttpErrorResponseMessage } from '../../../utils/utils/http-error-response-extractor.util';
import {
  defaultOtpDataFetchInputStatusMessages,
  EOtpDataFetchInputErrorKeys,
  EOtpDataFetchInputStatusMessageKeys,
  EOtpDataFetchStatus,
} from '../../config/custom-id-input-api-urls.config';
import { IEnvironment } from '../../models/environment.model';

@Component({
  selector: 'irembogov-otp-fetch-input',
  templateUrl: './irembo-otp-data-fetch-input.component.html',
  styleUrls: ['./irembo-otp-data-fetch-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboOtpDataFetchComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IremboOtpDataFetchComponent,
      multi: true,
    },
  ],
})
export class IremboOtpDataFetchComponent
  implements
    ControlValueAccessor,
    Validator,
    OnChanges,
    AfterViewInit,
    OnDestroy
{
  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInputRef!: NgOtpInputComponent;

  @ViewChild('otpRecipient') otpRecipient: ElementRef | undefined;
  @Input() otpLabel!: string;
  @Input() changeRecipientLabel!: string;
  @Input() placeholder!: string;

  @Input() dataFetchedFailedError = false;

  @Input() otpLength!: number;
  @Input() allowOtpNumbersOnly!: boolean;
  @Input() otpPlaceholder!: string;
  @Input() disableAutoFocus = false;
  @Input() inputRegexValueValidation = '\\d{5}';
  @Input() fieldStatus: EOtpDataFetchStatus =
    EOtpDataFetchStatus.INPUT_RECIPIENT;
  @Input() statusMessages: Record<string, string> = {};
  @Input() isFetchingData = false;
  @Output() fetchVerifiedData = new EventEmitter<string>();

  EOtpDataFetchStatus = EOtpDataFetchStatus;
  statusInformationMessage: string | null = null;

  otpFormControl: FormControl = new FormControl('', [Validators.required]);
  isRequestingSendOtp = false;
  isVerifyingOtp = false;
  otpInputConfig!: NgOtpInputConfig;
  private readonly environment: IEnvironment;

  private otpFetchDataInputSub: Subscription = new Subscription();

  customFormControl = new FormControl();

  defaultStatusMessages: Record<string, string> =
    defaultOtpDataFetchInputStatusMessages();

  constructor(
    private readonly http: HttpClient,
    private readonly cd: ChangeDetectorRef,
    @Inject('environment') environment: IEnvironment
  ) {
    this.environment = environment;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  private _onTouch = (value: unknown) => {};
  private _onValidationChange = () => {};
  /* eslint-enable */

  ngAfterViewInit(): void {
    this.otpInputConfig = {
      length: this.otpLength,
      allowNumbersOnly: this.allowOtpNumbersOnly,
      placeholder: this.otpPlaceholder,
      disableAutoFocus: this.disableAutoFocus,
    };
    this.otpFormControl.addValidators(Validators.minLength(this.otpLength));
    this.otpFormControl.addValidators(Validators.maxLength(this.otpLength));

    this.otpFetchDataInputSub = this.customFormControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe({
        next: () => {
          this.fieldStatus = EOtpDataFetchStatus.INPUT_RECIPIENT;
          const validValue = this.validateRecipientValue();
          if (validValue) {
            this.setStatusInformationMessageByKey(
              EOtpDataFetchInputStatusMessageKeys.PENDING_REQUEST_OTP
            );
          } else {
            this.setStatusInformationMessageByKey(null);
          }
          this._onValidationChange();
          this.cd.detectChanges();
        },
      });

    this.otpFetchDataInputSub = this.otpFormControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe({
        next: () => {
          this._onChange(null);
          this.fieldStatus = EOtpDataFetchStatus.INPUT_OTP;
          delete this.customFormControl.errors?.[
            EOtpDataFetchInputErrorKeys.DATA_FETCH_FAILED
          ];

          if (!this.otpFormControl.value) {
            this.setStatusInformationMessageByKey(
              EOtpDataFetchInputStatusMessageKeys.OTP_REQUIRED
            );
          } else {
            this.setStatusInformationMessageByKey(null);
          }

          if (!this.otpFormControl.valid) {
            this.setErrorOnControl(
              EOtpDataFetchInputErrorKeys.OTP_INVALID,
              this.otpFormControl
            );
          } else {
            delete this.otpFormControl.errors?.[
              EOtpDataFetchInputErrorKeys.OTP_INVALID
            ];
            this.setStatusInformationMessageByKey(
              EOtpDataFetchInputStatusMessageKeys.OTP_VERIFICATION_PENDING
            );
          }
          this._onValidationChange();
          this.cd.detectChanges();
        },
      });

    this.otpFetchDataInputSub.add(
      fromEvent(this.otpRecipient?.nativeElement, 'blur').subscribe({
        next: () => {
          this._onValidationChange();
          this.cd.detectChanges();
        },
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['fieldStatus'] || changes?.['isFetchingData']) {
      if (!changes?.['isFetchingData']?.currentValue) {
        this.ngOtpInputRef?.otpForm?.enable();
      }

      if (
        changes?.['fieldStatus']?.currentValue === EOtpDataFetchStatus.COMPLETED
      ) {
        this.customFormControl.setErrors(null);
        this.otpFormControl.setErrors(null);
        this._onChange(this.customFormControl.value);
        this._onTouch(this.customFormControl.value);

        this.setStatusInformationMessageByKey(
          EOtpDataFetchInputStatusMessageKeys.DATA_FETCH_COMPLETE
        );
      }
    }

    if (changes?.['dataFetchedFailedError']?.currentValue) {
      this.setStatusInformationMessageByKey(null);
      this.setErrorOnControl(
        EOtpDataFetchInputErrorKeys.DATA_FETCH_FAILED,
        this.customFormControl
      );
      this._onChange(this.customFormControl.value);
      this._onTouch(this.customFormControl.value);
      this.cd.detectChanges();
    } else {
      delete this.customFormControl?.errors?.[
        EOtpDataFetchInputErrorKeys.DATA_FETCH_FAILED
      ];
      this._onChange(this.customFormControl.value);
      this._onTouch(this.customFormControl.value);
      this.cd.detectChanges();
    }
  }

  validateRecipientValue(): string | undefined {
    if (
      new RegExp(this.inputRegexValueValidation).test(
        this.customFormControl.value
      )
    ) {
      return this.customFormControl.value;
    }
    return undefined;
  }

  onRequestSendOtp() {
    if (this.customFormControl.errors?.['pattern']) {
      this._onValidationChange();
      this.cd.detectChanges();
      return;
    }
    const otpRequestUrl = `${this.environment.apiGatewayBaseUrl}/application/v1/send-otp`;

    delete this.customFormControl.errors?.[
      EOtpDataFetchInputErrorKeys.REQUEST_OTP_FAILED
    ];

    const params = {
      recipient: this.customFormControl.value,
    };

    this.isRequestingSendOtp = true;
    this.otpFetchDataInputSub.add(
      this.http
        .post<Record<string, unknown>>(otpRequestUrl, params)
        .pipe(
          finalize(() => {
            this.isRequestingSendOtp = false;
            this._onValidationChange();
            this.cd.detectChanges();
          })
        )
        .subscribe({
          next: () => {
            this.setStatusInformationMessageByKey(
              EOtpDataFetchInputStatusMessageKeys.OTP_REQUIRED
            );
            this.fieldStatus = EOtpDataFetchStatus.INPUT_OTP;
          },
          error: () => {
            this.setErrorOnControl(
              EOtpDataFetchInputErrorKeys.REQUEST_OTP_FAILED,
              this.customFormControl
            );
          },
        })
    );
  }

  onVerifyOtp() {
    if (this.otpFormControl.invalid) {
      this._onValidationChange();
      this.cd.detectChanges();
      return;
    }
    const otpVerifyUrl = `${this.environment.apiGatewayBaseUrl}/application/v1/verify-otp`;

    const params = {
      recipient: this.customFormControl.value,
      otp: this.otpFormControl.value,
    };
    delete this.otpFormControl.errors?.[
      EOtpDataFetchInputErrorKeys.OTP_VERIFICATION_REQUEST_FAILED
    ];
    delete this.otpFormControl.errors?.[
      EOtpDataFetchInputErrorKeys.OTP_EXPIRED
    ];

    this.isVerifyingOtp = true;
    this.ngOtpInputRef?.otpForm?.disable();
    this.otpFetchDataInputSub.add(
      this.http
        .post<Record<string, unknown>>(otpVerifyUrl, params)
        .pipe(
          finalize(() => {
            this.isVerifyingOtp = false;
            this._onValidationChange();
            this.cd.detectChanges();
          })
        )
        .subscribe({
          next: () => {
            this.fieldStatus = EOtpDataFetchStatus.OTP_VERIFIED;
            this.setStatusInformationMessageByKey(
              EOtpDataFetchInputStatusMessageKeys.DATA_FETCH_PENDING
            );
            this.isFetchingData = true;
            this.fetchVerifiedData.emit(this.customFormControl.value);
          },
          error: (error: HttpErrorResponse) => {
            this.fieldStatus = EOtpDataFetchStatus.INPUT_OTP;
            const errorMesage = ExtractHttpErrorResponseMessage(
              error,
              'OTP Verification Failed'
            );
            if (errorMesage === 'OTP_EXPIRED') {
              this.setErrorOnControl(
                EOtpDataFetchInputErrorKeys.OTP_EXPIRED,
                this.otpFormControl
              );
            } else {
              this.setErrorOnControl(
                EOtpDataFetchInputErrorKeys.OTP_VERIFICATION_REQUEST_FAILED,
                this.otpFormControl
              );
            }
            this.ngOtpInputRef?.otpForm?.enable();
            this.setStatusInformationMessageByKey(null);
          },
        })
    );
  }

  onChangeRecipient(): void {
    this.customFormControl.setErrors(null);
    this.customFormControl.setValue(null);
    this.otpFormControl.setErrors(null);
    this.fieldStatus = EOtpDataFetchStatus.INPUT_RECIPIENT;
    this._onChange(this.customFormControl.value);
    this._onTouch(this.customFormControl.value);
    this._onValidationChange();
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  writeValue(obj: unknown): void {
    this.customFormControl.setValue(obj);
  }

  setDisabledState?(disabledControl: boolean): void {
    disabledControl
      ? this.disableCustomFormControl()
      : this.enableCustomFormControl();
  }

  private enableCustomFormControl(): void {
    this.customFormControl.enable();
  }

  private disableCustomFormControl(): void {
    this.customFormControl.disable();
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  validate(formControl: FormControl): ValidationErrors | null {
    const validationErrors: ValidationErrors = {};

    if (
      formControl.errors?.[
        EOtpDataFetchInputErrorKeys.ENDPOINT_CODE_NOT_CONFIGURED
      ]
    ) {
      validationErrors[
        EOtpDataFetchInputErrorKeys.ENDPOINT_CODE_NOT_CONFIGURED
      ] = true;
      return validationErrors;
    }

    const validInput = this.validateRecipientValue();

    switch (this.fieldStatus) {
      case EOtpDataFetchStatus.INPUT_RECIPIENT: {
        if (!validInput) {
          validationErrors[
            EOtpDataFetchInputErrorKeys.INVALID_RECIPIENT_ENTRY
          ] = true;
        }
        this.handleInputRecipientModeValidations(validationErrors);
        break;
      }
      case EOtpDataFetchStatus.INPUT_OTP: {
        this.handleInputOtpModeValidations(validationErrors);
        break;
      }

      default:
        break;
    }

    if (this.fieldStatus !== EOtpDataFetchStatus.COMPLETED) {
      validationErrors[EOtpDataFetchInputErrorKeys.DATA_FETCH_INCOMPLETE] =
        true;
    }

    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  private handleInputRecipientModeValidations(
    validationErrors: ValidationErrors
  ): ValidationErrors {
    if (
      this.customFormControl.errors?.[
        EOtpDataFetchInputErrorKeys.RECIPIENT_NOT_FOUND
      ]
    ) {
      validationErrors[EOtpDataFetchInputErrorKeys.RECIPIENT_NOT_FOUND] = true;
    }

    if (
      this.customFormControl.errors?.[
        EOtpDataFetchInputErrorKeys.REQUEST_OTP_FAILED
      ]
    ) {
      validationErrors[EOtpDataFetchInputErrorKeys.REQUEST_OTP_FAILED] = true;
    }

    if (
      this.customFormControl.errors?.[
        EOtpDataFetchInputErrorKeys.DATA_FETCH_FAILED
      ]
    ) {
      validationErrors[EOtpDataFetchInputErrorKeys.DATA_FETCH_FAILED] = true;
    }
    return validationErrors;
  }

  private handleInputOtpModeValidations(
    validationErrors: ValidationErrors
  ): ValidationErrors {
    if (
      this.otpFormControl.hasError('minLength') ||
      this.otpFormControl.hasError('maxLength') ||
      this.otpFormControl.hasError(EOtpDataFetchInputErrorKeys.OTP_INVALID)
    ) {
      validationErrors[EOtpDataFetchInputErrorKeys.OTP_INVALID] = true;
    }

    if (this.otpFormControl.errors?.[EOtpDataFetchInputErrorKeys.OTP_EXPIRED]) {
      validationErrors[EOtpDataFetchInputErrorKeys.OTP_EXPIRED] = true;
    }

    if (
      this.otpFormControl.errors?.[
        EOtpDataFetchInputErrorKeys.OTP_VERIFICATION_REQUEST_FAILED
      ]
    ) {
      validationErrors[
        EOtpDataFetchInputErrorKeys.OTP_VERIFICATION_REQUEST_FAILED
      ] = true;
    }

    return validationErrors;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  private setErrorOnControl(
    errorKey: string | EOtpDataFetchInputErrorKeys,
    control: FormControl
  ): void {
    const errorObj: Record<string, unknown> = {
      ...control.errors,
    };
    errorObj[errorKey] = true;
    control.setErrors(errorObj);
  }

  setStatusInformationMessageByKey(
    messageKey: EOtpDataFetchInputStatusMessageKeys | null
  ): void {
    if (!messageKey) {
      this.statusInformationMessage = null;
      return;
    }
    this.statusInformationMessage =
      this.statusMessages[messageKey] ?? this.defaultStatusMessages[messageKey];
  }

  ngOnDestroy(): void {
    this.otpFetchDataInputSub?.unsubscribe();
  }
}
