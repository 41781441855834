export const apis = {
  service: {
    services: '/api/v1/public/services',
    featured: '/api/v1/public/featured-services',
    categories: '/v1/categories',
    organisations: '/v1/public/organizations',
    public: '/api/v1/public/services',
    serviceCategories: '/api/v1/category-services',
  },
  changeRequest: {
    details: '/api/v1/change-requests',
  },
  userProfile: {
    getUserProfile: '/user-profile',
    setDefaultChannel: '/set-default-notification-channel',
    addUpdateNotificationChannel: '/notification-channel',
    changePassword: '/auth/v1/update-password',
    changeFirstLogin: '/first-login',
  },
  application: {
    applications: '/v1/user/applications',
    details: '/v1/application/details',
    billId: '/v1/application/by-bill-id',
    attachment: '/v1/file-manager/application/attachment',
    rfaObject: '/v1/application/rfa-object',
    formState: '/v1/application/form-state',
    feedbacks: '/v1/application/feedbacks',
    userCertificates: '/v1/user/certificates',
    userExpiredCertificates: '/v1/user/expired-certificates',
    userOfficersAttachments: '/v1/user/officer-attachments',
    userExpiringCertificates: '/v1/user/almost-expiring-certificates',
    saveDraft: '/v1/save-application-draft',
    getDraft: '/v1/get-application-draft',
    billIdPublic: '/v1/public/application/by-bill-id',
    applicationNumberPublic: '/v1/public/application/by-application-number',
    applicationCertificateValidity:
      '/v1/application/check-certificate-validity',
    businessApplications: (id: string) => `/v1/business/${id}/applications`,
    businessCertificates: (id: string) => `/v1/business/${id}/certificates`,
    businessOfficerAttachments: (id: string) =>
      `/v1/business/${id}/officer-attachments`,
  },
  documentGeneration: {
    downloadCertificate: '/v1/download-certificate',
  },
  payment: {
    paymentReceipt: '/v1/payment-receipt',
  },
  userIdentification: {
    nidInfo: '/v1/nid-info-by-username',
    childIdInfo: '/v1/child-id-info-by-username',
    ninInfo: '/v1/nin-info-by-username',
  },
  business: {
    listBusinesses: 'v1/user/businesses',
    linkBusiness: 'v1/business',
    verifyBusinessToken: '/v1/business/verify',
    initiateAddUserRequest: '/v1/business/user-requests/operator',
    listUserRequests: (id: string) => `/v1/business/${id}/user-requests`,
    processUserRequest: (id: string) =>
      `/v1/business/user-requests/${id}/take-action`,
    iremboBusinessPermissions: '/v1/business/permissions',
    iremboBusinessPermissionGroups: '/v1/business/permission-groups',
    addNewBusinessUser: '/v1/business/users',
    businessUsers: '/v1/business/{businessId}/users',
    removeBusinessUser: (businessUserId: string) =>
      `/v1/business/users/${businessUserId}/remove`,
    userAvailablePermissionsInBusiness: (businessUserId: string) =>
      `/v1/business/users/${businessUserId}/available-permissions`,
  },
};
