import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { ICertificate } from '../../../../models/certificates.model';
import { IOfficerAttachment } from '../../../../models/officer-attachment.model';

@Component({
  selector: 'irembogov-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss'],
})
export class DocumentsListComponent {
  @Input() filteredCertificates: any[] = [];
  @Input() filteredAttachments: any[] = [];
  @Input() categories: string[] = [];
  @Input() selectedCategory = 'View all';
  @Input() showMobileApplicationList = false;
  @Input() isLoadingDocuments = false;
  @Input() documentMode: any;
  @Input() certificate_content: any;
  @Input() attachment_content!: TemplateRef<any>;
  @Input() languageSelector!: TemplateRef<any>;
  @Input() certificateNames: any;
  @Input() allowedAttachmentPreviewTypes: string[] = [];

  @Output() LanguageSelectorModalOpened = new EventEmitter<any>();
  @Output() DownloadAttachment = new EventEmitter<any>();
  @Output() PreviewAttachment = new EventEmitter<any>();
  @Output() FilterDocuments = new EventEmitter<string>();

  searchDocTerm = '';

  onEnterInSearchDocInput() {
    console.log('search');
  }

  openLanguageSelectorModal(
    certificate: ICertificate,
    documentMode: string,
    content?: TemplateRef<any>
  ) {
    this.LanguageSelectorModalOpened.emit({
      ...this.languageSelector,
      certificate,
      documentMode,
      content,
    });
  }

  previewAttachment(attachment: IOfficerAttachment) {
    this.PreviewAttachment.emit({
      attachment,
    });
  }

  downloadAttachment(attachment: IOfficerAttachment) {
    this.DownloadAttachment.emit({
      attachment,
    });
  }

  filterDocuments(category: string) {
    this.selectedCategory = category;
    this.FilterDocuments.emit(this.selectedCategory);
  }
}
