import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { TranslateService } from '@ngx-translate/core';
import { finalize, Subscription } from 'rxjs';
import {
  BusinessUserRequest,
  BusinessUserRequestActionDTO,
} from '../../../../../models/business/business.model';
import { BusinessService } from '../../../../../services/business.service';

@Component({
  selector: 'irembogov-list-business-user-requests',
  templateUrl: './list-business-user-requests.component.html',
  styleUrls: ['./list-business-user-requests.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListBusinessUserRequestsComponent implements OnInit, OnDestroy {
  @Input() businessId!: string;

  private subscriptions = new Subscription();
  isLoadingUserRequests = false;
  isProcessUserRequests = false;
  userRequestLists: BusinessUserRequest[] = [];
  processedUserRequest!: BusinessUserRequest;
  totalUserRequests = 0;

  constructor(
    private businessService: BusinessService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUserRequests(0, 10, 'dateCreated');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getUserRequests(page: number, size: number, sort: string) {
    this.isLoadingUserRequests = true;
    this.subscriptions.add(
      this.businessService
        .getUserRequests(this.businessId, {
          page,
          size,
          sort,
        })
        .pipe(finalize(() => (this.isLoadingUserRequests = false)))
        .subscribe({
          next: res => {
            this.userRequestLists = res.data.content;
            this.totalUserRequests = res.data.totalElements;
          },
          error: () => {
            this.toastService.show({
              body: 'Error loading user requests.',
              type: 'error',
            });
          },
        })
    );
  }

  handlePaginate(event: { pageNumber: number; pageSize: number }) {
    this.getUserRequests(event.pageNumber - 1, event.pageSize, 'dateCreated');
  }

  convertDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/');
    return new Date(+year, +month - 1, +day);
  }

  generateInitials(names: string | string[]): string {
    if (typeof names === 'string') return names.substring(0, 2);

    return names
      .filter(name => name && name.length > 0)
      .map(name => name[0])
      .join('');
  }

  approveRequest(request: BusinessUserRequest) {
    this.processedUserRequest = request;
    const data: BusinessUserRequestActionDTO = {
      actionType: 'APPROVE',
    };
    this.processRequest(request.guid, data);
  }

  rejectRequest(request: BusinessUserRequest) {
    this.processedUserRequest = request;
    const data: BusinessUserRequestActionDTO = {
      actionType: 'REJECT',
    };
    this.processRequest(request.guid, data);
  }

  private processRequest(id: string, data: BusinessUserRequestActionDTO) {
    this.isProcessUserRequests = true;

    this.subscriptions.add(
      this.businessService
        .processUserRequest(id, data)
        .pipe(finalize(() => (this.isProcessUserRequests = false)))
        .subscribe({
          next: () => {
            const toastTitle =
              data.actionType === 'APPROVE'
                ? `${this.translateService.instant('Success')}!`
                : undefined;
            const toastType =
              data.actionType === 'APPROVE' ? 'success' : 'info';
            const successMessage = this.getSuccessMessage(data.actionType);
            this.toastService.show({
              title: toastTitle,
              body: successMessage,
              type: toastType,
            });
            setTimeout(() => {
              this.navigateToProfile();
            }, 3000);
          },
          error: () => {
            this.toastService.show({
              body: this.translateService.instant(
                'Error processing request, please try again.'
              ),
              type: 'error',
            });
          },
        })
    );
  }

  private getSuccessMessage(actionType: 'APPROVE' | 'REJECT'): string {
    let message = '';
    const username =
      this.processedUserRequest.firstName && this.processedUserRequest.lastName
        ? `${this.processedUserRequest.firstName} ${this.processedUserRequest.lastName}`
        : this.processedUserRequest.username;
    switch (actionType) {
      case 'APPROVE':
        message = `
          ${this.translateService.instant('You have successfully added')}${' '}
          <span class='fw-bold'>${username}</span>${' '}
          ${this.translateService.instant(
            'to your team and the user has been notified'
          )}
        .`;
        break;
      case 'REJECT':
        message = `
          ${this.translateService.instant(
            'You have successfully denied a request from'
          )}${' '}
          <span class='fw-bold text-muted'>${username}</span>${' '}
          ${this.translateService.instant(
            'to join your team and the user has been notified'
          )}
        .`;
        break;

      default:
        message = 'Request has been processed successfully.';
        break;
    }
    return message;
  }

  private navigateToProfile() {
    this.router.navigate(['profile'], { queryParams: { section: 'business' } });
  }
}
