<ng-select
  [readonly]="disabled"
  [appendTo]="appendTo"
  [items]="timeSlots"
  [virtualScroll]="true"
  [placeholder]="placeholder"
  [searchable]="false"
  [formControl]="customFormControl"
  class="custom irembo-time-value-picker {{ itemIconPosition }} {{
    customFormControl?.value ? 'filled' : 'not-filled'
  }}"
  [labelForId]="labelForId"
  bindLabel="label"
  bindValue="value">
  <ng-template ng-label-tmp let-item="item">
    <i class="fa-regular fa-clock"></i>
    {{ item.label }}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <i class="fa-regular fa-clock"></i>
    {{ item.label }}
  </ng-template>
</ng-select>
