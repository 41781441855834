import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'irembogov-select',
  templateUrl: './irembo-select.component.html',
  styleUrls: ['./irembo-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboSelectComponent),
      multi: true,
    },
  ],
})
export class IremboSelectComponent
  implements ControlValueAccessor, OnChanges, OnDestroy
{
  @Input() items: Record<string, unknown>[] | null = [];
  @Input() bindLabel = '';
  @Input() bindValue = '';
  @Input() compareWithFn: (a: any, b: any) => boolean = (a, b) => {
    if (this.bindValue) {
      return JSON.stringify(a?.[this.bindValue]) === JSON.stringify(b);
    }
    return JSON.stringify(a) === JSON.stringify(b);
  };
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() multiple = false;
  @Input() virtualScroll = false;
  @Input() labelForId: unknown;
  @Input() customFormControl = new FormControl();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() loading = false;
  @Input() parent: string | undefined;
  @Input() hideIcon = false;
  @Input() showCancel = true;
  @Input() customClass = '';
  @Input() appendTo = 'body';
  @Input() dropdownPosition: 'bottom' | 'top' | 'auto' = 'auto';

  constructor(private renderer: Renderer2) {}

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  private _onTouch = (value: unknown) => {};
  private itemsSubscription!: Subscription;

  /* eslint-enable */

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']?.currentValue) {
      if (this.itemsSubscription) {
        this.itemsSubscription.unsubscribe();
      }

      if (this.items instanceof Observable) {
        this.itemsSubscription = this.items.subscribe(items => {
          this.items = [...items];
        });
      } else {
        this.items = [...changes['items'].currentValue];
      }
    }
  }

  writeValue(obj: unknown): void {
    this.customFormControl.setValue(obj);
  }
  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change(): void {
    this._onChange(this.customFormControl.value);
    this._onTouch(this.customFormControl.value);
    this.itemSelected.emit(this.customFormControl.value);
  }

  ngOnDestroy(): void {
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
    }
  }

  onDropDownOpen() {
    setTimeout(() => {
      if (this.customClass) {
        this.renderer.addClass(
          document.querySelector('.ng-dropdown-panel'),
          this.customClass
        );
      }
    }, 0);
  }
}
