import { IIrembogovBasicLabelKeyPair } from '../models/irembogov-basic-label-keypair.model';

export type TIdType = 'NID' | 'CHILD_ID' | 'NIN';

export const CustomIdInputTypesApiUrls = (): ICustomIdInputTypeApiUrls => {
  return {
    nid: {
      basic: '/identity/v1/nid-info',
      verify: '/identity/v1/nid-info/verify',
      privacy: '/identity/v1/nid-info/privacy',
      prefetchUrl: '/application/v1/user-profile',
      prefetchFieldKey: 'NID',
    },
    childId: {
      basic: '/identity/v1/child-id-info',
      verify: '/identity/v1/child-id-info/verify',
      privacy: '/identity/v1/child-id-info/privacy',
    },
    nin: {
      basic: '/identity/v1/nin-info',
    },
  };
};

export interface ICustomIdInputTypeApiUrls {
  nid: IIdInputTypeApiUrls;
  childId: IIdInputTypeApiUrls;
  nin: IIdInputTypeApiUrls;
}

export interface IIdInputTypeApiUrls {
  basic?: string;
  verify?: string;
  privacy?: string;
  prefetchUrl?: string;
  prefetchFieldKey?: string;
}

export enum CustomIdInputTypesIdLength {
  NID_ID_LENGTH = 16,
  CHILD_ID_LENGTH = 8,
  NIN_ID_LENGTH = 10,
}

export enum ECustomIdInputErrorKeys {
  INVALID_INPUT = 'invalidInput',
  INVALID_ID_INPUT = 'invalidId',
  VERIFICATON_FAILED = 'verificationFailed',
  VERIFICATON_INVALID = 'verificationInvalid',
  VERIFICATION_REQUIRED = 'verificationRequired',
  ENDPOINT_CODE_NOT_CONFIGURED = 'endpointCodeNotConfigured',
}

export enum EOtpDataFetchStatus {
  INPUT_RECIPIENT = 'INPUT_RECIPIENT',
  INPUT_OTP = 'INPUT_OTP',
  OTP_VERIFIED = 'OTP_VERIFIED',
  COMPLETED = 'COMPLETED',
}

export enum EOtpDataFetchInputErrorKeys {
  // INITIAL
  ENDPOINT_CODE_NOT_CONFIGURED = 'endpointCodeNotConfigured',
  INVALID_RECIPIENT_ENTRY = 'invalidRecipientEntry',
  RECIPIENT_NOT_FOUND = 'recipientNotFound',
  REQUEST_OTP_FAILED = 'requestOtpFailed',

  // OTP
  OTP_INVALID = 'otpInvalid',
  OTP_EXPIRED = 'otpExpired',
  OTP_VERIFICATION_REQUEST_FAILED = 'otpVerificationRequestFailed',

  // FINALIZE
  DATA_FETCH_FAILED = 'dataFetchFailed',
  DATA_FETCH_INCOMPLETE = 'dataFetchIncomplete',
}

export const defaultOtpDataFetchInputErrorMessages: IIrembogovBasicLabelKeyPair<string>[] =
  [
    {
      key: EOtpDataFetchInputErrorKeys.ENDPOINT_CODE_NOT_CONFIGURED,
      label: 'Endpoint code is required on this field.',
    },
    {
      key: EOtpDataFetchInputErrorKeys.INVALID_RECIPIENT_ENTRY,
      label: 'Please enter a valid recipient',
    },
    {
      key: EOtpDataFetchInputErrorKeys.RECIPIENT_NOT_FOUND,
      label: 'Recipient not found',
    },
    {
      key: EOtpDataFetchInputErrorKeys.REQUEST_OTP_FAILED,
      label: 'Failed sending OTP request. Please try again.',
    },
    {
      key: EOtpDataFetchInputErrorKeys.OTP_INVALID,
      label: 'Please enter a valid OTP.',
    },
    {
      key: EOtpDataFetchInputErrorKeys.OTP_EXPIRED,
      label: 'OTP expired.',
    },
    {
      key: EOtpDataFetchInputErrorKeys.OTP_VERIFICATION_REQUEST_FAILED,
      label: 'OTP verification failed.',
    },
    {
      key: EOtpDataFetchInputErrorKeys.DATA_FETCH_FAILED,
      label: 'Failed to complete data fetch. Please try again.',
    },
    {
      key: EOtpDataFetchInputErrorKeys.DATA_FETCH_INCOMPLETE,
      label: 'Otp verificationa and data fetch incomplete.',
    },
  ];

export enum EOtpDataFetchInputStatusMessageKeys {
  // INITIAL
  PENDING_REQUEST_OTP = 'messagePendingRequestOtp',

  // OTP
  OTP_REQUIRED = 'messageOtpRequired',
  OTP_VERIFICATION_PENDING = 'messageOtpVerificationPending',

  // FINALIZE
  DATA_FETCH_PENDING = 'messageDataFetchPending',
  DATA_FETCH_COMPLETE = 'messageDataFetchComplete',
}

export const defaultOtpDataFetchInputStatusMessages = (): Record<
  string,
  string
> => {
  const messages: Record<string, string> = {};
  messages[EOtpDataFetchInputStatusMessageKeys.PENDING_REQUEST_OTP] =
    'Request for an OTP to be sent to the recipient to proceed.';
  messages[EOtpDataFetchInputStatusMessageKeys.OTP_REQUIRED] =
    'Please enter the OTP digits sent to the recipient.';
  messages[EOtpDataFetchInputStatusMessageKeys.OTP_VERIFICATION_PENDING] =
    'Please submit the OTP for verification to proceed.';
  messages[EOtpDataFetchInputStatusMessageKeys.DATA_FETCH_PENDING] =
    'Please wait while the information is retrieved.';
  messages[EOtpDataFetchInputStatusMessageKeys.DATA_FETCH_COMPLETE] =
    'Information is retrieved. Data fetch successful.';

  return messages;
};
