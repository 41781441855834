<irembogov-file-upload-selector
  [loadingFile]="loadingFile"
  [progress]="progress"
  [filePath]="filePath"
  [totalPreviousAttachments]="this.totalPreviousAttachments"
  [totalCertificateAttachments]="this.totalCertificateAttachments"
  [previousAttachments]="this.previousAttachments"
  [certificateAttachments]="this.certificateAttachments"
  [id]="field.key"
  [minimumUploadSize]="field.props['minimumUploadSize']"
  [maximumUploadSize]="field.props['maximumUploadSize']"
  [noFileActionLabel]="noFileActionLabel"
  [noFileSubLabelHtml]="noFileSubLabelHtml"
  [formControl]="formControl"
  [allowedFormats]="field.props['allowedFormats']"
  (fileUploadEvent)="onFileUploadEvent($event)"
  (selectFileEvent)="onSelectFile()"
  (selectPreviousAttachmentEvent)="selectPreviousAttachmentEvent($event)"
  (selectCertificateAttachmentEvent)="selectCertificateAttachmentEvent($event)"
  (previousAttachmentEventScroll)="handlePreviousAttachmentPagination($event)"
  (certificateAttachmentEventScroll)="
    handleCertificateAttachmentPagination($event)
  "
  [photoValidationResponse]="photoValidationResponse"
  [validatePhoto]="field.props['validatePhoto']"
  [validatingPhoto]="validatingPhoto"
  [attachmentCode]="field.props['attachmentCode']"
  [allowUploadPreviousFiles]="
    this.allowUploadPreviousFiles
  "></irembogov-file-upload-selector>
