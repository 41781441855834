import { Component, OnInit } from '@angular/core';
import { VerifyAccountDto } from '../../models/verify-account.model';
import { AuthService } from '../../modules/auth/service/auth.service';

import { finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'irembogov-verify-identity',
  templateUrl: './verify-identity.component.html',
  styleUrls: ['./verify-identity.component.scss'],
})
export class VerifyIdentityComponent implements OnInit {
  verifyStep: 1 | 2 | 3 | 4 = 1;
  errorMessage = '';
  isLoading = false;
  subHeadingText =
    'You can confirm your identity using your <strong>National ID (NID)</strong>. We will send a verification code to your registered phone number for added security.';
  verifyForm!: FormGroup;
  locale = '';

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.locale = localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
    this.verifyForm = new FormGroup({
      phone: new FormControl('', [
        Validators.pattern('^[0-9]+$'),
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.required,
      ]),
      identificationNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(16),
        Validators.maxLength(16),
        Validators.pattern('^[0-9]+$'),
      ]),
    });
  }

  initiateVerify() {
    this.verifyStep = 2;
    this.isLoading = true;
    const idNumber = this.verifyForm.get('identificationNumber')?.value;
    const phone = this.verifyForm.get('phone')?.value;

    if (idNumber.length !== 16) {
      this.errorMessage = 'ID number must be 16 digits';
      this.verifyStep = 1;
      this.isLoading = false;
      return;
    }

    if (phone.length !== 10) {
      this.errorMessage = 'Phone number(Rwanda) must be 10 digits';
      this.verifyStep = 1;
      this.isLoading = false;
      return;
    }

    const verifyAccountDto: VerifyAccountDto = {
      phoneNumber: phone,
      identificationNumber: idNumber,
      locale: this.locale,
    };

    this.authService
      .initiateVerifyAccount(verifyAccountDto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.verifyStep = 3;
        },
        error: (err: HttpErrorResponse) => {
          this.verifyStep = 1;
          this.handleError(err);
        },
      });
  }

  private handleError(err: HttpErrorResponse) {
    if (err.error?.responseMessage) {
      this.errorMessage = err.error.responseMessage;
    } else {
      this.errorMessage =
        'The phone number and national id you provided do not match in the national registry. Please try a different number.';
    }
  }

  cancelVerification() {
    this.router.navigate(['/']);
  }

  onPhoneVerification() {
    this.verifyStep = 4;
  }

  gotToHomePage() {
    this.router.navigate(['/']);
  }
}
