import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Business } from '../models/business/business.model';
import { BusinessService } from './business.service';
import { IHttpPagedResponse } from '../models/http/paginated-response.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { AuthService } from '../modules/auth/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserBusinessService {
  private userBusinesses$: BehaviorSubject<Business[] | undefined> =
    new BehaviorSubject<Business[] | undefined>(undefined);

  userBusinessesSubjectObservable!: Observable<Business[] | undefined>;

  private userPermissions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );
  userPermissionsSubjectObservable!: Observable<any[]>;

  constructor(
    private businessService: BusinessService,
    private authService: AuthService,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {
    this.userBusinessesSubjectObservable = this.userBusinesses$.asObservable();
    this.userPermissionsSubjectObservable =
      this.userPermissions$.asObservable();
  }

  setUserBusinesses(businesses: Business[]) {
    this.userBusinesses$.next(businesses);
  }

  setPermissions(permissions: any[]) {
    this.userPermissions$.next([...permissions]);
  }

  getPermissions(): any[] {
    return this.userPermissions$.getValue();
  }

  findUserBusinessInBusinessesById(id: string): Business | undefined {
    return this.userBusinesses$
      .getValue()
      ?.find((business: Business) => business.guid === id);
  }

  fetchAndSetUserServices(): void {
    this.businessService.getBusinesses().subscribe({
      next: (response: IHttpPagedResponse<Business>) => {
        if (response.data.content) {
          this.setUserBusinesses(response.data.content);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.setUserBusinesses([]);
        if (error.status === 401) {
          this.authService.loginUserAndRedirectBackToSamePage();
        }

        this.toastService.show({
          body: this.translateService.instant(
            'User businesses could not be retrieved.'
          ),
          type: 'error',
        });
      },
    });
  }
}
