import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BannerConfig } from 'src/app/models/info-banner.model';
import { IdentityVerificationService } from '../../../app/services/show-identity-verification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'irembogov-generic-container',
  templateUrl: './generic-container.component.html',
  styleUrls: ['./generic-container.component.scss'],
})
export class GenericContainerComponent implements OnInit, OnDestroy {
  activeBanner: BannerConfig | null = null;
  showIdentityVerification = false;
  private subscription = new Subscription();
  constructor(
    public translate: TranslateService,
    private router: Router,
    private identityVerificationService: IdentityVerificationService
  ) {}

  ngOnInit(): void {
    this.updateActiveBanner(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActiveBanner(event.urlAfterRedirects);
      }
    });

    this.subscription =
      this.identityVerificationService.showIdentityVerification$.subscribe(
        value => {
          this.showIdentityVerification = value;
        }
      );
  }

  private updateActiveBanner(url: string) {
    this.activeBanner = environment.pageBanners[url] || null;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
