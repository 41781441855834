<div class="profile-settings">
  <a
    class="back-button text-decoration-none d-flex flex-row align-items-center"
    [routerLink]="['/profile']">
    <i class="icon-chevron-left me-2"></i> {{ 'Back to profile' | translate }}
  </a>

  <h1>{{ 'Profile settings' | translate }}</h1>
  <div class="divider"></div>
  <div
    class="personal-info d-flex flex-column flex-lg-row justify-content-lg-between justify-content-md-between align-items-start col-12">
    <h2>{{ 'Personal info' | translate }}</h2>
    <div
      class="info-container d-flex flex-column flex-lg-row align-items-start col-12 col-lg-8 col-md-8"
      *ngIf="keycloakProfile && userProfile">
      <div class="d-flex flex-column col-lg-6 col-md-6 gap-4">
        <div class="info-item">
          <span class="label">{{ 'Status' | translate }}</span>
          <div class="status verified" *ngIf="userProfile.accountVerified">
            <i class="icon-check-verified-01"></i>{{ 'Verified' | translate }}
          </div>
          <div class="status pending" *ngIf="!userProfile.accountVerified">
            <i class="icon-info-circle"></i
            >{{ 'Pending verification' | translate }}
          </div>
        </div>
        <div class="info-item col-12 col-lg-6 col-md-6">
          <span class="label">{{ 'Username' | translate }}</span>
          <span class="username">{{ keycloakProfile.username }}</span>
        </div>
      </div>

      <div class="info-item">
        <span class="label">{{ 'National ID Number' | translate }}</span>
        <span class="nid">{{ userProfile.identificationNumber }}</span>
      </div>
    </div>
  </div>
  <div class="divider d-none d-lg-block"></div>
  <div
    class="personal-info d-flex flex-column flex-lg-row align-items-start justify-content-lg-between justify-content-md-between col-12">
    <h2>{{ 'Notification settings' | translate }}</h2>
    <div
      class="notifications-settings d-flex flex-column align-items-start col-12 col-lg-8 col-md-8">
      <div
        class="info-items d-flex flex-column flex-lg-row justify-content-lg-between align-items-start col-12">
        <div
          class="info-item col-12 col-lg-6 col-md-6"
          *ngIf="userProfile.notificationPhoneNumber">
          <span class="label">{{ 'Phone Number' | translate }}</span>
          <span class="value">{{ userProfile.notificationPhoneNumber }}</span>
        </div>
        <div
          class="info-item col-12 col-lg-6 col-md-6"
          *ngIf="userProfile.notificationEmail">
          <span class="label">{{ 'Primary Email' | translate }}</span>
          <span class="value">{{ userProfile.notificationEmail }}</span>
        </div>
      </div>
      <div
        class="edit-action d-flex justify-content-end justify-content-lg-start align-items-center w-100">
        <button
          class="btn edit-button"
          (click)="openUpdateNotificationsModal(updateNotifications)">
          <i class="icon-edit-05 me-1"></i> {{ 'Edit' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #updateNotifications let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'Update notification settings' | translate }}
    </h4>
    <i
      type="button"
      class="icon-x-close"
      aria-label="Close"
      (click)="modal.dismiss()"></i>
  </div>
  <div class="modal-body">
    <form
      class="d-flex flex-column gap-3 col-12"
      [formGroup]="updateNotificationsForm">
      <div class="col-12">
        <label for="phoneNumber"> {{ 'Phone number' | translate }}</label>
        <input
          type="tel"
          class="form-control form-control-lg"
          id="phoneNumber"
          formControlName="phoneNumber"
          [placeholder]="'Enter phone number' | translate" />
        <div
          id="phoneNumberHelpBlock"
          class="form-text"
          [ngClass]="{
            'text-danger':
              updateNotificationsForm.controls['phoneNumber'].invalid &&
              updateNotificationsForm.controls['phoneNumber'].touched
          }">
          {{ '10 digit Rwandan phone number required.' | translate }}
        </div>
      </div>
      <div class="col-12">
        <label for="email"> {{ 'Email' | translate }}</label>
        <input
          type="email"
          class="form-control form-control-lg"
          id="email"
          formControlName="email"
          [placeholder]="'Enter email address' | translate" />
        <p
          *ngIf="
            updateNotificationsForm.controls['email'].invalid &&
            updateNotificationsForm.controls['email'].touched
          "
          class="text-danger small mt-1">
          {{ 'Please provide a valid email to proceed' | translate }}
        </p>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex flex-column gap-lg-2 flex-lg-row-reverse">
    <button
      type="button"
      class="btn btn-primary fw-semibold col-12 col-lg-5 flex-lg-fill"
      [disabled]="updateNotificationsForm.invalid || updating"
      (click)="updateNotificationsChanges()">
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        *ngIf="updating">
        <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
      </div>
      {{ 'Save changes' | translate }}
    </button>
    <button
      type="button"
      class="btn cancel-btn col-12 col-lg-5 flex-lg-fill"
      (click)="modal.dismiss()">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</ng-template>
