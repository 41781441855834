<div class="container-fluid verify-certificates">
  <div class="row">
    <div class="col verify-certificate-container">
      <div class="mx-auto text-center w-100 inner-container">
        <h3 class="title">{{ 'Verify a certificate' | translate }}</h3>
        <div class="d-flex justify-content-center form-container">
          <form
            class="row g-3"
            [formGroup]="verifyForm"
            (ngSubmit)="openVerifyModal(certificateStatus)">
            <div class="col-auto">
              <label for="applicationNumber" class="visually-hidden">
                {{ 'Enter application number' | translate }}</label
              >
              <input
                type="text"
                class="form-control form-control-lg"
                id="applicationNumber"
                formControlName="applicationNo"
                [placeholder]="'Enter application number' | translate" />
              <div id="applicationNumberHelpBlock" class="form-text">
                {{
                  'Application number is in the lower section of the certificate'
                    | translate
                }}
              </div>
            </div>
            <div class="col-auto verify-btn">
              <button
                type="submit"
                [disabled]="verifyForm.invalid"
                class="btn btn-lg btn-primary mb-3">
                <div
                  *ngIf="verifying"
                  class="spinner-border spinner-border-sm"
                  role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                {{ 'Verify' | translate }}
              </button>
            </div>
          </form>
        </div>
        <div class="support-text">
          <p>
            {{
              'Only certificates from ePoBox registration applications can be verified here. To verify other certificates,'
                | translate
            }}
            <span
              ><a
                href="https://iremboui.uat.iremboinc.com/home/citizen/all_services"
                target="_blank"
                >{{ 'click here' | translate }}</a
              ></span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #certificateStatus let-modal>
  <div class="modal-header">
    <img
      *ngIf="applicationStatus"
      src="/assets/icons/{{ getApplicationStatusIcon(applicationStatus) }}"
      alt="modal-icon" />
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="applicationStatus === 'VALID'">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'Certificate is valid' | translate }}
      </h4>
      <p>
        {{
          'This document was generated by Irembo and is valid as of today'
            | translate
        }}
      </p>
    </ng-container>
    <ng-container *ngIf="applicationStatus === 'INVALID'">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'Certificate is invalid' | translate }}
      </h4>
      <p>
        {{
          "Certificate not found on IremboGov. Please verify the document's source and either try again or apply for a new certificate"
            | translate
        }}.
      </p>
    </ng-container>
    <ng-container *ngIf="applicationStatus === 'EXPIRED'">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'Certificate is expired' | translate }}
      </h4>
      <p>
        {{
          'This certificate has expired and is no longer valid. Please apply for a new certificate'
            | translate
        }}.
      </p>
    </ng-container>
  </div>
  <div class="modal-footer" *ngIf="applicationStatus === 'VALID'">
    <button
      type="button"
      class="btn w-100 btn-outline-dark cancel-btn"
      (click)="modal.dismiss('Cancel')">
      {{ 'Cancel' | translate }}
    </button>
    <button
      type="button"
      class="btn w-100 btn-primary"
      (click)="openTokenModal(token)">
      {{ 'View Certificate' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #token let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'Enter token provided by certificate owner' | translate }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body" [ngClass]="{ 'invalid-token': invalidToken }">
    <ng-otp-input
      #ngOtpInputRef
      (onInputChange)="onOtpChange($event, ngOtpInputRef)"
      [config]="{
        length: 5,
        placeholder: '0',
        inputClass: 'formControl',
        containerClass: 'custom-token-input'
      }"></ng-otp-input>
    <p *ngIf="invalidToken" class="invalidTokenMsg mt-2">
      {{
        'The token you entered is invalid. Please verify the token and try again'
          | translate
      }}.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn w-100 btn-outline-dark cancel-btn"
      (click)="modal.dismiss('Cancel')">
      {{ 'Cancel' | translate }}
    </button>
    <button
      type="button"
      [disabled]="otp.length < 5"
      (click)="confirmCertificate(ngOtpInputRef)"
      class="btn w-100 btn-primary">
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        *ngIf="isPreviewingCertificate">
        <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
      </div>
      {{ 'Confirm' | translate }}
    </button>
  </div>
</ng-template>
