import { flatten } from 'flat';

export function deepFlatten(
  target: Record<string, unknown>
): Record<string, unknown> {
  let flattenObj: Record<string, unknown> = flatten(target);

  Object.keys(flattenObj).forEach((key: string) => {
    try {
      const jsonValue: Record<string, unknown> = JSON.parse(
        flattenObj[key] as string
      );
      const valueObj: Record<string, unknown> = {};
      valueObj[key] = jsonValue;
      const flattedValue: Record<string, unknown> = flatten(valueObj);
      flattenObj = { ...flattenObj, ...flattedValue };
      flattenObj[key] = jsonValue;
    } catch (e) {
      return;
    }
  });

  return flattenObj;
}
