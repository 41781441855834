<span id="validate-id-fetch-modal-container-{{ field.key }}">
  <irembogov-id-input
    [formControl]="formControl"
    [id]="field.key"
    [placeholder]="field.props['placeholder']"
    [idLength]="field.props['idLength']"
    [statusClass]="statusClass"
    (verifyId)="onVerifyId($event)"></irembogov-id-input>
</span>

<ng-template #idFetchVerificationModal let-modal>
  <div class="p-3">
    <div class="modal-header validate-id-fetch-modal border-bottom-0 pb-0">
      <span
        class="modal-title d-flex align-items-center justify-content-center modal-validate-id-fetch-title-icon">
        <i class="icon-alert-triangle"></i>
      </span>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.close('close')"></button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <h4 class="modal-validate-id-fetch-title">
          {{ 'Verification required' | translate }}
        </h4>
        <div class="w-100">
          <p *ngIf="idVerificationMethod === EVerificationMethods.NAME">
            {{
              "For privacy, verify ID owner's consent by entering the ID owner's first or last name to proceed."
                | translate
            }}
          </p>
          <p
            *ngIf="idVerificationMethod === EVerificationMethods.DATE_OF_BIRTH">
            {{
              "For privacy, verify ID owner's consent by entering their date of birth as it appears on their national ID."
                | translate
            }}
          </p>

          <strong>ID: {{ verifyingIdValue }}</strong>
        </div>
      </div>

      <div
        class="input-group mb-3"
        *ngIf="idVerificationMethod === EVerificationMethods.NAME">
        <label for="firstOrLastName" class="mb-1 w-100">{{
          "Owner's name" | translate
        }}</label>
        <div class="w-100">
          <input
            type="text"
            class="form-control"
            id="firstOrLastName"
            placeholder="Enter either first or last name"
            [formControl]="firstOrLastName" />
        </div>
        <small
          class="w-100 text-danger pb-3"
          *ngIf="firstOrLastName.invalid && firstOrLastName.touched">
          {{ 'Enter a valid first or last name.' | translate }}
        </small>
      </div>

      <div
        class="mb-3 dateOfBirth-inout-group"
        *ngIf="idVerificationMethod === EVerificationMethods.DATE_OF_BIRTH">
        <label for="dateOfBirth" class="mb-1 w-100">
          {{ 'Date of birth' | translate }}
        </label>
        <div class="w-100">
          <irembogov-irembo-date-picker
            type="text"
            id="dateOfBirth"
            addRemoveTimeMaxDate="0:d"
            addRemoveTimeMinDate="-150:y"
            placeholder="{{ 'Select date' | translate }}"
            [formControl]="dateOfBirth"></irembogov-irembo-date-picker>
        </div>
        <small
          class="w-100 text-danger pb-3"
          *ngIf="dateOfBirth.invalid && dateOfBirth.touched">
          {{ 'A valid date of birth is required.' | translate }}
        </small>
      </div>
    </div>
    <div
      class="modal-footer d-flex justify-content-between border-top-0 pt-0 w-100">
      <button
        type="button"
        class="btn btn-outline-dark flex-grow-1"
        (click)="modal.close('cancel')">
        {{ 'Cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary flex-grow-1"
        (click)="modal.close('verifyId')"
        [disabled]="
          isValidatingIdOwner ||
          !(
            (dateOfBirth.value || firstOrLastName.value) &&
            dateOfBirth.valid &&
            firstOrLastName.valid
          )
        ">
        <div
          *ngIf="isValidatingIdOwner"
          class="spinner-border spinner-border-sm text-light me-2">
          <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
        </div>
        {{ 'Verify' | translate }}
      </button>
    </div>
  </div>
</ng-template>
