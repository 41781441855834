<irembogov-slot-booking
  [isTouched]="formControl.touched"
  [formControl]="formControl"
  [loading]="loading"
  [items]="items"
  [remainingSeats]="remainingSeats"
  [invalidSlot]="invalidSlot"
  (activeDropDown)="handleActiveDropDown($event)"
  (rfaEvent)="handleRFAEvent($event)"
  [configs]="configs"></irembogov-slot-booking>
