<div class="preview-card">
  <div class="preview-card-header tt_norms500">
    <h1 class="preview-card-header-title">{{ group?.fieldGroupName }}</h1>
  </div>
  <div class="preview-card-body">
    <ng-container
      *ngFor="
        let field of group?.fieldGroup;
        let i = index;
        trackBy: trackByFn
      ">
      <div [style.order]="field?.order" *ngIf="field">
        <ng-container
          *ngTemplateOutlet="
            fieldItemDataValues;
            context: { fieldItemData: field, content: content, index: i }
          ">
        </ng-container>
      </div>
    </ng-container>

    <div
      [style.order]="fieldArray?.order"
      class="d-flex flex-column"
      *ngFor="
        let fieldArray of group?.fieldArray;
        let i = index;
        trackBy: trackByFn
      ">
      <ng-container *ngIf="fieldArray?.arrayItems">
        <ng-container *ngFor="let arrayItem of fieldArray?.arrayItems">
          <ng-container *ngFor="let fieldGroupItem of arrayItem.formGroup">
            <div [style.order]="fieldGroupItem?.order">
              <ng-container
                *ngTemplateOutlet="
                  nestedBlock;
                  context: {
                    fieldGroupNameData: fieldGroupItem
                  }
                ">
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngFor="let fieldGroupItem of arrayItem.fieldGroup">
            <div [style.order]="fieldGroupItem?.order">
              <ng-container
                *ngTemplateOutlet="
                  fieldItemDataValues;
                  context: { fieldItemData: fieldGroupItem }
                ">
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #content let-modal let-content>
  <div class="modal-body">
    <div class="modal-header-preview">
      <h1 class="tt_norms500">
        <i class="fa fa-file-text"></i> {{ selectedAttachment?.fileName }}
      </h1>

      <div class="d-flex gap-3 flex-row">
        <ng-container *ngIf="selectedAttachment && base64Data">
          <button
            type="button"
            class="btn btn-sm btn-outline-secondary download-btn"
            aria-label="Close"
            (click)="downloadFile(base64Data, selectedAttachment.fileName)">
            <span class="icon-download-01"></span> Download
          </button>
        </ng-container>

        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"></button>
      </div>
    </div>

    <div *ngIf="loadingPreview" class="d-flex justify-content-center mt-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <ng-container
      *ngIf="
        selectedAttachment?.fileType === 'application/pdf' && base64Data;
        else imagePreview
      ">
      <pdf-viewer
        *ngIf="base64Data"
        [src]="base64Data"
        [render-text]="true"
        [original-size]="true"
        style="width: 100%; height: 80vh; margin-top: 15px"></pdf-viewer>
    </ng-container>
  </div>
</ng-template>

<ng-template #imagePreview>
  <img
    *ngIf="this.filePath"
    [src]="this.filePath"
    alt="preview"
    class="w-100 h-100 mt-3" />
</ng-template>

<ng-template
  let-fieldItemData="fieldItemData"
  let-content="content"
  let-index="index"
  #fieldItemDataValues>
  <ng-container
    *ngIf="
      fieldItemData?.fieldValue &&
      fieldItemData?.fieldType !== 'customfileupload'
    ">
    <ng-container
      *ngIf="multiLineValueFieldTypes.includes(fieldItemData.fieldType)">
      <div
        *ngFor="let value of getMultiLineValues(fieldItemData)"
        class="fieldValue">
        <p class="group-text dark-grey-color tt_norms400 m-0">
          {{ value?.label }}:
        </p>
        <p class="group-value tt_norms500 m-0">
          {{ value.key }}
        </p>
      </div>
    </ng-container>

    <div
      *ngIf="!multiLineValueFieldTypes.includes(fieldItemData.fieldType)"
      class="fieldValue">
      <p class="group-text dark-grey-color tt_norms400 m-0">
        {{ fieldItemData?.fieldLabel }}
      </p>
      <p class="group-value tt_norms500 m-0">
        {{ getSingleLineValue(fieldItemData) }}
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="fieldItemData?.fieldType === 'customfileupload'">
    <div class="d-flex">
      <div
        class="flex-shrink-0"
        (click)="previewAttachment(content, fieldItemData.fieldValue, index)">
        <img
          src="../../assets/images/file-icon.svg"
          class="file-preview-icon"
          alt="preview" />
      </div>
      <div class="flex-grow-1 ms-3">
        <p class="group-value dark-grey-color tt_norms400 mb-1">
          {{ fieldItemData?.fieldLabel }}
        </p>
        <p class="group-text dark-grey-color tt_norms500 m-0">
          {{
            getAttachmentValue(fieldItemData?.fieldValue).fileSize | fileSize
          }}
        </p>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template let-fieldGroupNameData="fieldGroupNameData" #nestedBlock>
  <div class="my-3">
    <irembogov-non-logeddin-application-form-field-card
      [group]="fieldGroupNameData"
      [applicationNumber]="applicationNumber"
      [baseUrl]="baseUrl"
      [applicationPath]="
        applicationPath
      "></irembogov-non-logeddin-application-form-field-card>
  </div>
</ng-template>
