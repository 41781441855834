<div
  class="irembogov-username-and-email-circle-icon d-flex gap-3 align-items-center">
  <div class="username-icon">
    <span class="username">
      {{ fullName | usernameInitials }}
    </span>
  </div>
  <div class="user-inline-details align-items-center">
    <div class="name">
      {{ fullName }}
    </div>
    <div
      [class]="email ? 'email' : 'email no-email'"
      *ngIf="email || noEmailPlaceholder">
      {{ email ? email : noEmailPlaceholder }}
    </div>
  </div>
</div>
