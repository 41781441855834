import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CustomDatePickerComponent } from '../components/formly/custom-date-picker/custom-date-picker.component';
import { CustomDropDownComponent } from '../components/formly/custom-drop-down/custom-drop-down.component';
import { CustomPhoneNumberComponent } from '../components/formly/custom-phone-number/custom-phone-number.component';
import { CustomIdInputComponent } from '../components/formly/custom-id-input/custom-id-input.component';
import { CustomTimePickerComponent } from '../components/formly/custom-time-picker/custom-time-picker.component';
import { CustomCascadingDropDownsComponent } from '../components/formly/custom-cascading-drop-downs/custom-cascading-drop-downs.component';
import { CustomFileUploadComponent } from '../components/formly/custom-file-upload/custom-file-upload.component';
import { CustomRepeaterComponent } from '../components/formly/custom-repeater/custom-repeater.component';
import { CustomLabelValueRepeaterComponent } from '../components/formly/custom-label-value-repeater/custom-label-value-repeater.component';
import { CustomDropdownPaginatedComponent } from '../components/formly/custom-dropdown-paginated/custom-dropdown-paginated.component';
import { getValueFromNestedObject } from '../../utils/utils/nested-object-extractor.util';
import { CustomApplicationNumberInputComponent } from '../components/formly/custom-application-number-input/custom-application-number-input.component';
import { CustomGenericDataFetchComponent } from '../components/formly/custom-generic-data-fetch/custom-generic-data-fetch.component';
import { CustomDoubleIdInputComponent } from '../components/formly/custom-double-id-input/custom-double-id-input.component';
import { CustomCrvsIdInputFetchWidgetComponent } from '../components/formly/custom-crvs-id-input-fetch-widget/custom-crvs-id-input-fetch-widget.component';
import { CustomTinInputComponent } from '../components/formly/custom-tin-input/custom-tin-input.component';
import { CustomOtpDataFetchComponent } from '../components/formly/custom-otp-data-fetch/custom-otp-data-fetch.component';
import { CustomSectionMessageWithLinkComponent } from '../components/formly/custom-section-message-with-link/custom-section-message-with-link.component';
import { CustomDateParserFormatterService } from '../services/custom-date-picker/custom-date-parser-formatter.service';
import { TDateFormatTypes } from '../models/date-picker-date-format.types';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { phoneNUmberRegexValidation } from '../../../lib/utils/utils/phone-number-regex-validation.util';
import { CustomSlotBookingComponent } from '../components/formly/custom-slot-booking/custom-slot-booking.component';
import { CustomDateTimePickerComponent } from '../../../public-api';

export function EmailValidatorMessage(
  error: unknown,
  field: FormlyFieldConfig
) {
  return `"${field.formControl?.value}" is not a valid email address`;
}

export function EmailValidator(
  control: AbstractControl
): ValidationErrors | null {
  return /^[\w\-\\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(control.value)
    ? null
    : { email: true };
}

export function PhoneNumberValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;
  return phoneNUmberRegexValidation(control.value)
    ? null
    : { invalidNumberFormat: true };
}

export function PhoneNumberValidatorMessage() {
  return `Only numbers are allowed.`;
}

const getGenericUtilValidatorPropValue = (
  prop: unknown,
  field: FormlyFieldConfig,
  operator?: string
): string | undefined | number | Record<string, unknown> => {
  if (typeof prop === 'string' || typeof prop === 'number') {
    return getGenericUtilValidatorPropValueByOperator(prop, operator);
  }

  if (Array.isArray(prop)) {
    let propValue: any = field;

    prop.some((path: string) => {
      if ((<Record<string, unknown>>propValue)[path]) {
        propValue = (<Record<string, unknown>>propValue)[path];
        return false;
      }
      propValue = undefined;
      return true;
    });

    return getGenericUtilValidatorPropValueByOperator(propValue, operator);
  }
  return undefined;
};

const getGenericUtilValidatorPropValueByOperator = (
  value: unknown,
  operation?: string
): any => {
  try {
    switch (operation) {
      case 'parseInt':
        return Number.parseInt(<string>value, 10);
      case 'toLowerCase':
        return (<string>value).toLowerCase();
      case 'toUpperCase':
        return (<string>value).toUpperCase();
      default:
        return value;
    }
  } catch (e: any) {
    return value;
  }
};

export function GenericUtilValidator(
  this: {
    name: EIremboFormlyValidationTypes;
    validation: (
      control: AbstractControl,
      field: FormlyFieldConfig,
      options?: Record<string, string>
    ) => ValidationErrors | null;
  },
  control: AbstractControl,
  field: FormlyFieldConfig,
  options: Record<string, string> = {}
): ValidationErrors | null {
  try {
    const { prop1, prop2, comparator, validationName, operator } = options;
    if (!(prop1 && prop2 && comparator && validationName)) {
      return null;
    }
    const prop1Value = getGenericUtilValidatorPropValue(prop1, field, operator);
    const prop2Value = getGenericUtilValidatorPropValue(prop2, field, operator);
    let validation = false;
    switch (comparator) {
      case '=':
        validation = prop1Value == prop2Value;
        break;
      case '===':
        validation = prop1Value === prop2Value;
        break;
      case '!=':
        validation = prop1Value !== prop2Value;
        break;
      case '!==':
        validation = prop1Value !== prop2Value;
        break;
      case '<':
        validation = (prop1Value ?? '') < (prop2Value ?? '');
        break;
      case '<=':
        validation = (prop1Value ?? '') <= (prop2Value ?? '');
        break;
      case '>':
        validation = (prop1Value ?? '') > (prop2Value ?? '');
        break;
      case '>=':
        validation = (prop1Value ?? '') >= (prop2Value ?? '');
        break;
      case 'includes':
        validation = (<string>prop1Value).includes(<string>prop2Value);
        break;
      case 'notContains':
        validation = !(<string>prop1Value).includes(<string>prop2Value);
        break;
      case 'endsWith':
        validation = (<string>prop1Value).endsWith(<string>prop2Value);
        break;
      case 'startsWith':
        validation = (<string>prop1Value).startsWith(<string>prop2Value);
        break;
      case 'length':
        validation =
          (<string>prop1Value).length === Number.parseInt(<string>prop2Value);
        break;
      default:
        break;
    }

    if (validation) {
      return null;
    }

    const validationErrorObj: ValidationErrors = {};
    validationErrorObj[validationName] = true;
    return validationErrorObj;
  } catch (e: unknown) {
    return null;
  }
}

export function UniqueFieldValidator(
  control: AbstractControl,
  field: FormlyFieldConfig,
  options: any
) {
  const fieldKeys = options.fieldKeys;
  if (!fieldKeys && typeof fieldKeys !== 'object') {
    return null;
  }
  const formData = field.formControl?.root.value;
  const validationErrors: Record<string, unknown> = {};

  for (const key in fieldKeys) {
    const sourceValue = findControlValueByKey(formData, key);
    const sourceFieldData = fieldKeys[key];
    const targetFields = sourceFieldData['fields'];
    const message = sourceFieldData['message'];

    if (Array.isArray(targetFields)) {
      targetFields.forEach((target_field: string) => {
        const targetValue = findControlValueByKey(formData, target_field);
        if (sourceValue && targetValue && sourceValue === targetValue) {
          validationErrors['uniqueField'] = {
            errorPath: findPathForKey(formData, target_field),
            message,
          };
        }
      });
    }
  }
  return Object.keys(validationErrors).length > 0 ? validationErrors : null;
}

export function CompareDateFieldsValidator(
  _control: AbstractControl,
  field: FormlyFieldConfig,
  options: any
) {
  const fieldKeys = options.fieldKeys;

  if (!fieldKeys && typeof fieldKeys !== 'object') {
    return null;
  }
  const formData = field.formControl?.root.value;
  const validationErrors: Record<string, unknown> = {};

  const delimeter: string | undefined = options.delimeter;
  const dateFormat: string | undefined = options.delimeter;
  const parserFormatter = new CustomDateParserFormatterService();
  parserFormatter.setDateFormatType(<TDateFormatTypes>dateFormat);
  parserFormatter.setDelimeter(delimeter);

  for (const key in fieldKeys) {
    const sourceValue: any = findControlValueByKey(formData, key);

    const sourceNgbDate = NgbDate.from(
      parserFormatter.parse(<string>sourceValue)
    );

    const sourceFieldComparisons: {
      comparator: string;
      fieldKey: string;
      message: string;
    }[] = fieldKeys[key];

    if (Array.isArray(sourceFieldComparisons)) {
      sourceFieldComparisons.forEach(
        (comparison: {
          comparator: string;
          fieldKey: string;
          message: string;
        }) => {
          const targetValue: any = findControlValueByKey(
            formData,
            comparison.fieldKey
          );

          const targetNgbDate = NgbDate.from(
            parserFormatter.parse(<string>targetValue)
          );
          switch (comparison.comparator) {
            case 'before': {
              if (
                compareBeforeDateField(
                  sourceValue,
                  targetValue,
                  sourceNgbDate,
                  targetNgbDate
                )
              ) {
                validationErrors['compareDateFields'] = {
                  errorPath: findPathForKey(formData, comparison.fieldKey),
                  message: comparison.message,
                };
              }
              break;
            }
            case 'after': {
              if (
                compareAfterDateField(
                  sourceValue,
                  targetValue,
                  sourceNgbDate,
                  targetNgbDate
                )
              ) {
                validationErrors['compareDateFields'] = {
                  errorPath: findPathForKey(formData, comparison.fieldKey),
                  message: comparison.message,
                };
              }
              break;
            }
            case 'beforeOrEquals': {
              if (
                compareBeforeOrEqualsDateField(
                  sourceValue,
                  targetValue,
                  sourceNgbDate,
                  targetNgbDate
                )
              ) {
                validationErrors['compareDateFields'] = {
                  errorPath: findPathForKey(formData, comparison.fieldKey),
                  message: comparison.message,
                };
              }
              break;
            }
            case 'afterOrEquals': {
              if (
                compareAfterOrEqualsDateField(
                  sourceValue,
                  targetValue,
                  sourceNgbDate,
                  targetNgbDate
                )
              ) {
                validationErrors['compareDateFields'] = {
                  errorPath: findPathForKey(formData, comparison.fieldKey),
                  message: comparison.message,
                };
              }
              break;
            }
            case 'equals': {
              if (
                compareEqualsDateFields(
                  sourceValue,
                  targetValue,
                  sourceNgbDate,
                  targetNgbDate
                )
              ) {
                validationErrors['compareDateFields'] = {
                  errorPath: findPathForKey(formData, comparison.fieldKey),
                  message: comparison.message,
                };
              }
              break;
            }
            default:
              break;
          }
        }
      );
    }
  }
  return Object.keys(validationErrors).length > 0 ? validationErrors : null;
}

function compareBeforeDateField(
  sourceValue: any,
  targetValue: any,
  sourceNgbDate: NgbDate | null,
  targetNgbDate: NgbDate | null
): boolean {
  return sourceValue && targetValue && !sourceNgbDate?.before(targetNgbDate);
}

function compareAfterDateField(
  sourceValue: any,
  targetValue: any,
  sourceNgbDate: NgbDate | null,
  targetNgbDate: NgbDate | null
): boolean {
  return sourceValue && targetValue && !sourceNgbDate?.after(targetNgbDate);
}

function compareBeforeOrEqualsDateField(
  sourceValue: any,
  targetValue: any,
  sourceNgbDate: NgbDate | null,
  targetNgbDate: NgbDate | null
): boolean {
  return (
    sourceValue &&
    targetValue &&
    !(
      sourceNgbDate?.equals(targetNgbDate) ||
      sourceNgbDate?.before(targetNgbDate)
    )
  );
}

function compareAfterOrEqualsDateField(
  sourceValue: any,
  targetValue: any,
  sourceNgbDate: NgbDate | null,
  targetNgbDate: NgbDate | null
): boolean {
  return (
    sourceValue &&
    targetValue &&
    !(
      sourceNgbDate?.equals(targetNgbDate) ||
      sourceNgbDate?.after(targetNgbDate)
    )
  );
}

function compareEqualsDateFields(
  sourceValue: any,
  targetValue: any,
  sourceNgbDate: NgbDate | null,
  targetNgbDate: NgbDate | null
): boolean {
  return sourceValue && targetValue && !sourceNgbDate?.equals(targetNgbDate);
}
function findControlValueByKey(obj: any, targetKey: string): any {
  if (Object.prototype.hasOwnProperty.call(obj, targetKey)) {
    return obj[targetKey];
  }
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const result = findControlValueByKey(obj[key], targetKey);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

function findPathForKey(
  obj: any,
  targetKey: string,
  currentPath = ''
): string | null {
  for (const key in obj) {
    const newPath = currentPath ? `${currentPath}.${key}` : key;
    if (typeof obj[key] === 'object') {
      const result = findPathForKey(obj[key], targetKey, newPath);
      if (result) {
        return result;
      }
    } else if (key === targetKey) {
      return newPath;
    }
  }
  return null;
}

export function IsLessThanFieldValidator(
  control: AbstractControl,
  field: FormlyFieldConfig,
  options: any
): ValidationErrors | null {
  const fieldKey: string | undefined = field.model?.[options.fieldKey];
  if (!options?.fieldKey) return null;
  if (!fieldKey) return null;

  const returnValue =
    control.value < fieldKey
      ? null
      : { [EIremboFormlyValidationTypes.isLessThanField]: true };

  return returnValue;
}

export function IsGreaterThanFieldValidator(
  control: AbstractControl,
  field: FormlyFieldConfig,
  options: any
): ValidationErrors | null {
  if (!options?.fieldKey) return null;
  if (!control.value) return null;

  return control.value > field.model?.[options.fieldKey]
    ? null
    : { [EIremboFormlyValidationTypes.isGreaterThanField]: true };
}

export function JsonKeyValueIsEqualToValidator(
  control: AbstractControl,
  _: FormlyFieldConfig,
  options: any
): ValidationErrors | null {
  return jsonKeyValueEqualValidationHelper(control, _, options);
}

export function JsonKeyValueIsNotEqualToValidator(
  control: AbstractControl,
  _: FormlyFieldConfig,
  options: any
): ValidationErrors | null {
  return jsonKeyValueEqualValidationHelper(control, _, options, true);
}

export function repeaterUniqueFieldValidator(
  control: AbstractControl,
  _field: FormlyFieldConfig
): ValidationErrors | null {
  const repeaterFields = _field?.parent?.parent?.fieldGroup;
  const currentRepeaterField = _field?.parent;
  let isDuplicate = false;

  if (repeaterFields && currentRepeaterField) {
    const currentRepeaterIndex = repeaterFields?.indexOf(currentRepeaterField);
    const repeaterValues = control?.parent?.parent?.value;
    const key = _field?.key?.toString();

    if (repeaterValues && key) {
      isDuplicate = repeaterValues.some(
        (repeaterValue: any, index: number) =>
          repeaterValue[key] === control.value && index !== currentRepeaterIndex
      );
    }
  }

  return isDuplicate
    ? null
    : { [EIremboFormlyValidationTypes.repeaterUniqueField]: true };
}

const jsonKeyValueEqualValidationHelper = (
  control: AbstractControl,
  _: FormlyFieldConfig,
  options: any,
  inverseCheck = false
): ValidationErrors | null => {
  // check if elements needed to run validation check are available
  if (!control?.value || !options?.key || !options?.value) return null;
  // get value from object
  let valueFromObj: any;
  try {
    valueFromObj = getValueFromNestedObject(control?.value, options?.key);
  } catch (error) {
    // catch error getting value from object, indicating that property is not found
    return inverseCheck
      ? { [EIremboFormlyValidationTypes.jsonKeyValueIsNotEqualTo]: true }
      : { [EIremboFormlyValidationTypes.jsonKeyValueIsEqualTo]: true };
  }
  // check if value found is equal to configured to expect

  if (inverseCheck) {
    return valueFromObj !== options?.value
      ? null
      : { [EIremboFormlyValidationTypes.jsonKeyValueIsNotEqualTo]: true };
  }

  return valueFromObj === options?.value
    ? null
    : { [EIremboFormlyValidationTypes.jsonKeyValueIsEqualTo]: true };
};

export function formlyConfigs() {
  return {
    types: [
      {
        name: EIremboFormlyFieldTypes.customdatepicker,
        component: CustomDatePickerComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customdatetimepicker,
        component: CustomDateTimePickerComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customdropdown,
        component: CustomDropDownComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customphonenumber,
        component: CustomPhoneNumberComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customtimepicker,
        component: CustomTimePickerComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customidinput,
        component: CustomIdInputComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customdoubleidinput,
        component: CustomDoubleIdInputComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customcascadingdropdowns,
        component: CustomCascadingDropDownsComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customfileupload,
        component: CustomFileUploadComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customrepeater,
        component: CustomRepeaterComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customlabelvaluerepeater,
        component: CustomLabelValueRepeaterComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customdropdownpaginated,
        component: CustomDropdownPaginatedComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customapplicationnumberinput,
        component: CustomApplicationNumberInputComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customgenericdatafetch,
        component: CustomGenericDataFetchComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customotpdatafetch,
        component: CustomOtpDataFetchComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customcrvsidinput,
        component: CustomCrvsIdInputFetchWidgetComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customtininput,
        component: CustomTinInputComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customsectionmessagewithlink,
        component: CustomSectionMessageWithLinkComponent,
      },
      {
        name: EIremboFormlyFieldTypes.customslotbooking,
        component: CustomSlotBookingComponent,
      },
    ],
    validators: [
      { name: EIremboFormlyValidationTypes.email, validation: EmailValidator },
      {
        name: EIremboFormlyValidationTypes.requiredTrue,
        validation: Validators.requiredTrue,
      },
      {
        name: EIremboFormlyValidationTypes.genericUtilValidator,
        validation: GenericUtilValidator,
      },
      {
        name: EIremboFormlyValidationTypes.invalidNumberFormat,
        validation: PhoneNumberValidator,
      },
      {
        name: EIremboFormlyValidationTypes.uniqueField,
        validation: UniqueFieldValidator,
        options: { fields: [] },
      },
      {
        name: EIremboFormlyValidationTypes.isLessThanField,
        validation: IsLessThanFieldValidator,
        options: { fieldKey: undefined },
      },
      {
        name: EIremboFormlyValidationTypes.isGreaterThanField,
        validation: IsGreaterThanFieldValidator,
        options: { fieldKey: undefined },
      },
      {
        name: EIremboFormlyValidationTypes.jsonKeyValueIsEqualTo,
        validation: JsonKeyValueIsEqualToValidator,
        options: { key: undefined, value: undefined },
      },
      {
        name: EIremboFormlyValidationTypes.jsonKeyValueIsNotEqualTo,
        validation: JsonKeyValueIsNotEqualToValidator,
        options: { key: undefined, value: undefined },
      },
      {
        name: EIremboFormlyValidationTypes.compareDateFields,
        validation: CompareDateFieldsValidator,
        options: { fields: [] },
      },
      {
        name: EIremboFormlyValidationTypes.repeaterUniqueField,
        validation: repeaterUniqueFieldValidator,
      },
    ],
    validationMessages: [
      { name: 'email', message: EmailValidatorMessage },
      { name: 'invalidNumberFormat', message: PhoneNumberValidatorMessage },
    ],
  };
}

export enum EIremboFormlyFieldTypes {
  // Containers
  'formly-group' = 'formly-group',
  'sections' = 'sections',
  'block' = 'block',

  // Formly defaults
  'input' = 'input',
  'select' = 'select',
  'checkbox' = 'checkbox',
  'multicheckbox' = 'multicheckbox',
  'radio' = 'radio',
  'textarea' = 'textarea',

  // Irembo customs
  'customdatepicker' = 'customdatepicker',
  'customdatetimepicker' = 'customdatetimepicker',
  'customdropdown' = 'customdropdown',
  'customphonenumber' = 'customphonenumber',
  'customtimepicker' = 'customtimepicker',
  'customidinput' = 'customidinput',
  'customdoubleidinput' = 'customdoubleidinput',
  'customcascadingdropdowns' = 'customcascadingdropdowns',
  'customfileupload' = 'customfileupload',
  'customrepeater' = 'customrepeater',
  'customlabelvaluerepeater' = 'customlabelvaluerepeater',
  'customdropdownpaginated' = 'customdropdownpaginated',
  'customapplicationnumberinput' = 'customapplicationnumberinput',
  'customgenericdatafetch' = 'customgenericdatafetch',
  'customotpdatafetch' = 'customotpdatafetch',
  'customcrvsidinput' = 'customcrvsidinput',
  'customtininput' = 'customtininput',
  'customsectionmessagewithlink' = 'customsectionmessagewithlink',
  'customslotbooking' = 'customslotbooking',
}
export enum EIremboFormlyFieldSubTypes {
  CUSTOM_ID_INPUT_NID = 'CUSTOM_ID_INPUT_NID',
  CUSTOM_ID_INPUT_CHILD_ID = 'CUSTOM_ID_INPUT_CHILD_ID',
  CUSTOM_ID_INPUT_NIN = 'CUSTOM_ID_INPUT_NIN',
  CUSTOM_DROPDOWN_DATAFETCH = 'CUSTOM_DROPDOWN_DATAFETCH',
  CUSTOM_DROPDOWN_DATAFETCH_PAGINATED = 'CUSTOM_DROPDOWN_DATAFETCH_PAGINATED',
  CUSTOM_DROPDOWN_DATASET = 'CUSTOM_DROPDOWN_DATASET',
  CUSTOM_DROPDOWN_DATASET_PAGINATED = 'CUSTOM_DROPDOWN_DATASET_PAGINATED',
  CUSTOM_DATEPICKER_DATASET = 'CUSTOM_DATEPICKER_DATASET',
}

export declare enum EIremboFormlyWidgetSubTypes {
  LOCATION = 'LOCATION',
}

export enum EIremboFormlyValidationTypes {
  'genericUtilValidator' = 'genericUtilValidator',
  'email' = 'email',
  'minLength' = 'minLength',
  'maxLength' = 'maxLength',
  'required' = 'required',
  'requiredTrue' = 'requiredTrue',
  'invalidNumberFormat' = 'invalidNumberFormat',
  'pattern' = 'pattern',
  'uniqueField' = 'uniqueField',
  'isLessThanField' = 'isLessThanField',
  'isGreaterThanField' = 'isGreaterThanField',
  'invalidInput' = 'invalidInput',
  'invalidId' = 'invalidId',
  'minimumUploadSize' = 'minimumUploadSize',
  'maximumUploadSize' = 'maximumUploadSize',
  'invalidfileformat' = 'invalidfileformat',
  'invalidNumber' = 'invalidNumber',
  'jsonKeyValueIsEqualTo' = 'jsonKeyValueIsEqualTo',
  'jsonKeyValueIsNotEqualTo' = 'jsonKeyValueIsNotEqualTo',
  'invalidAppNo' = 'invalidAppNo',
  'invalidGenericInput' = 'invalidGenericInput',
  'invalidTIN' = 'invalidTIN',
  'compareDateFields' = 'compareDateFields',
  'repeaterUniqueField' = 'repeaterUniqueField',
}

export const LOCATION_WIDGET_LEVELS: Record<string, string> = {
  PROVINCE: 'Province',
  DISTRICT: 'District',
  SECTOR: 'Sector',
  CELL: 'Cell',
  VILLAGE: 'Village',
};

export const LOCATION_COUNTRY_KEY = 'COUNTRY';
