import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged, fromEvent, Subscription } from 'rxjs';
import { TDateFormatTypes } from '../../models/date-picker-date-format.types';

@Component({
  selector: 'irembogov-date-time-picker',
  templateUrl: './irembo-date-time-picker.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboDateTimePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IremboDateTimePickerComponent),
      multi: true,
    },
  ],
})
export class IremboDateTimePickerComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, Validator
{
  private readonly controlSub: Subscription = new Subscription();
  //Date Picker
  newDate = new Date();
  @Output() selectedDateEvent = new EventEmitter<string | null>();
  @Input() requiredField = false;
  @Input() disabled = false;
  @Input() startDate: NgbDateStruct = {
    year: this.newDate.getFullYear(),
    month: this.newDate.getMonth() + 1,
    day: this.newDate.getDay(),
  };
  @Input() dateFormat: TDateFormatTypes = 'DD MM YYYY';
  @Input() delimeter? = '-';
  @Input() minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };
  @Input() maxDate: NgbDateStruct = {
    year: this.newDate.getFullYear() + 10,
    month: 12,
    day: 31,
  };
  @Input() placeholder? = 'Select Date';
  @Input() allowedDaysOfTheWeek?: number[] = undefined;
  @Input() addRemoveTimeMinDate? = '';
  @Input() addRemoveTimeMaxDate? = '';
  @Input() eventCode? = '';
  @Input() eventDatesMaxSize? = '';

  // Time picker
  @Input() interval = 30;
  @Input() startHour = 8;
  @Input() endHour = 16;
  @Input() labelForId: unknown;
  @Input() customFormControl = new FormControl();
  @Output() itemSelected = new EventEmitter<string | null>();
  timeSlots: Record<string, string>[] = [];

  datePickerFormControl = new FormControl();
  timePickerFormControl = new FormControl();

  @ViewChild('dateTimePickerFormControlEl') dateTimePickerFormControlEl:
    | ElementRef
    | undefined;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.datePickerFormControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.setDateTimeValue();
      });

    this.timePickerFormControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.setDateTimeValue();
      });
  }

  ngAfterViewInit(): void {
    if (this.dateTimePickerFormControlEl) {
      this.controlSub.add(
        fromEvent(
          this.dateTimePickerFormControlEl.nativeElement,
          'blur'
        ).subscribe({
          next: () => {
            this._onTouch(this.generateDateTimeValue());
            this._onValidationChange();
            this.cdRef.detectChanges();
          },
        })
      );
    }
  }

  private generateDateTimeValue(): string | null {
    return `${this.datePickerFormControl.value} : ${this.timePickerFormControl.value}`;
  }

  setDateTimeValue(): void {
    const dateTimeValue = this.generateDateTimeValue();
    this._onChange(dateTimeValue);
    this._onTouch(dateTimeValue);
    this._onValidationChange();
    this.cdRef.detectChanges();
  }

  validate(formControl: FormControl): ValidationErrors | null {
    const validationErrors: ValidationErrors = {};
    this.cdRef.detectChanges();

    if (this.requiredField && !formControl.value) {
      validationErrors['required'] = true;
    }

    if (this.datePickerFormControl.errors?.['invalid']) {
      validationErrors['invalid'] = true;
    }

    if (this.datePickerFormControl.errors?.['minDate']) {
      validationErrors['minDate'] = true;
    }

    if (this.datePickerFormControl.errors?.['maxDate']) {
      validationErrors['maxDate'] = true;
    }

    if (this.datePickerFormControl.errors?.['unavailableSlots']) {
      validationErrors['unavailableSlots'] = true;
    }

    if (this.datePickerFormControl.errors?.['dayOfWeekIsNotAllowed']) {
      validationErrors['dayOfWeekIsNotAllowed'] = true;
    }

    if (
      (this.datePickerFormControl.value && !this.timePickerFormControl.value) ||
      (!this.datePickerFormControl.value && this.timePickerFormControl.value)
    ) {
      validationErrors['dateAndTimeValuesRequired'] = true;
    }

    if (this.timePickerFormControl.invalid) {
      validationErrors['invalidTimeValue'] = true;
    }

    if (
      formControl.value &&
      this.validateControlValueOnRegexPattern(formControl.value)
    ) {
      validationErrors['invalidDateTimeFormat'] = true;
    }

    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  private validateControlValueOnRegexPattern(controlValue: string): boolean {
    const dateRegexString = '(0?[1-9]|[12][0-9]|3[01])';
    const monthRegexString = '(0?[1-9]|1[0-2])';
    const yearAndTimeRegexString =
      '([0-9]{4})\\s:\\s(0?[1-9]|1[0-2]):([0-5][0-9])\\s([AP][M])';
    const delimeterRegex = (this.delimeter ?? '//').replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&'
    );

    let valueTestRegExString = `\\b${dateRegexString}${delimeterRegex}${monthRegexString}${delimeterRegex}${yearAndTimeRegexString}\\b`;

    if (this.dateFormat === 'MM DD YYYY') {
      valueTestRegExString = `\\b${monthRegexString}${delimeterRegex}${dateRegexString}${delimeterRegex}${yearAndTimeRegexString}\\b`;
    }

    return !new RegExp(valueTestRegExString).test(controlValue);
  }

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  private _onTouch = (value: unknown) => {};
  private readonly _onValidationChange = () => {};
  /* eslint-enable */

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.timePickerFormControl.disable();
      this.datePickerFormControl.disable();
      return;
    }
    this.timePickerFormControl.enable();
    this.datePickerFormControl.enable();
  }

  writeValue(value: unknown): void {
    if (value) {
      const valueParts: string[] = (<string>value).split(' : ');
      this.datePickerFormControl.setValue(valueParts.shift() ?? null);
      this.timePickerFormControl.setValue(valueParts.join(':'));
    } else {
      this.datePickerFormControl.setValue(null);
      this.timePickerFormControl.setValue(null);
    }
  }
  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  ngOnDestroy(): void {
    this.controlSub?.unsubscribe();
  }
}
