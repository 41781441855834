import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdentityVerificationService {
  private showIdentityVerificationSubject = new BehaviorSubject<boolean>(false);
  showIdentityVerification$ =
    this.showIdentityVerificationSubject.asObservable();

  // Method to update the value
  setShowIdentityVerification(value: boolean) {
    this.showIdentityVerificationSubject.next(value);
  }
}
