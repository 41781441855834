<div class="wrapper-content">
  <ng-container
    *ngIf="hintPosition === EHintPositions.TOP"
    [ngTemplateOutlet]="hintTemplate"
    [ngTemplateOutletContext]="{
      hintText: props['hint']
    }"></ng-container>

  <ng-container #fieldComponent></ng-container>

  <ng-container
    *ngIf="hintPosition === EHintPositions.BOTTOM"
    [ngTemplateOutlet]="hintTemplate"
    [ngTemplateOutletContext]="{
      hintText: props['hint']
    }"></ng-container>
</div>

<ng-template let-hintText="hintText" #hintTemplate>
  <strong *ngIf="hintText" class="small d-block w-100 m-2 text-primary">
    <i class="fa-solid fa-circle-info me-1"></i>
    <span [innerHTML]="hintText"></span>
  </strong>
</ng-template>
