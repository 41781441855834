import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ERROR_MAP } from '../../../data/errorMap';
import { AccountResendOtpRequestDto } from '../../../models/account-resend-otp-request.model';
import { environment } from '../../../../environments/environment';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../service/auth.service';
import {
  CitizenCreationRequestDto,
  RecipientType,
  UserType,
} from '../../../models/citizenCreationRequest.model';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'irembogov-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateAccountComponent implements OnInit {
  ACCOUNT_USER_ALREADY_EXIST_NOT_ACTIVATED_ERROR =
    'ACCOUNT_USER_ALREADY_EXIST_NOT_ACTIVATED';
  username = '';
  termsAndConditions = false;
  isLoading = false;
  errorMessage = '';
  usernameValid = false;
  accountAlreadyExist = false;
  usernameIsPhone = false;
  activeToggle: 'phone' | 'email' = 'phone';
  showPassword = false;
  showConfirmPassword = false;
  year = new Date().getFullYear();
  registrationForm!: FormGroup;
  locale = '';

  env = environment;
  httpError: string | undefined;

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.setFlowLocalStorage();
    this.locale = localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
  }

  get PhoneNumber() {
    return this.registrationForm?.get('phone_number');
  }

  get Email() {
    return this.registrationForm?.get('email');
  }

  get Password() {
    return this.registrationForm?.get('password');
  }

  get confirmPassword() {
    return this.registrationForm?.get('confirm_password');
  }

  private initForm(): void {
    this.registrationForm = new FormGroup({
      email: new FormControl('', Validators.email),
      phone_number: new FormControl('', Validators.pattern('07\\d{8}')),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[@#$%^&+=!])(?!.*\s).{8,}$/
        ),
      ]),
      confirm_password: new FormControl('', [
        Validators.minLength(8),
        Validators.required,
      ]),
    });
    this.registrationForm
      .get('confirm_password')
      ?.setValidators(this.passwordMatchValidator.bind(this));
  }

  private setFlowLocalStorage(): void {
    window.localStorage.setItem('flow', 'registration');
  }

  passwordMatchValidator(
    control: AbstractControl
  ): { [s: string]: boolean } | null {
    if (this.registrationForm && control.value !== this.Password?.value) {
      return { passwordNotMatch: true };
    }
    return null;
  }

  toggleActive(active: 'phone' | 'email') {
    this.registrationForm.reset();
    this.errorMessage = '';
    this.activeToggle = active;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  onSaveAgreeTermsChanged(value: boolean): void {
    this.termsAndConditions = value;
  }

  onFormSubmit(): void {
    this.isLoading = true;
    this.errorMessage = '';
    this.httpError = '';

    const username =
      this.activeToggle === 'phone'
        ? this.PhoneNumber?.value
        : this.Email?.value;

    if (!this.termsAndConditions) {
      this.errorMessage = `Please confirm that you've read and agree to IremboGov's user agreement and privacy policy.`;
      this.isLoading = false;
      return;
    }

    if (!this.confirmPassword?.value) {
      this.confirmPassword?.setErrors({ required: true });
      this.errorMessage = 'Confirm password cannot be empty.';
      this.isLoading = false;
      return;
    }

    this.authService.checkIfAccountExist(username).subscribe({
      next: response => {
        this.isLoading = false;
        if (
          response.status &&
          response.responseCode === 'ACCOUNT_CHECK_FOUND'
        ) {
          this.accountAlreadyExist = true;
          this.handleUsernameExistError();
        }

        if (
          response.status &&
          response.responseCode ===
            this.ACCOUNT_USER_ALREADY_EXIST_NOT_ACTIVATED_ERROR
        ) {
          this.accountAlreadyExist = true;
          this.handleInactiveAccountError(username);
        }
      },
      error: (err: any) => {
        if (err?.status === 404) {
          this.usernameValid = true;
          this.accountAlreadyExist = false;
          this.errorMessage = '';
          this.processForm(username);
        } else {
          this.httpError = 'An error has occurred, please try again';
          this.isLoading = false;
        }
      },
    });
  }

  handleUsernameExistError(): void {
    if (this.activeToggle === 'phone') {
      this.accountAlreadyExist = true;
      this.errorMessage =
        'This phone number is already associated with an account. Please try logging in instead, or use a different phone number to create a new account.';
    } else {
      this.errorMessage =
        'This email is already associated with an account. Please try logging in instead, or use a different email to create a new account.';
    }
  }

  handleInactiveAccountError(username: string): void {
    this.username = username;
    this.toastService.show({
      body: this.translate.instant(
        'Your account already exists but not activated'
      ),
      type: 'warning',
      delay: 5000,
    });
    if (this.activeToggle === 'phone') {
      this.resendPhoneOtp();
    } else {
      this.resendEmailActivationLink();
    }
  }

  processForm(username: string): void {
    if (this.usernameValid) {
      if (this.activeToggle === 'phone') {
        this.phoneFlow(username);
      } else {
        this.emailFlow(username);
      }
    }
  }

  private emailFlow(email: string): void {
    const citizenCreationRequestDto: CitizenCreationRequestDto = {
      username: email,
      password: this.registrationForm.get('password')?.value,
      usernameType: RecipientType.EMAIL_ADDRESS,
      locale: this.locale,
    };

    this.authService
      .createCitizen(citizenCreationRequestDto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.handleEmailFlowSuccess(email);
        },
        error: (err: HttpErrorResponse) => {
          this.handleError(err);
        },
      });
  }

  private handleEmailFlowSuccess(email: string): void {
    window.localStorage.setItem('regType', 'email');
    this.router.navigate(['auth/verify'], {
      queryParams: { email },
    });
  }

  private phoneFlow(phone: string): void {
    const citizenCreationRequestDto: CitizenCreationRequestDto = {
      username: phone,
      usernameType: RecipientType.PHONE_NUMBER,
      password: this.registrationForm.get('password')?.value,
      locale: this.locale,
    };

    this.authService
      .createCitizen(citizenCreationRequestDto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.handlePhoneFlowSuccess(phone);
        },
        error: (err: HttpErrorResponse) => {
          this.handleError(err);
        },
      });
  }

  private handlePhoneFlowSuccess(phone: string): void {
    window.localStorage.setItem('regType', 'phone');
    this.router.navigate(['auth/verify'], {
      queryParams: { phone },
    });
  }

  private handleError(err: HttpErrorResponse): void {
    const errorCode = err.error.responseCode as string;
    this.errorMessage =
      ERROR_MAP[errorCode] ?? err.error?.message ?? 'Fail to create account';
  }

  customErrorMessages(): string[] {
    return [
      'EMAIL_ALREADY_EXIST_DEACTIVATED',
      'PHONE_NUMBER_ALREADY_EXIST_DEACTIVATED',
      'ACCOUNT_USER_ALREADY_EXIST_NOT_ACTIVATED',
    ];
  }

  resendEmailActivationLink() {
    this.resendOtp(RecipientType.EMAIL_ADDRESS);
  }

  resendPhoneOtp() {
    this.resendOtp(RecipientType.PHONE_NUMBER);
  }

  resendOtp(usernameType: RecipientType) {
    const username = this.username;
    const accountResendOtpRequestDto: AccountResendOtpRequestDto = {
      clientId: environment.authClientId,
      usernameType: usernameType,
      username: username,
      userType: UserType.CITIZEN,
      locale: this.locale,
    };

    this.authService.resendAccountLink(accountResendOtpRequestDto).subscribe({
      next: () => {
        this.handleOtpResendSuccess(username, usernameType);
      },
      error: (err: HttpErrorResponse) => {
        this.handleError(err);
      },
    });
  }

  private handleOtpResendSuccess(
    username: string,
    usernameType: RecipientType
  ): void {
    if (usernameType === RecipientType.PHONE_NUMBER) {
      this.handlePhoneFlowSuccess(username);
    } else {
      this.handleEmailFlowSuccess(username);
    }
  }

  backToPreviousScreen() {
    this.usernameValid = false;
  }

  onLocaleChanged(): void {
    this.locale = localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
  }
}
