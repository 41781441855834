import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';
import { finalize } from 'rxjs';
import { ERROR_MAP } from '../../data/errorMap';
import { VerifyIdentityOtp } from '../../models/common.model';
import { AuthService } from '../../modules/auth/service/auth.service';

@Component({
  selector: 'irembogov-verify-phone-number',
  templateUrl: './verify-phone-number.component.html',
  styleUrls: ['./verify-phone-number.component.scss'],
})
export class VerifyPhoneNumberComponent {
  @Input() phoneNumber = '';
  @Input() restartTimer!: boolean;
  @Output() phoneVerified: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() resendOtp: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() goBack = new EventEmitter<void>();

  constructor(private authService: AuthService) {}

  @ViewChild('cd', { static: false }) private countdown:
    | CountdownComponent
    | undefined;

  errorMessage = '';
  isLoading = false;
  email = '';
  verificationType = '';

  onError(errorMessage: string): void {
    this.isLoading = false;
    this.errorMessage = errorMessage;
  }

  onFormSubmit(otp: any): void {
    this.isLoading = true;
    this.errorMessage = '';
    const identityOtp: VerifyIdentityOtp = {
      token: otp,
    };

    this.performHttpRequest(
      this.authService.validateIdentiyOtp(identityOtp),
      () => this.phoneVerified.emit(true)
    );
  }

  resendLink(): void {
    this.errorMessage = '';
    this.countdown?.restart();
    this.resendOtp.emit(true);
  }

  getEncodedString(str: string): string {
    let encoded = '';
    try {
      encoded = btoa(str ?? '');
    } catch (error) {
      console.log(error);
    }

    return encoded;
  }

  private performHttpRequest(request: any, successCallback: () => void): void {
    request.pipe(finalize(() => (this.isLoading = false))).subscribe({
      next: () => {
        successCallback();
      },
      error: (err: HttpErrorResponse) => {
        const errorCode = err.error.responseCode as string;

        this.errorMessage =
          ERROR_MAP[errorCode] ??
          err?.error?.responseMessage ??
          err?.error?.message ??
          'Fail to verify otp';
      },
    });
  }

  onGoBack() {
    this.goBack.emit();
  }
}
