import { KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from '../interfaces';
import { shouldAddToken } from './should-add-token.util';

/**
 * Config Layer for initializing keycloak
 * @param keycloakService keycloak's angular service
 * @param keycloakConfig additional required config per application
 * @returns keycloak's UI config
 */
export function initializeKeycloak(
  keycloakService: KeycloakService,
  keycloakConfig: KeycloakConfig
) {
  return () =>
    keycloakService.init({
      config: {
        url: keycloakConfig.url,
        realm: keycloakConfig.realm,
        clientId: keycloakConfig.clientId,
      },
      initOptions: {
        pkceMethod: 'S256',
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
      shouldAddToken: shouldAddToken,
    });
}
