import { Component, Input } from '@angular/core';

@Component({
  selector: 'irembogov-username-and-email-circle-icon',
  templateUrl: './username-and-email-circle-icon.component.html',
  styleUrls: ['./username-and-email-circle-icon.component.scss'],
})
export class UsernameAndEmailCircleIconComponent {
  @Input() fullName = '';
  @Input() email = '';
  @Input() noEmailPlaceholder = '*Unverified Account';
}
