<ng-container>
  <span class="hint-item activated-hint-wrapper" *ngIf="props?.['tooltip']">
    <span
      class="hint-tool-tip"
      [placement]="tooltipPlacement"
      [ngbTooltip]="props['tooltip']">
      <i aria-hidden="true" class="fas fa-circle-info ms-2"></i>
    </span>
  </span>
  <ng-container #fieldComponent></ng-container>
</ng-container>
