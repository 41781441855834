<div class="mb-3 irembogov-custom-repeater">
  <div
    *ngFor="let fieldItem of field.fieldGroup; let i = index"
    class="d-flex align-items-baseline custom-repeater-extra-fields">
    <formly-field
      class="repeater-group repeater-extra-fields"
      [field]="fieldItem"></formly-field>
    <ng-container *ngIf="!field?.props?.readonly">
      <button
        class="btn btn-danger ms-2 repeater-add-button"
        [disabled]="field.props.disabled"
        type="button"
        (click)="remove(i)">
        <i class="fa-solid fa-trash-can"></i></button
    ></ng-container>
  </div>
  <div class="my-1" *ngIf="!field?.props?.readonly">
    <button
      [disabled]="field.props.disabled || checkIfMaxLengthLimitReached()"
      class="btn btn-primary btn-sm"
      type="button"
      (click)="add()">
      <i class="fa-solid fa-plus"></i> {{ props['addText'] ?? 'Add' }}
    </button>
  </div>
</div>
