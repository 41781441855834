import { HttpErrorResponse } from '@angular/common/http';

export interface IHttpErrorResponseCodeAndMessage {
  message: string;
  code: string | null;
}

export const ExtractHttpErrorResponseMessage = (
  error: HttpErrorResponse,
  defaultMessage: string
): string => {
  let errorMessage =
    error && error.error && error.error.responseMessage
      ? error.error.responseMessage
      : '';
  if (!errorMessage) {
    errorMessage =
      error && error.error && error.error.message
        ? error.error.message
        : defaultMessage;
  }
  return errorMessage;
};

export const ExtractHttpErrorResponseCode = (
  error: HttpErrorResponse
): string | null => {
  return error && error.error && error.error.responseCode
    ? error.error.responseCode
    : null;
};

export const ExtractHttpErrorResponseCodeAndMessage = (
  error: HttpErrorResponse,
  defaultMessage: string
): IHttpErrorResponseCodeAndMessage => {
  return {
    message: ExtractHttpErrorResponseMessage(error, defaultMessage),
    code: ExtractHttpErrorResponseCode(error),
  };
};
