import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'customTranslate',
})
export class CustomTranslatePipe
  extends TranslatePipe
  implements PipeTransform
{
  override transform(value: string) {
    const translatedValue = super.transform(value);

    if (
      translatedValue === null ||
      translatedValue === undefined ||
      translatedValue.trim() === ''
    ) {
      return value;
    }

    return translatedValue;
  }
}
