<div *ngIf="supportedLocales.length > 0" ngbDropdown>
  <a
    ngbDropdownToggle
    type="button"
    class="btn btn-nav-secondary w-100 d-flex justify-content-between align-items-center"
    [ngClass]="{
      'p-0': styling === 'MOBILE',
      'mt-1': styling === 'NAVBAR'
    }">
    <div class="d-flex justify-content-between align-items-center gap-2">
      <i
        class="fa fa-globe"
        [ngClass]="{ 'text-black': styling === 'NONAVBAR' }"></i>
      <span
        class="ms-1"
        [ngClass]="{ 'mobile-nav-item': styling !== 'NONAVBAR' }"
        >{{ activeLocaleName === 'en-US' ? 'English' : activeLocaleName }}</span
      >
    </div>
    <i
      class="icon-chevron-down ms-auto"
      [ngClass]="{
        'mobile-nav-item': styling !== 'NONAVBAR',
        'text-muted': styling === 'NONAVBAR'
      }"></i>
  </a>

  <ul
    class="dropdown-menu dropdown-menu-end language-dd"
    ngbDropdownMenu
    aria-labelledby="userProfile_dd">
    <ng-container *ngFor="let item of supportedLocales">
      <li ngbDropdownItem class="" *ngIf="activeLocale !== item.locale">
        <a class="dropdown-link cursor-pointer" (click)="changeLocale(item)">
          <div
            class="d-flex flex-row justify-content-start align-items-center gap-2">
            {{ item.nativeName }}
          </div>
        </a>
      </li>
    </ng-container>
  </ul>
</div>
