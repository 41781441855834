<irembogov-generic-data-fetch
  [formControl]="formControl"
  [id]="field.key"
  class="w-100"
  [genericInputLength]="field.props['genericInputLength']"
  [inputLenghtAsMinLength]="field.props['inputLenghtAsMinLength'] ?? false"
  [placeholder]="field.props['placeholder']"
  [statusClass]="statusClass"
  [triggerType]="field.props['triggerType']"
  [disableInternalFormControl]="field.props['disableInternalFormControl']"
  [successMsg]="field.props['successMessage']"
  (dataFetch)="onDataFetch($event)"></irembogov-generic-data-fetch>
