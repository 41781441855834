import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'irembogov-tooltip-field-wrapper',
  templateUrl: './tooltip-field-wrapper.component.html',
  styleUrls: ['./tooltip-field-wrapper.component.scss'],
})
export class TooltipFieldWrapperComponent
  extends FieldWrapper
  implements OnInit
{
  private tooltipPlacementOptions: string[] = [
    'auto',
    'top',
    'top-start',
    'top-left',
    'top-end',
    'top-right',
    'bottom',
    'bottom-start',
    'bottom-left',
    'bottom-end',
    'bottom-right',
    'start',
    'left',
    'start-top',
    'left-top',
    'start-bottom',
    'left-bottom',
    'end',
    'right',
    'end-top',
    'right-top',
    'end-bottom',
    'right-bottom',
  ];

  tooltipPlacement = 'auto';
  constructor(private elRef: ElementRef, private renderer: Renderer2) {
    super();
  }

  ngOnInit() {
    if (this.field?.props?.['tooltip']) {
      this.setLabelClass();
    }
    if (
      this.field?.props?.['tooltipPlacement'] &&
      this.tooltipPlacementOptions.includes(
        this.field?.props?.['tooltipPlacement']
      )
    ) {
      this.tooltipPlacement = this.field?.props?.['tooltipPlacement'];
    }
  }

  setLabelClass(): void {
    const formLabelElement: ElementRef | undefined =
      this.elRef.nativeElement.closest('formly-wrapper-form-field');
    if (formLabelElement) {
      this.renderer.setAttribute(
        formLabelElement,
        'class',
        'form-label-with-hint'
      );
    }
  }
}
