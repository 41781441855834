import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usernameInitials',
})
export class UsernameInitialsPipe implements PipeTransform {
  transform(username?: string): string {
    if (!username || typeof username !== 'string' || !username.trim()) {
      return '';
    }

    const cleanUsername = username.replace(/\s{1,}/, ' ');
    if (!cleanUsername || !cleanUsername.trim()) {
      return '';
    }

    const usernameParts = username.split(' ');

    return `${usernameParts[0][0]}${
      usernameParts?.[1]?.[0] ? usernameParts?.[1]?.[0] : ''
    }`.toUpperCase();
  }
}
