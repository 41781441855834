import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { distinctUntilChanged, filter, Subscription } from 'rxjs';

@Component({
  selector: 'irembogov-custom-date-time-picker',
  templateUrl: './custom-date-time-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDateTimePickerComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy
{
  constructor(private readonly cd: ChangeDetectorRef) {
    super();
  }

  private readonly subscription: Subscription | undefined;

  ngOnInit(): void {
    this.field.options?.fieldChanges
      ?.pipe(
        filter(
          (change: FormlyValueChangeEvent) =>
            this.field.key === change.field.key
        ),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(() => {
        this.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
