import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { CustomDateAdapterService } from '../../services/custom-date-picker/custom-date-adapter.service';
import { CustomDateParserFormatterService } from '../../services/custom-date-picker/custom-date-parser-formatter.service';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbPeriod,
} from '@ng-bootstrap/ng-bootstrap';
import { TDateFormatTypes } from '../../models/date-picker-date-format.types';
import { AdminService } from '../../services/admin/admin.service';
import { ToastService } from '../../services/toast/toast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'irembogov-irembo-date-picker',
  templateUrl: './irembo-date-picker.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboDatePickerComponent),
      multi: true,
    },
    { provide: NgbDateAdapter, useClass: CustomDateAdapterService },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatterService,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IremboDatePickerComponent),
      multi: true,
    },
  ],
})
export class IremboDatePickerComponent
  implements
    ControlValueAccessor,
    OnInit,
    OnChanges,
    AfterViewInit,
    Validator,
    OnDestroy
{
  newDate = new Date();
  @Output() selectedDateEvent = new EventEmitter<string | null>();
  customformControl = new FormControl();
  @Input() disabled = false;
  @Input() startDate: NgbDateStruct = {
    year: this.newDate.getFullYear(),
    month: this.newDate.getMonth() + 1,
    day: this.newDate.getDay(),
  };
  @Input() minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };
  @Input() maxDate: NgbDateStruct = {
    year: this.newDate.getFullYear() + 10,
    month: 12,
    day: 31,
  };
  @Input() dateFormat: TDateFormatTypes = 'DD MM YYYY';
  @Input() delimeter? = '/';
  @Input() placeholder? = 'Select Date';
  @Input() addRemoveTimeMinDate? = '';
  @Input() addRemoveTimeMaxDate? = '';
  @Input() allowedDaysOfTheWeek?: number[] = undefined;
  @Input() eventCode? = '';
  @Input() eventDatesMaxSize? = '';
  availableDates: string[] = [];
  dayOfWeekIsNotAllowed = false;

  remainingSlots = 0;
  private subscriptions = new Subscription();

  constructor(
    private dateParser: NgbDateParserFormatter,
    private dateAdapter: NgbDateAdapter<string>,
    private calendar: NgbCalendar,
    private toastService: ToastService,
    private adminService: AdminService,
    private cdRef: ChangeDetectorRef
  ) {}

  initialDate: string | undefined;

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  public _onTouch = (value: unknown) => {};
  private _onValidationChange = () => {};
  /* eslint-enable */

  ngOnInit() {
    this.initialDate = undefined;
  }

  ngAfterViewInit(): void {
    if (this.addRemoveTimeMinDate) {
      this.updateMinDate(this.addRemoveTimeMinDate);
    }

    if (this.addRemoveTimeMaxDate) {
      this.updateMaxDate(this.addRemoveTimeMaxDate);
    }

    if (this.customformControl.value) {
      this.customformControl.updateValueAndValidity({ emitEvent: true });
      this._onValidationChange();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['delimeter']?.currentValue) {
      (<CustomDateAdapterService>this.dateAdapter).setDelimeter(this.delimeter);
      (<CustomDateParserFormatterService>this.dateParser).setDelimeter(
        this.delimeter
      );
    }
    if (changes?.['dateFormat']?.currentValue) {
      (<CustomDateAdapterService>this.dateAdapter).setDateFormatType(
        this.dateFormat
      );
      (<CustomDateParserFormatterService>this.dateParser).setDateFormatType(
        this.dateFormat
      );
    }
    if (!(this.placeholder && changes?.['placeholder']?.currentValue)) {
      this.placeholder = (this.dateFormat ?? 'DD MM YYYY')
        .split(' ')
        .join(this.delimeter ?? '/');
    }
    if (changes?.['eventCode']?.currentValue) {
      this.getEventFutureDates();
    }

    if (changes?.['addRemoveTimeMinDate']?.currentValue) {
      this.updateMinDate(changes?.['addRemoveTimeMinDate']?.currentValue);
    }

    if (changes?.['addRemoveTimeMaxDate']?.currentValue) {
      this.updateMaxDate(changes?.['addRemoveTimeMaxDate']?.currentValue);
    }
  }

  updateMinDate(addRemoveTimeMinDate: string) {
    if (addRemoveTimeMinDate) {
      const config = addRemoveTimeMinDate.split(':');
      const duration = Number(config[0]);
      const period = config[1] as NgbPeriod;
      if (!isNaN(duration)) {
        this.minDate =
          duration < 0
            ? this.subtractTime(period, Math.abs(duration))
            : this.addTime(period, Math.abs(duration));
      }
      this._onValidationChange();
    }
  }

  updateMaxDate(addRemoveTimeMaxDate: string) {
    if (addRemoveTimeMaxDate) {
      const config = addRemoveTimeMaxDate.split(':');
      const duration = Number(config[0]);
      const period = config[1] as NgbPeriod;
      if (!isNaN(duration)) {
        this.maxDate =
          duration < 0
            ? this.subtractTime(period, Math.abs(duration))
            : this.addTime(period, Math.abs(duration));
      }
      this._onValidationChange();
    }
  }

  writeValue(dateStr: string): void {
    this.customformControl.setValue(dateStr);
    if (!this.initialDate) {
      this.initialDate = dateStr;
      this._onValidationChange();
    }
  }
  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  dateSelect(date: NgbDate) {
    if (this.eventCode) {
      this.checkEventDateSlots(date);
      return;
    }
    this.setValueOnDateSelect(date);
  }

  setValueOnDateSelect(date: NgbDate) {
    const formattedData = this.dateAdapter.toModel(date);
    this.selectedDateEvent.emit(formattedData);
    this._onChange(formattedData);
    this._onTouch(formattedData);
  }

  change(event: unknown) {
    console.log(event, 'change date picker');
    if (event instanceof Event) {
      const date = (event.target as HTMLInputElement).value;
      const dateData: NgbDateStruct | null = this.dateAdapter.fromModel(date);
      if (dateData) {
        this.checkIfDayOfTheWeekIsAllowed(
          new NgbDate(dateData.year, dateData.month, dateData.day)
        );
      } else {
        this.dayOfWeekIsNotAllowed = false;
      }
      this._onChange(date);
      this._onTouch(date);
      this._onValidationChange();
    }
  }

  cancelDate() {
    if (this.initialDate) {
      this.customformControl.setValue(this.initialDate);
      this._onChange(this.initialDate);
      this._onTouch(this.initialDate);
    }
  }

  addTime(period: NgbPeriod, duration: number): NgbDate {
    const today = this.calendar.getToday();
    if (duration === 0) {
      return today;
    }
    const newDate = this.calendar.getNext(today, period, duration);
    return newDate;
  }

  subtractTime(period: NgbPeriod, duration: number): NgbDate {
    const today = this.calendar.getToday();
    if (duration === 0) {
      return today;
    }
    const newDate = this.calendar.getPrev(today, period, duration);
    return newDate;
  }

  validate(): ValidationErrors | null {
    const validationErrors: ValidationErrors = {};
    this.cdRef.detectChanges();

    if (this.customformControl.errors?.['ngbDate']) {
      const errorName = Object.keys(
        this.customformControl.errors['ngbDate']
      )[0];
      validationErrors[errorName] = true;
    }
    if (this.customformControl.errors?.['unavailableSlots']) {
      validationErrors['unavailableSlots'] = true;
    }

    if (this.dayOfWeekIsNotAllowed) {
      validationErrors['dayOfWeekIsNotAllowed'] = true;
    }

    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  ngbDateToString(date: NgbDate): string {
    const day = date.day < 10 ? `0${date.day}` : `${date.day}`;
    const month = date.month < 10 ? `0${date.month}` : `${date.month}`;
    const year = `${date.year}`;
    return `${year}-${month}-${day}`;
  }

  getEventFutureDates() {
    const today = this.calendar.getToday();
    const startDate = this.ngbDateToString(today);
    const maxDataSetSize = this.eventDatesMaxSize ?? '31';
    this.subscriptions.add(
      this.adminService
        .getEventFutureDates(this.eventCode!, startDate, maxDataSetSize)
        .subscribe({
          next: (res: Record<string, unknown>) => {
            const data = res?.['data'] as Record<string, unknown>;
            this.availableDates = data?.['dates'] as string[];
          },
          error: () => {
            this.toastService.show({
              body: `Failed to fetch dates for event ${this.eventCode}`,
              type: 'error',
            });
          },
        })
    );
  }

  checkEventDateSlots(date: NgbDate) {
    this.subscriptions.add(
      this.adminService
        .getBookingsSummary(this.eventCode!, this.ngbDateToString(date))
        .subscribe({
          next: (res: Record<string, unknown>) => {
            const data = res?.['data'] as Record<string, unknown>;
            const totalSlots = data?.['totalSlots'] as number;
            const usedSlots = data?.['usedSlots'] as number;
            this.remainingSlots = totalSlots - usedSlots;
            if (this.remainingSlots === 0) {
              this.customformControl.setErrors({
                ...this.customformControl.errors,
                unavailableSlots: true,
              });
            } else {
              delete this.customformControl.errors?.['unavailableSlots'];
            }
            this.cdRef.detectChanges();
            this.setValueOnDateSelect(date);
          },
          error: () => {
            this.toastService.show({
              body: `Failed to validate availability of slots for selected date`,
              type: 'error',
            });
            delete this.customformControl.errors?.['unavailableSlots'];
            this.remainingSlots = 0;
          },
        })
    );
  }

  markDisabled = (date: NgbDate, current?: { year: number; month: number }) => {
    let isUnavailableSlots = false;
    let isDayOfTheWeekNotAllowed = false;

    if (this.eventCode) {
      isUnavailableSlots = !this.checkIfDateIsAvailable(date, current);
    }

    if (this.allowedDaysOfTheWeek && Array.isArray(this.allowedDaysOfTheWeek)) {
      isDayOfTheWeekNotAllowed = !this.checkIfDayOfTheWeekIsAllowed(date);
    }

    return isUnavailableSlots || isDayOfTheWeekNotAllowed;
  };

  checkIfDateIsAvailable(
    date: NgbDate,
    _current?: { year: number; month: number }
  ): boolean {
    return this.availableDates.includes(this.ngbDateToString(date));
  }

  checkIfDayOfTheWeekIsAllowed(date: NgbDate): boolean {
    let isAllowed = true;

    if (Array.isArray(this.allowedDaysOfTheWeek)) {
      const dayIndex = this.calendar.getWeekday(date);
      isAllowed = this.allowedDaysOfTheWeek?.includes?.(dayIndex) ?? false;
    }

    this.dayOfWeekIsNotAllowed = !isAllowed;
    return isAllowed;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
