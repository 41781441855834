import { IAttachment } from './../../models/attachments-list.model';
import { IService } from './../../models/services-list.model';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { PublicServicesService } from '../../services/public-services.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../../src/environments/environment';

@Component({
  selector: 'irembogov-service-search-result',
  templateUrl: './service-search-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./service-search-result.component.scss'],
})
export class ServiceSearchResultComponent implements OnDestroy {
  keyword = '';
  searchby = '';

  showMore = false;
  showMoreText = 'More Details';
  modalID!: ElementRef;
  @Input() service: IService | undefined;
  @Output() applyClicked = new EventEmitter<string>();

  requiredAttachments: IAttachment[];
  conditionalAttachments: IAttachment[] | undefined;
  optionalAttachments: IAttachment[] | undefined;
  knowledgeBaseUrl = environment.knowledgeBaseUrl;

  private subscriptions = new Subscription();

  constructor(
    private publicServicesService: PublicServicesService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.requiredAttachments = [];
  }

  openDetails(serviceId: string | undefined) {
    this.showMore = !this.showMore;
    if (serviceId && this.showMore) {
      this.showMoreText = 'Less Details';
    } else {
      this.showMoreText = 'More Details';
    }
  }

  closeDetails() {
    this.showMore = false;
  }

  handleApply() {
    this.applyClicked.emit('Apply for a service');
  }

  navigateToApply(keyword: string, id: string) {
    this.router.navigate(['service/details'], { queryParams: { id, keyword } });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
