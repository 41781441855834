import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Business } from '../../../../models/business/business.model';
import { UserBusinessService } from '../../../../../app/services/user-business.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'irembogov-business-list',
  templateUrl: './business-list.component.html',
  styleUrls: ['./business-list.component.scss'],
})
export class BusinessListComponent {
  businesses?: Business[] = [];
  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private userBusinessService: UserBusinessService
  ) {
    this.subscriptions.add(
      this.userBusinessService.userBusinessesSubjectObservable.subscribe(
        (userBusinesses: Business[] | undefined) => {
          this.businesses = userBusinesses;
        }
      )
    );
  }

  viewApplications(businessId: string) {
    this.router.navigate(['profile', 'applications', 'business', businessId]);
  }

  viewCertificates(businessId: string) {
    this.router.navigate(['profile', 'certificates', 'business', businessId]);
  }

  goToManageTeams(businessId: string) {
    this.router.navigate(['profile', 'business-teams', businessId]);
  }
}
