import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  forwardRef,
  TemplateRef,
  Input,
  Inject,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { IIremboFileUpload } from '../../models/irembo-file-upload-response.model';
import { FileUploadSource } from '../../models/file-upload-source.enum';
import { Subject } from 'rxjs';
import { IPreviousAttachmentEvent } from '../../models/previous-attachment-event';
import { DOCUMENT } from '@angular/common';
import { ICertificateAttachmentEvent } from '../../models/certificate-attachment-event';
import errorMessages from '../../models/photo-validation-error-messages';

@Component({
  selector: 'irembogov-file-upload',
  templateUrl: './irembo-file-upload.component.html',
  styleUrls: ['./irembo-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboFileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IremboFileUploadComponent,
      multi: true,
    },
  ],
})
export class IremboFileUploadComponent
  implements ControlValueAccessor, Validator, OnChanges
{
  @Input() allowedFormats: string[] = ['png', 'jpg', 'pdf'];
  @Input() maximumUploadSize = 1024;
  @Input() minimumUploadSize = 100;
  @Input() id: unknown;
  @Input() previousAttachments: IPreviousAttachmentEvent[] = [];
  @Input() certificateAttachments: ICertificateAttachmentEvent[] = [];
  @Input() placeholder = 'Select file to upload';
  @Input() filePath: string | undefined;
  @Output() fileUploadEvent = new EventEmitter<IIremboFileUpload>();
  @Output() previewFileEvent = new EventEmitter<unknown>();
  @Output() selectFileEvent = new EventEmitter<unknown>();
  @Output() selectPreviousAttachmentEvent =
    new EventEmitter<IPreviousAttachmentEvent>();
  @Output() selectCertificateAttachmentEvent =
    new EventEmitter<ICertificateAttachmentEvent>();
  @Output() previousAttachmentEventScroll = new EventEmitter<
    Record<string, number>
  >();
  @Output() certificateAttachmentEventScroll = new EventEmitter<
    Record<string, number>
  >();
  @Output() handleValidationErrors = new EventEmitter<ValidationErrors>();
  @Input() progress: Subject<number> = new Subject<number>();
  @Input() loadingFile = false;
  @Input() totalPreviousAttachments = 0;
  @Input() totalCertificateAttachments = 0;

  @Input() photoValidationResponse: Record<string, unknown> | undefined;
  @Input() validatePhoto = false;
  @Input() validatingPhoto = false;
  @Input() attachmentCode: string | undefined;

  loadingFileName = '';

  disabled = false;
  file: IIremboFileUpload | null | undefined;
  hasErrors = false;
  addDragClass = false;
  base64Data: string | undefined;
  failedChecks: string[] = [];
  invalidPhoto = false;
  photoValiationErrorMessages: Record<string, string> = errorMessages;

  constructor(
    public modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  private _onTouch = (value: unknown) => {};
  private _onValidationChange = () => {};
  /* eslint-enable */

  writeValue(obj: IIremboFileUpload): void {
    this.file = obj;
    if (obj) {
      const fileSizeInKB = this.file.fileSize / 1024;
      this._onValidationChange();
      this.validateUpload(fileSizeInKB);
      this.base64Data = obj.content as string;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['validatingPhoto']) {
      this._onValidationChange();
    }

    if (this.validatePhoto) {
      if (
        changes?.['photoValidationResponse'] &&
        changes['photoValidationResponse'].currentValue
      ) {
        this.failedChecks = [];
        const isImage = this.file?.fileType?.includes('image');
        if (isImage) {
          const checks = changes['photoValidationResponse'].currentValue;
          this.failedChecks = this.checkPhotoResponse(checks);
          this.hasErrors = this.failedChecks.length > 0;
          this.invalidPhoto = this.hasErrors;
        }
      }
      this._onValidationChange();
    }
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  removeFile() {
    this.file = null;
    this.loadingFileName = '';
    this.addDragClass = false;
    this._onChange(null);
    this._onTouch(null);
    this.failedChecks = [];
    this.invalidPhoto = false;
    this.hasErrors = false;
    this._onValidationChange();
  }

  onClickFileUpload(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  onPreviousAttachmentScroll() {
    console.log('Triggered previous attachment scroll');

    const elements = this._document.getElementsByClassName(
      'previousAttachmentsContainer'
    );
    if (elements.length > 0) {
      const scrollPosition = Math.round(elements[0].scrollTop);
      const scrollHeight = elements[0].scrollHeight;
      const clientHeight = elements[0].clientHeight;
      this.previousAttachmentEventScroll.emit({
        scrollPosition,
        scrollHeight,
        clientHeight,
      });
    }
  }

  onCertificateAttachmentScroll() {
    const elements = this._document.getElementsByClassName(
      'certificateAttachmentsContainer'
    );
    if (elements.length > 0) {
      const scrollPosition = Math.round(elements[0].scrollTop);
      const scrollHeight = elements[0].scrollHeight;
      const clientHeight = elements[0].clientHeight;
      this.certificateAttachmentEventScroll.emit({
        scrollPosition,
        scrollHeight,
        clientHeight,
      });
    }
  }

  change(event: unknown) {
    this.hasErrors = false;
    this.failedChecks = [];
    this.invalidPhoto = false;
    if (event instanceof Event) {
      const fileList = this.getFileListFromEvent(event);
      if (fileList) {
        this.processFile(fileList[0]);
      }
    }
    this._onValidationChange();
  }

  private getFileListFromEvent(event: Event): FileList | null {
    return (event.target as HTMLInputElement).files;
  }

  private processFile(file: File) {
    this.file = {
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
      content: null,
      fileDownloadName: '',
      source: FileUploadSource.DEVICE,
      attachmentCode: this.attachmentCode,
    };

    const fileSizeInKB = file.size / 1024;
    this.validateUpload(fileSizeInKB);

    if (!this.hasErrors) {
      this.loadFile(file);
    }
  }

  private loadFile(file: File) {
    const reader = new FileReader();
    reader.onprogress = (event: ProgressEvent<FileReader>) => {
      this.handleFileProgress(event, file);
    };

    reader.onload = () => {
      this.handleFileLoaded(reader.result as string);
    };

    reader.readAsDataURL(file);
  }

  private handleFileProgress(event: ProgressEvent<FileReader>, file: File) {
    this.loadingFile = true;
    this.loadingFileName = file.name;
    if (event.lengthComputable) {
      const progressPercentage = Math.round((event.loaded / event.total) * 100);
      this.progress.next(progressPercentage);
    }
  }

  private handleFileLoaded(result: string) {
    this.loadingFile = false;
    this.filePath = this.sanitizer.bypassSecurityTrustResourceUrl(
      result
    ) as string;
    this.base64Data = result;
    if (this.file) {
      this.file.content = result;
      this._onChange(this.file);
      this._onTouch(this.file);
      this.fileUploadEvent.emit(this.file);
      this.modalService.dismissAll();
    }
  }

  private validateUpload(fileSizeInKB: number) {
    const ext = this.file?.fileName.split('.').pop();
    if (
      fileSizeInKB > this.maximumUploadSize ||
      fileSizeInKB < this.minimumUploadSize ||
      (this.allowedFormats?.length > 1 &&
        ext &&
        !this.checkIfExtensionIsAllowed(ext))
    ) {
      this.hasErrors = true;
      this._onChange(this.file);
      this._onTouch(this.file);
      if (this.file) {
        this.fileUploadEvent.emit(this.file);
      }
      this.modalService.dismissAll();
      this.cd.detectChanges();
    }
  }

  getPhotoValidationErrorMessage(key: string) {
    return this.photoValiationErrorMessages[key] as string;
  }

  private checkIfExtensionIsAllowed(ext: string) {
    return (
      this.allowedFormats &&
      this.allowedFormats
        .map((format: string) => format.toLowerCase())
        .indexOf(ext.toLowerCase()) > -1
    );
  }

  preview(content: TemplateRef<unknown>) {
    this.modalService.open(content, {
      ariaLabelledBy: 'preview-document',
      size: 'xl',
    });
    this.previewFileEvent.emit(this.file);
  }

  selectFile(content: TemplateRef<unknown>) {
    this.selectFileEvent.emit('select-file');
    this.modalService.open(content, {
      ariaLabelledBy: 'preview-document',
      size: 'xl',
    });
  }

  get canPreview() {
    let isImageOrPdf = false;
    const fileType = this.file?.fileType;
    if (fileType) {
      isImageOrPdf = fileType.includes('image') || fileType.includes('pdf');
    }
    return isImageOrPdf;
  }

  acceptList() {
    return this.allowedFormats
      ? this.allowedFormats.map(ele => `.${ele.toLowerCase()}`).toString()
      : '';
  }

  validate(): ValidationErrors | null {
    if (!this.file) return null;
    const validationErrors: ValidationErrors = {};
    const ext = this.file?.fileName.split('.').pop();

    if (!ext) {
      validationErrors['invalidfileformat'] = true;
    }

    if (
      this.allowedFormats?.length > 1 &&
      ext &&
      !this.checkIfExtensionIsAllowed(ext)
    ) {
      validationErrors['invalidfileformat'] = true;
    }

    const fileSizeInKB = this.file.fileSize / 1024;
    if (fileSizeInKB < this.minimumUploadSize) {
      validationErrors['minimumUploadSize'] = true;
    }

    if (fileSizeInKB > this.maximumUploadSize) {
      validationErrors['maximumUploadSize'] = true;
    }

    if (this.invalidPhoto && this.validatePhoto) {
      validationErrors['invalidPhoto'] = true;
    }

    if (this.validatingPhoto) {
      validationErrors['validatingPhoto'] = true;
    }

    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  getIcon(fileName: unknown): string {
    const ext = (fileName as string).split('.').pop();
    return ext === 'pdf' ? 'fa-regular fa-file-pdf' : 'fa-regular fa-image';
  }

  handleSelectPreviousAttachment(item: IPreviousAttachmentEvent) {
    this.loadingFileName = item.fileName;
    this.selectPreviousAttachmentEvent.emit(item);
  }

  handleSelectCertificateAttachment(item: ICertificateAttachmentEvent) {
    this.loadingFileName = item.certificateName;
    this.selectCertificateAttachmentEvent.emit(item);
  }

  castToString(value: unknown) {
    return value as string;
  }

  toogledragClass(event: Event, status: boolean) {
    this.addDragClass = status;
  }

  checkPhotoResponse(response: Record<string, unknown>) {
    const failedChecks: string[] = [];
    if (response) {
      for (const prop in response) {
        if (
          Object.hasOwnProperty.call(response, prop) &&
          response[prop] === false
        ) {
          failedChecks.push(prop);
        }
      }
    }
    return failedChecks;
  }
}
