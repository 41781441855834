<div
  class="activation-reset container d-flex flex-column justify-content-start min-vh-100">
  <div class="text-center px-5 logo-container auth-components-margin-top">
    <img class="img logo" src="assets/images/logo.png" alt="irembo logo" />
  </div>
  <div class="text-center image-contianer" *ngIf="showLogo">
    <img class="icon-img" src="assets/images/irembo_img-key.svg" alt="" />
  </div>
  <div class="align-self-center heading-content">
    <h2 class="text-center">{{ title }}</h2>
    <p class="text-center mt-2 desc">
      {{ description }}
    </p>
  </div>
  <div
    class="align-self-center bg-body rounded text activation-reset-form-container px-3">
    <div class="mt-2 activation-reset-form">
      <small
        class="d-block text-center text-danger"
        *ngIf="responseErrorMessage"
        >{{ responseErrorMessage }}</small
      >
      <form [formGroup]="passwordForm" (submit)="onFormSubmit()">
        <div class="form-group mb-3">
          <label for="login-pass" class="form-label">{{
            'New Password' | translate
          }}</label>
          <div class="input-group">
            <input
              type="{{ showPassword ? 'text' : 'password' }}"
              id="password"
              #password
              class="form-control"
              placeholder="Enter password"
              formControlName="password"
              aria-describedby="password-icon"
              autocomplete />
            <span
              class="input-group-text bg-body border-start-0"
              id="password-icon">
              <i
                class="fa-regular"
                [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                (click)="togglePassword()"></i>
            </span>
          </div>
          <div
            *ngIf="
              this.passwordForm.controls['password'].dirty &&
              this.passwordForm.controls['password'].invalid
            "
            class="mt-4 alert alert-danger">
            {{ regExpErrorMsg }}
          </div>
        </div>
        <irembogov-password-strength-meter
          *ngIf="showPasswordStrengthMeter"
          [password]="
            passwordForm.get('password')?.value
          "></irembogov-password-strength-meter>

        <div class="form-group w-100">
          <label for="login-pass" class="form-label">{{
            'Confirm New Password' | translate
          }}</label>
          <div class="input-group mb-3">
            <input
              formControlName="re_password"
              type="{{ showConfirmPassword ? 'text' : 'password' }}"
              class="form-control bg-body"
              id="confirmPassword"
              placeholder="Re-enter password"
              #confirmPassword
              autocomplete />
            <span class="input-group-text bg-body border-start-0">
              <i
                class="fa-regular"
                [ngClass]="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"
                (click)="toggleConfirmPassword()"></i>
            </span>
          </div>
        </div>
        <div class="form-check form-check-inline" *ngIf="showTermsCheckbox">
          <input
            #agreeToTermsCheckbox
            type="checkbox"
            [checked]="agreeToTerms"
            (change)="onSaveAgreeTermsChanged(agreeToTermsCheckbox.checked)"
            id="terms"
            name="terms"
            value=""
            class="form-check-input" />
          <label class="form-check-label" for="terms">
            {{ 'I agree to the IremboGov' | translate }}
            <a href="https://irembo.gov.rw/support/legal-information">{{
              'Terms and Conditions' | translate
            }}</a>
            {{ 'and' | translate }}
            <a href="https://irembo.gov.rw/support/legal-information">{{
              'Privacy Policy' | translate
            }}</a></label
          >
        </div>
        <div *ngIf="errorMessage.length > 0" class="mt-4 alert alert-danger">
          {{ errorMessage }}
        </div>
        <button
          class="btn btn-primary mt-2 w-100"
          style="font-weight: 600"
          type="submit"
          [disabled]="
            isLoading ||
            passwordForm.invalid ||
            (showTermsCheckbox && !agreeToTerms)
          ">
          <div
            *ngIf="isLoading"
            class="spinner-border spinner-border-sm mr-2"
            role="status"></div>
          {{ buttonActionLabel }}
        </button>
      </form>
      <div *ngIf="showBackToLogin" class="login-button text-center my-3">
        <button class="back-btn btn btn-default w-auto" (click)="goBack.emit()">
          <i aria-hidden="true" class="fa fa-arrow-left pe-2"></i>
          {{ 'Back to log in' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
