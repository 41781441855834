<div class="irembogov-list-business-user-requests">
  <ng-container *ngIf="!isLoadingUserRequests; else listLoading">
    <div class="mobile-content-list d-block d-md-none">
      <div
        class="custom-gap d-flex flex-column"
        *ngIf="userRequestLists.length > 0; else listEmpty">
        <div
          class="list-item"
          *ngFor="let request of userRequestLists; index as i">
          <div class="requestor-details">
            <div
              *ngIf="request.firstName && request.lastName; else unverifiedUser"
              class="user-details d-flex flex-row justify-content-start align-items-center gap-3">
              <div class="initials">
                <span class="user-initials">{{
                  generateInitials([request.firstName, request.lastName])
                }}</span>
              </div>
              <div
                class="d-flex flex-column justify-content-between gap-2 user-details-text">
                <span class="usernames">
                  {{ request.firstName }} {{ request.lastName }}
                </span>
                <span class="user-nid">
                  {{ request.username }}
                </span>
              </div>
            </div>
            <ng-template #unverifiedUser>
              <div
                class="user-details d-flex flex-row justify-content-start align-items-center gap-3">
                <div class="initials">
                  <span class="user-initials">{{
                    generateInitials(request.username)
                  }}</span>
                </div>
                <div
                  class="d-flex flex-column justify-content-between gap-2 user-details-text">
                  <span class="usernames">
                    {{ request.username }}
                  </span>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="document-expiry">
            <div
              class="font-sm d-flex align-items-center justify-content-start">
              {{ request.dateCreated | date : 'MMM d, yyyy' }}
            </div>
          </div>
          <div class="divider"></div>
          <div class="request-actions d-flex flex-row fw-semibold">
            <a
              type="button"
              (click)="approveRequest(request)"
              class="approve-action text-decoration-none">
              {{ 'Approve' | translate }}
            </a>
            <a
              type="button"
              (click)="rejectRequest(request)"
              class="reject-action text-decoration-none">
              {{ 'Reject' | translate }}
            </a>
          </div>
        </div>

        <div
          class="table-pagination mt-3"
          *ngIf="!isLoadingUserRequests && userRequestLists.length > 0">
          <irembogov-citizen-pagination
            [minimalPagination]="true"
            [showDirectionText]="false"
            [collectionSize]="totalUserRequests"
            (paginatedValues)="handlePaginate($event)">
          </irembogov-citizen-pagination>
        </div>
      </div>
    </div>
    <div class="table-responsive d-none d-md-block">
      <div class="table-border">
        <table
          class="table table-sm"
          *ngIf="userRequestLists.length > 0; else listEmpty">
          <thead>
            <tr>
              <th>{{ 'Requestor' | translate }}</th>
              <th>{{ 'Request Date' | translate }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let request of userRequestLists">
              <td>
                <div
                  *ngIf="
                    request.firstName && request.lastName;
                    else unverifiedUserView
                  "
                  class="user-details d-flex flex-row justify-content-start align-items-center gap-3">
                  <div class="initials">
                    <span class="user-initials">{{
                      generateInitials([request.firstName, request.lastName])
                    }}</span>
                  </div>
                  <div
                    class="d-flex flex-column justify-content-between gap-2 user-details-text">
                    <span class="usernames"
                      >{{ request.firstName }} {{ request.lastName }}</span
                    >
                    <span class="user-nid">
                      {{ request.username }}
                    </span>
                  </div>
                </div>
                <ng-template #unverifiedUserView>
                  <div
                    class="user-details d-flex flex-row justify-content-start align-items-center gap-3">
                    <div class="initials">
                      <span class="user-initials">{{
                        generateInitials(request.username)
                      }}</span>
                    </div>
                    <div
                      class="d-flex flex-column justify-content-between align-items-center p-2 user-details-text">
                      <span class="usernames">
                        {{ request.username }}
                      </span>
                    </div>
                  </div>
                </ng-template>
              </td>
              <td>
                {{ request.dateCreated | date : 'MMM d, yyyy' }}
              </td>
              <td>
                <div class="request-actions fw-semibold">
                  <a
                    type="button"
                    (click)="approveRequest(request)"
                    class="approve-action text-decoration-none">
                    {{ 'Approve' | translate }}
                  </a>
                  <a
                    type="button"
                    (click)="rejectRequest(request)"
                    class="reject-action text-decoration-none">
                    {{ 'Reject' | translate }}
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="w-100 mt-3 px-4"
          *ngIf="!isLoadingUserRequests && userRequestLists.length > 0">
          <irembogov-citizen-pagination
            [minimalPagination]="false"
            [showDirectionText]="true"
            [collectionSize]="totalUserRequests"
            (paginatedValues)="handlePaginate($event)">
          </irembogov-citizen-pagination>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- No Documents Template -->
<ng-template #listLoading>
  <div
    class="d-flex align-items-center justify-content-center text-center py-5">
    <div
      class="spinner-grow text-primary"
      style="width: 3rem; height: 3rem"
      role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>

<!-- No Documents Template -->
<ng-template #listEmpty>
  <div
    class="list-empty d-flex flex-column py-4 px-3 justify-content-center align-items-center w-100 h-100">
    <div class="icons-container">
      <i class="icon-layers-three-01"></i>
    </div>
    <div class="title">
      {{ 'No user requests!' | translate }}
    </div>
    <div class="description col-12 w-40">
      {{
        'Users requesting to join your business will be displayed here.'
          | translate
      }}
    </div>
  </div>
</ng-template>
