import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subscription, distinctUntilChanged, debounceTime } from 'rxjs';
import { getValidDataFetchInputValue } from '../../../utils/utils/data-fetch-widget-utils';
import { EGenericInputTriggerType } from '../../models/irembo-generic-input-status.enum';

@Component({
  selector: 'irembogov-generic-data-fetch',
  templateUrl: './irembogov-generic-data-fetch.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IrembogovGenericDataFetchComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IrembogovGenericDataFetchComponent,
      multi: true,
    },
  ],
})
export class IrembogovGenericDataFetchComponent
  implements
    ControlValueAccessor,
    Validator,
    OnChanges,
    AfterViewInit,
    OnDestroy
{
  @Input() placeholder: unknown;
  @Input() id: unknown;
  @Input() genericInputLength = 0;
  @Output() dataFetch = new EventEmitter<string>();
  @Input() statusClass: string | undefined;
  @Input() inputLenghtAsMinLength = false;
  @Input() disableInternalFormControl = false;
  @Input() triggerType: EGenericInputTriggerType =
    EGenericInputTriggerType.INPUT_LENGTH;
  @Input() successMsg: string | undefined;

  isInvalidGenericInput = false;
  genericInputSub: Subscription | undefined;
  customFormControl: FormControl = new FormControl('');

  constructor(private cd: ChangeDetectorRef) {}

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onValidationChange = () => {};
  private _onTouch = (value: unknown) => {};
  private _onChange = (value: unknown) => {};
  /* eslint-enable */

  ngAfterViewInit(): void {
    this.genericInputSub = this.customFormControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe({
        next: (value: string) => {
          this.statusClass = '';
          this._onTouch(value);
          this._onChange(value);

          let validValue: string | null = null;
          if (this.triggerType === EGenericInputTriggerType.TRIGGER_BUTTON) {
            return;
          } else {
            validValue = getValidDataFetchInputValue(
              value,
              this.genericInputLength,
              this.inputLenghtAsMinLength
            );
          }
          if (validValue) {
            this.dataFetch.emit(validValue);
          }
          this.cd.detectChanges();
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isInvalidGenericInput =
      changes['statusClass'].currentValue === 'danger';
    if (changes?.['statusClass']) {
      const validControlValue = getValidDataFetchInputValue(
        this.customFormControl.value,
        this.genericInputLength,
        this.inputLenghtAsMinLength,
        this.triggerType
      );
      this._onChange(validControlValue);
      this._onTouch(validControlValue);
      this.cd.detectChanges();
    }

    if (changes?.['disableInternalFormControl']?.currentValue) {
      this.customFormControl.disable();
    }
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  writeValue(obj: unknown): void {
    this.customFormControl.setValue(obj);
  }

  setDisabledState?(disable: boolean): void {
    if (disable) {
      this.customFormControl.disable();
    } else {
      this.customFormControl.enable();
    }
  }

  validate(formControl: FormControl): ValidationErrors | null {
    const value = formControl.value;
    const validationErrors: ValidationErrors = {};
    if (!value) {
      return null;
    }
    const validInput = getValidDataFetchInputValue(
      value,
      this.genericInputLength,
      this.inputLenghtAsMinLength,
      this.triggerType
    );
    if (!validInput) {
      validationErrors['invalidInput'] = true;
    }
    if (this.isInvalidGenericInput) {
      validationErrors['invalidGenericInput'] = true;
    }

    if (formControl.errors?.['notVerifiedByTriggerAction']) {
      validationErrors['notVerifiedByTriggerAction'] = true;
    }

    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  ngOnDestroy(): void {
    this.genericInputSub?.unsubscribe();
  }
}
