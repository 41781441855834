import { SearchByTypeComponent } from './component/search-by-type/search-by-type.component';
import { ServiceSearchPageComponent } from './component/service-search-page/service-search-page.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@irembo-andela/irembogov3-common';
import { NgModule } from '@angular/core';
import { FindComponent } from './component/find/find.component';
import { NotfoundComponent } from './component/notfound/notfound.component';
import { ServiceDetailsComponent } from './component/service-details/service-details.component';
import { AdvanceSearchComponent } from './component/advance-search/advance-search.component';
import { ServiceNotFoundComponent } from './component/service-not-found/service-not-found.component';
import { GenericContainerComponent } from './component/generic-container/generic-container.component';
import { TrackApplicationComponent } from './component/track-application/track-application.component';
import { VerifyIdentityComponent } from './component/verify-identity/verify-identity.component';
import { IrembogovLegalPagesComponent } from '@irembo-andela/irembogov3-common';
import { VerifyCertificateComponent } from './component/verify-certificate/verify-certificate.component';
import { VerifyCertificateQrScanComponent } from './component/verify-certificate-qr-scan/verify-certificate-qr-scan.component';
import { ProfileComponent } from './component/profile/profile.component';
import { ProfileSettingsComponent } from './component/profile/components/profile-settings/profile-settings.component';
import { PersonalApplicationsComponent } from './component/profile/components/personal-applications/personal-applications.component';
import { AddBusinessSuccessComponent } from './component/add-business-success/add-business-success.component';
import { PersonalDocumentsComponent } from './component/profile/components/personal-documents/personal-documents.component';
import { BusinessApplicationsComponent } from './component/profile/components/business-applications/business-applications.component';
import { BusinessCertificatesComponent } from './component/profile/components/business-certificates/business-certificates.component';
import { ManageBusinessTeamsComponent } from './component/profile/components/business-teams/manage-business-teams.component';

const routes: Routes = [
  {
    path: '',
    component: GenericContainerComponent,
    children: [
      { path: '', component: FindComponent },
      { path: 'track-application', component: TrackApplicationComponent },
      {
        path: 'service/details',
        component: ServiceDetailsComponent,
      },
      { path: 'services', component: ServiceSearchPageComponent },
      { path: 'advance-search', component: AdvanceSearchComponent },
      { path: 'search-not-found', component: ServiceNotFoundComponent },
      { path: 'services/browse-by', component: SearchByTypeComponent },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'profile/settings',
        component: ProfileSettingsComponent,
        canActivate: [AuthGuard],
      },
      // List of citizen applications
      {
        path: 'profile/applications/personal',
        component: PersonalApplicationsComponent,
        canActivate: [AuthGuard],
      },
      //List of citizen business applications
      {
        path: 'profile/applications/business/:businessId',
        component: BusinessApplicationsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'profile/certificates/business/:businessId',
        component: BusinessCertificatesComponent,
        canActivate: [AuthGuard],
      },
      // List of citizen documents
      {
        path: 'profile/documents/personal',
        component: PersonalDocumentsComponent,
        canActivate: [AuthGuard],
      },
      // Manage citizen business teams
      {
        path: 'profile/business-teams/:businessId',
        component: ManageBusinessTeamsComponent,
        canActivate: [AuthGuard],
      },
      { path: 'notfound', component: NotfoundComponent },
      {
        path: 'legal-information',
        component: IrembogovLegalPagesComponent,
      },
      { path: 'verify-certificate', component: VerifyCertificateComponent },
      {
        path: 'verify-certificate-scan/:applicationNumber',
        component: VerifyCertificateQrScanComponent,
      },
      {
        path: 'business/success',
        component: AddBusinessSuccessComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'application',
    loadChildren: () =>
      import('./modules/application/application.module').then(
        m => m.ApplicationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'verify',
    component: VerifyIdentityComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
