export enum GenericInputStatusEnum {
  FETCHING = 'fetching',
  SUCCESS = 'success',
  DANGER = 'danger',
}

export enum EGenericInputTriggerType {
  INPUT_LENGTH = 'INPUT_LENGTH',
  TRIGGER_BUTTON = 'TRIGGER_BUTTON',
}
