import { Component, Input } from '@angular/core';

@Component({
  selector: 'irembogov-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
})
export class InfoBannerComponent {
  @Input() bannerTitle = '';
  @Input() bannerText = '';
  @Input() linkText = '';
  @Input() linkUrl = '';
  @Input() backgroundColor = '#f0f0f0';
}
