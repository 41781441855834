<div class="d-flex gap-2 align-items-start" #dateTimePickerFormControlEl>
  <irembogov-irembo-date-picker
    class="w-50"
    [formControl]="datePickerFormControl"
    ngDefaultControl
    [maxDate]="maxDate"
    [addRemoveTimeMinDate]="addRemoveTimeMinDate"
    [addRemoveTimeMaxDate]="addRemoveTimeMaxDate"
    [allowedDaysOfTheWeek]="allowedDaysOfTheWeek"
    [minDate]="minDate"
    [required]="requiredField"
    [placeholder]="placeholder"
    [dateFormat]="dateFormat"
    [eventCode]="eventCode"
    [eventDatesMaxSize]="eventDatesMaxSize"
    [delimeter]="delimeter ?? '-'"></irembogov-irembo-date-picker>

  <irembogov-time-picker
    [labelForId]="labelForId"
    [required]="requiredField"
    [formControl]="timePickerFormControl"
    [endHour]="endHour"
    [startHour]="startHour"
    [interval]="interval"
    [placeholder]="
      (placeholder ? placeholder + ' ' : '') + 'Time'
    "></irembogov-time-picker>
</div>
