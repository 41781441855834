<ng-container class="initiate-add-business-container">
  <button
    *ngIf="buttonStyling === 'profile'"
    class="btn flex-fill btn-custom-skyblue"
    (click)="initiateAddBusiness()">
    <i class="icon-plus"></i>{{ 'Add new business' | translate }}
  </button>
  <button
    *ngIf="buttonStyling === 'modal'"
    class="btn btn-lg col-12 w-100 col-lg-5 flex-lg-fill cancel-btn btn-primary text-decoration-none"
    (click)="initiateAddBusiness()">
    {{ 'Link your business' | translate }}
  </button>
</ng-container>

<ng-template #addBusinessModal let-modal>
  <div class="modal-header d-flex align-items-start">
    <div class="d-flex flex-column">
      <h4 class="modal-title mt-2" id="modal-basic-title">
        {{ 'Verify ownership by entering your business TIN' | translate }}
      </h4>
    </div>
    <button
      type="button"
      class="btn mt-1 btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form
      class="row"
      [formGroup]="addBusinessForm"
      (ngSubmit)="onAddBusiness()">
      <div class="col-12 mb-2">
        <label for="tinNumber">{{ 'TIN' | translate }}</label>
        <div class="input-group mt-2">
          <input
            [type]="'text'"
            class="form-control"
            [ngClass]="{
              'invalid-tin border-right-none':
                invalidTin || tinAlreadyExist || tinVerificationFailed
            }"
            id="tinNumber"
            formControlName="tinNumber"
            [placeholder]="'Enter your business TIN' | translate" />
          <span
            class="input-group-text bg-transparent border-left-none"
            [ngClass]="{
              'invalid-tin':
                invalidTin || tinAlreadyExist || tinVerificationFailed
            }"
            *ngIf="
              invalidTin ||
              tinAlreadyExist ||
              tinVerificationFailed ||
              (addBusinessForm.get('tinNumber')?.invalid &&
                addBusinessForm.get('tinNumber')?.touched)
            ">
            <i class="icon-info-circle text-danger"></i>
          </span>
        </div>
        <div
          *ngIf="
            addBusinessForm.get('tinNumber')?.invalid &&
            addBusinessForm.get('tinNumber')?.touched
          "
          class="text-danger">
          {{ 'Tin number is required' | translate }}.
        </div>

        <div class="col-12 mt-2">
          <p class="text-sm text-danger" *ngIf="invalidTin">
            {{
              'The TIN you entered is invalid. Please check the number and try again'
                | translate
            }}.
          </p>
          <p class="text-sm text-danger" *ngIf="tinAlreadyExist">
            {{
              'This TIN is already linked to an existing account. Please log in to the existing account or you can request permission from the administrator currently associated with this TIN'
                | translate
            }}.
          </p>
          <p class="text-sm text-danger" *ngIf="tinVerificationFailed">
            {{ 'TIN verification failed. Please try again.' | translate }}.
          </p>
        </div>
      </div>

      <div class="modal-footer d-flex flex-column flex-lg-row-reverse gap-lg-2">
        <button
          type="submit"
          class="btn col-12 col-lg-5 flex-lg-fill me-lg-2 btn-primary fw-semibold"
          [disabled]="addBusinessForm.invalid || isProcessingAddBusiness">
          <div
            class="spinner-border spinner-border-sm"
            role="status"
            *ngIf="isProcessingAddBusiness">
            <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
          </div>
          {{ 'Continue' | translate }}
        </button>
        <button
          type="button"
          class="btn col-12 col-lg-5 flex-lg-fill cancel-btn btn-outline-dark text-decoration-none"
          (click)="cancelAddBusiness()">
          {{ 'Cancel' | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #tokenModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title w-90 mt-2" id="modal-basic-title">
      {{ 'Confirm ownership of TIN' | translate }}
    </h4>

    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div
    class="modal-body responsive-otp-input pt-0 pb-4"
    [ngClass]="{ 'invalid-token': invalidToken }">
    <div class="w-100 mb-2">
      <p class="form-text">
        {{
          'Enter the five-digit code we sent to the phone number and email associated with the TIN'
            | translate
        }}
      </p>
    </div>
    <ng-otp-input
      #ngOtpInputRef
      (onInputChange)="onOtpChange($event, ngOtpInputRef)"
      [config]="{
        length: 5,
        placeholder: '0',
        inputClass: 'formControl',
        containerClass: 'custom-token-input'
      }"></ng-otp-input>
    <p *ngIf="invalidToken" class="text-danger mt-2">
      {{
        'The code you entered is not valid. Please request a new code to continue'
          | translate
      }}.
    </p>
    <div class="w-100 d-flex justify-content-start">
      <p class="mt-4">
        {{ 'Didn’t receive the email' | translate }}?
        <span class="resendLink" (click)="resendAddBusinessOTP()">{{
          'Click to resend' | translate
        }}</span>
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn w-100 btn-outline-dark cancel-btn"
      (click)="cancelVerifyAddBusiness()">
      {{ 'Cancel' | translate }}
    </button>
    <button
      type="button"
      [disabled]="token.length < 5"
      (click)="confirmAddBusiness(ngOtpInputRef)"
      class="btn w-100 btn-primary">
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        *ngIf="isVerifyingAddBusinessToken">
        <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
      </div>
      {{ 'Confirm' | translate }}
    </button>
  </div>
</ng-template>
<ng-template #businessAlreadyLinkedModal let-modal>
  <div class="modal-header d-flex align-items-start">
    <div class="check-icon mt-2">
      <i aria-hidden="true" class="icon-alert-circle"></i>
    </div>
    <button
      type="button"
      class="btn mt-1 btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="col-12 mb-2">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'Business already linked' | translate }}!
      </h4>
      <p class="text-sm mt-2" [innerHTML]="tinAlreadyExistMessage"></p>

      <p class="text-sm mt-2">
        {{ 'Would you like to request access to this business' | translate }}?
      </p>
    </div>
  </div>
  <div class="modal-footer d-flex flex-column flex-lg-row-reverse gap-lg-2">
    <button
      type="submit"
      (click)="initiateAddUserRequest()"
      class="btn col-12 col-lg-5 flex-lg-fill me-lg-2 btn-primary fw-semibold">
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        *ngIf="isRequestingAddUser">
        <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
      </div>
      {{ 'Request to join' | translate }}
    </button>
    <button
      type="button"
      class="btn col-12 col-lg-5 flex-lg-fill cancel-btn btn-outline-dark text-decoration-none"
      (click)="cancelAddBusiness()">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</ng-template>
