<irembogov-otp-fetch-input
  [formControl]="formControl"
  [fieldStatus]="fieldStatus"
  [isFetchingData]="isFetchingData"
  [dataFetchedFailedError]="dataFetchedFailedError"
  [otpLength]="field.props['otpLength'] ?? 5"
  [otpPlaceholder]="field.props['otpPlaceholder'] ?? '-'"
  [allowOtpNumbersOnly]="field.props['allowOtpNumbersOnly'] ?? false"
  [statusMessages]="this.field.props"
  [inputRegexValueValidation]="
    field.props['inputRegexValueValidation'] ?? '\\d{4}'
  "
  [otpLabel]="field.props['otpLabel'] ?? 'Enter OTP sent to the recipient.'"
  [changeRecipientLabel]="
    field.props['changeRecipientLabel'] ?? 'Click here to start again.'
  "
  [placeholder]="field.props['placeholder'] ?? 'Enter recipient'"
  [disableAutoFocus]="disableAutoFocus"
  (fetchVerifiedData)="onDataFetch($event)">
</irembogov-otp-fetch-input>
