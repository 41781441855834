import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../models/response.model';
import { apis } from '../app-apis';
import { convertObjectToQueryString } from '../helpers/query-param-converter';
import { Observable, map } from 'rxjs';
import {
  IApplication,
  IApplicationDetails,
  IHttpBaseResponse,
  IHttpPagedResponse,
} from '@irembo-andela/irembogov3-common';
import { ICertificate } from '../models/certificates.model';
import { ApplicationCertificateValidity } from '../models/application-certificate-response-model';
import { ICertificateDownload } from '../models/certificate-download.model';
import { IApplicationResponse } from '../models/application-response.model';
import {
  IFile,
  IRequestAccessResponse,
} from '../models/track-application.model';
import { IOfficerAttachment } from '../models/officer-attachment.model';
import { IBusinessApplication } from '../models/business/business.model';

interface IHttpPagedRecordResponse<T> extends IHttpBaseResponse {
  data: PagedRecord<T>;
}
export interface PagedRecord<T> {
  content: T;
  pageable: any;
  last: boolean;
  totalPages: number;
  totalElements: number;
  number: number;
  size: number;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
  sort: any;
}
@Injectable({
  providedIn: 'root',
})
export class ApplicationsService {
  private baseUrl = environment.apiGatewayBaseUrl + '/application';
  private applicationAPIs = apis.application;

  constructor(private http: HttpClient) {}

  getApplications(queryParams: {
    page: number;
    size: number;
    sort: string;
    searchTerm: string;
  }) {
    const queryParamsStr = convertObjectToQueryString(queryParams);
    return this.http.get<IHttpPagedResponse<IApplication>>(
      `${this.baseUrl}${this.applicationAPIs.applications}?${queryParamsStr}`
    );
  }

  getBusinessApplications(
    businessId: string,
    queryParams: {
      page: number;
      size: number;
      sort: string;
      searchTerm: string;
    }
  ) {
    const queryParamsStr = convertObjectToQueryString(queryParams);
    return this.http.get<IHttpPagedResponse<IBusinessApplication>>(
      `${this.baseUrl}${this.applicationAPIs.businessApplications(
        businessId
      )}?${queryParamsStr}`
    );
  }

  getBusinessCertificates(
    businessId: string,
    queryParams: {
      page: number;
      size: number;
      sort: string;
      searchTerm: string;
    }
  ) {
    const queryParamsStr = convertObjectToQueryString(queryParams);
    return this.http.get<IHttpPagedRecordResponse<ICertificate[]>>(
      `${this.baseUrl}${this.applicationAPIs.businessCertificates(
        businessId
      )}?${queryParamsStr}`
    );
  }

  getBusinessOfficersAttachments(
    businessId: string,
    queryParams: {
      page: number;
      size: number;
      sort: string;
      searchTerm: string;
    }
  ) {
    const queryParamsStr = convertObjectToQueryString(queryParams);
    return this.http.get<IHttpPagedRecordResponse<IOfficerAttachment[]>>(
      `${this.baseUrl}${this.applicationAPIs.businessOfficerAttachments(
        businessId
      )}?${queryParamsStr}`
    );
  }

  getApplicationDetailsById(
    applicationId: string
  ): Observable<IApplicationDetails> {
    return this.http
      .get<IResponse<IApplicationDetails>>(
        `${this.baseUrl}${this.applicationAPIs.details}`,
        {
          headers: { id: applicationId },
        }
      )
      .pipe(map(res => res.data));
  }

  getApplicationByBillId(billId: string): Observable<IApplicationResponse> {
    return this.http
      .get<IResponse<IApplicationResponse>>(
        `${this.baseUrl}${this.applicationAPIs.billId}`,
        {
          headers: { billId },
        }
      )
      .pipe(map(res => res.data));
  }

  getApplicationRfaObject(id: string): Observable<any> {
    return this.http
      .get<IResponse<any>>(`${this.baseUrl}${this.applicationAPIs.rfaObject}`, {
        headers: { id },
      })
      .pipe(map(res => res.data));
  }

  getApplicationFormState(id: string): Observable<any> {
    return this.http
      .get<IResponse<any>>(`${this.baseUrl}${this.applicationAPIs.formState}`, {
        headers: { id },
      })
      .pipe(map(res => res.data));
  }

  getApplicationFeedbacks(id: string): Observable<any> {
    return this.http
      .get<IResponse<any>>(`${this.baseUrl}${this.applicationAPIs.feedbacks}`, {
        headers: { id },
      })
      .pipe(map(res => res.data));
  }

  getActiveCertificates() {
    return this.http.get<IResponse<Record<string, ICertificate[]>>>(
      `${this.baseUrl}${this.applicationAPIs.userCertificates}`
    );
  }

  getAlmostExpiringCertificates() {
    return this.http.get<IResponse<ICertificate[]>>(
      `${this.baseUrl}${this.applicationAPIs.userExpiringCertificates}`
    );
  }

  getExpiredCertificates() {
    return this.http.get<IResponse<ICertificate[]>>(
      `${this.baseUrl}${this.applicationAPIs.userExpiredCertificates}`
    );
  }

  getTrackApplicatonByBillId(billId: string) {
    return this.http.get<IResponse<IApplication>>(
      `${this.baseUrl}${this.applicationAPIs.billIdPublic}`,
      {
        headers: { billId },
      }
    );
  }

  getTrackApplicatonByApplicationId(applicationNumber: string) {
    return this.http.get<IResponse<IApplication>>(
      `${this.baseUrl}${this.applicationAPIs.applicationNumberPublic}`,
      {
        headers: { applicationNumber },
      }
    );
  }

  checkApplicationCertificateValidity(applicationNumber: string) {
    return this.http.get<IResponse<ApplicationCertificateValidity>>(
      `${this.baseUrl}${this.applicationAPIs.applicationCertificateValidity}?applicationNumber=${applicationNumber}`
    );
  }

  verifyApplicationToken(applicationNumber: string, token: string) {
    const encodedToken = encodeURIComponent(btoa(token));
    return this.http.get<IResponse<ICertificateDownload>>(
      `${this.baseUrl}/v1/application/${applicationNumber}/certificates/preview?token=${encodedToken}`
    );
  }

  requestAccessByApplicationNumber(applicationNumber: string, locale: string) {
    return this.http.post<IResponse<IRequestAccessResponse>>(
      `${this.baseUrl}/v1/request-access/${applicationNumber}`,
      { locale }
    );
  }

  getApplicationDetailsByOTP(applicationNumber: string, otp: string) {
    let url = `${this.baseUrl}/v1/application/${applicationNumber}/details-by-otp`;
    if (otp.length > 0) {
      const encodedToken = encodeURIComponent(btoa(otp));
      url = url + `?otp=${encodedToken}`;
    }
    return this.http.get<IResponse<Record<string, unknown>>>(url);
  }

  getPaymentReceiptByOTP(applicationNumber: string, otp: string) {
    let url = `${this.baseUrl}/v1/payment-receipt-by-otp`;
    if (otp.length > 0) {
      const encodedToken = encodeURIComponent(btoa(otp));
      url = url + `?otp=${encodedToken}`;
    }
    return this.http.get<IResponse<IFile>>(url, {
      headers: { applicationNumber },
    });
  }

  getCertificateByOTP(applicationNumber: string, otp: string, locale: string) {
    let url = `${this.baseUrl}/v1/application/${applicationNumber}/certificate-by-otp?locale=${locale}`;
    if (otp.length > 0) {
      const encodedToken = encodeURIComponent(btoa(otp));
      url = url + `&otp=${encodedToken}`;
    }
    return this.http.get<IResponse<IFile>>(url);
  }

  getOfficersAttachments() {
    return this.http.get<IResponse<Record<string, IOfficerAttachment[]>>>(
      `${this.baseUrl}${this.applicationAPIs.userOfficersAttachments}`
    );
  }

  downloadAttachment(applicationId: string, fileName: string) {
    return this.http.get<Blob>(
      `${this.baseUrl}${this.applicationAPIs.attachment}`,
      {
        params: { id: applicationId, fileName },
        responseType: 'blob' as 'json',
        observe: 'response',
      }
    );
  }

  getPaymentPublicKey(merchant: string): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(
      `${environment.apiGatewayBaseUrl}/application/v1/payment-merchant/public-key`,
      {
        headers: { merchant },
      }
    );
  }
}
