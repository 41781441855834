<div class="wrapper d-flex no-wrap w-100">
  <ng-container #fieldComponent class="w-100"></ng-container>
  <button
    *ngIf="this.field.props?.['triggerType'] === EGenericInputTriggerType.TRIGGER_BUTTON"
    type="button"
    class="btn btn-primary ms-2"
    (click)="triggerAction()">
    <i class="icon-search-md" *ngIf="!this.field.props?.['runAction']"></i>
    <div
      *ngIf="this.field.props?.['runAction']"
      class="spinner-border spinner-border-sm text-light"
      role="status">
      <span class="visually-hidden">searching...</span>
    </div>
  </button>
</div>
<br />
