import { Component } from '@angular/core';

@Component({
  selector: 'irembogov-privacy-policy',
  templateUrl: './irembogov-privacy-policy.component.html',
  styleUrls: ['./irembogov-privacy-policy.component.scss'],
})
export class IrembogovPrivacyPolicyComponent {
  COMPANY_TEXT =
    '<b>Company</b> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot;, or &quot;Our&quot; in this Agreement) refers to Irembo Ltd, Irembo Campus KG 9 Ave, Nyarutarama, Kigali, Rwanda.';
  WEBSITE_TEXT =
    '<b>Website</b> refers to IremboGov, accessible from <a href=&quot;https://irembo.gov.rw&quot;>https://irembo.gov.rw</a>';
}
