import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { Subscription, finalize } from 'rxjs';
import {
  IUserProfile,
  IdentificationType,
} from '../../../../models/user-profile.model';
import { UserProfileService } from '../../../../services/user-profile.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'irembogov-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss'],
})
export class ProfileSettingsComponent implements OnInit, OnDestroy {
  keycloakProfile: KeycloakProfile = {};
  isLoggedIn = false;
  updateNotificationsForm!: FormGroup;
  updating = false;
  doneLoadingProfile = false;
  emailVerificationPattern = /^[\w\-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  subscriptions = new Subscription();
  userProfile: IUserProfile = {
    userId: '',
    identificationType: IdentificationType.CITIZEN_APPLICATION_NUMBER,
    identificationNumber: '',
    accountVerified: false,
    notificationPhoneNumber: '',
    notificationEmail: '',
    notificationType: {
      PHONE_NUMBER: true,
      EMAIL: false,
    },
  };

  constructor(
    private modalService: NgbModal,
    private toastService: ToastService,
    private userProfileService: UserProfileService,
    private keycloak: KeycloakService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    if (this.isLoggedIn)
      this.keycloakProfile = await this.keycloak.loadUserProfile();
    this.updateNotificationsForm = new FormGroup({
      phoneNumber: new FormControl<string>('', [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(environment.PHONE_NUMBER_REGEX),
      ]),
      email: new FormControl<string>('', [
        Validators.pattern(this.emailVerificationPattern),
      ]),
    });

    this.getUserProfile();
  }

  openModel(
    content: unknown,
    windowClass: string,
    size?: string,
    backdrop?: string
  ) {
    let options: Record<string, unknown> = {
      ariaLabelledBy: 'update-notifications',
      windowClass,
      centered: true,
      size,
    };
    if (backdrop) {
      options = { ...options, backdrop };
    }
    return this.modalService.open(content, options);
  }

  openUpdateNotificationsModal(content: TemplateRef<unknown>) {
    this.openModel(content, 'update-notifications-modal');
  }

  updateNotificationsChanges() {
    const phoneNumberControl = this.updateNotificationsForm.get('phoneNumber');
    const emailControl = this.updateNotificationsForm.get('email');

    if (
      this.updateNotificationsForm.invalid ||
      (phoneNumberControl?.value === '' && emailControl?.value === '')
    ) {
      this.updating = false;
      return this.toastService.show({
        type: 'error',
        title: 'Error saving notification settings',
        body: 'Provide at least one contact detail (email or phone number)',
        delay: 5000,
      });
    }

    this.updating = true;

    const params = {
      notificationPhoneNumber:
        phoneNumberControl?.value !== '' ? phoneNumberControl?.value : null,
      notificationEmail:
        emailControl?.value !== '' ? emailControl?.value : null,
    };

    this.subscriptions.add(
      this.userProfileService.addUpdateNotificationChannel(params).subscribe({
        next: res => {
          this.toastService.show({
            body: "You'll now receive alerts based on your preferences.",
            title: 'Notification settings updated!',
            type: 'success',
            delay: 5000,
          });
          this.getUserProfile();
          this.userProfileService.updateUserProfile(res.data);
          this.updating = false;
          this.modalService.dismissAll();
        },
        error: () => {
          this.updating = false;
          this.toastService.show({
            body: 'Saving preferences failed, please try again!',
            title: 'Error saving notification settings',
            type: 'error',
            delay: 5000,
          });
        },
      })
    );
  }

  getUserProfile() {
    this.subscriptions.add(
      this.userProfileService
        .getUserProfile()
        .pipe(finalize(() => (this.doneLoadingProfile = true)))
        .subscribe({
          next: res => {
            this.userProfile = res;
            this.fillFormData(res);
          },
          error: () => {
            this.toastService.show({
              body: 'Error loading notification preferences',
              title: 'Error loading notification preferences',
              type: 'error',
            });
          },
        })
    );
  }

  fillFormData(data: IUserProfile) {
    this.updateNotificationsForm.controls['email'].setValue(
      data.notificationEmail
    );
    this.updateNotificationsForm.controls['phoneNumber'].setValue(
      data.notificationPhoneNumber
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
