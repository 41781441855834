import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { EGenericInputTriggerType } from '../../../../models/irembo-generic-input-status.enum';

@Component({
  selector: 'irembogov-button-trigger-wrapper',
  templateUrl: './button-trigger-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./button-trigger-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonTriggerWrapperComponent
  extends FieldWrapper
  implements OnInit, OnDestroy
{
  private fieldChangesSub: Subscription | undefined;
  EGenericInputTriggerType = EGenericInputTriggerType;
  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (!this.field.expressions) {
      this.field.expressions = {};
    }

    this.field.expressions['props.runningAction'] = `field?.props?.runAction`;
  }

  ngOnDestroy(): void {
    this.fieldChangesSub?.unsubscribe();
  }

  triggerAction() {
    if (this.field.props) {
      this.field.props['runAction'] = true;
      return;
    }
  }
}
