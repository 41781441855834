import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { distinctUntilChanged, filter, Subscription } from 'rxjs';

@Component({
  selector: 'irembogov-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDatePickerComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy
{
  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  private subscription: Subscription | undefined;

  ngOnInit(): void {
    this.field.options?.fieldChanges
      ?.pipe(
        filter(
          (change: FormlyValueChangeEvent) =>
            change.field.key === this.field.key
        ),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(() => {
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
