<div
  class="w-100 d-flex align-items-center"
  *ngIf="
    fieldStatus === EOtpDataFetchStatus.INPUT_RECIPIENT ||
    fieldStatus === EOtpDataFetchStatus.COMPLETED
  ">
  <input
    #otpRecipient
    type="text"
    class="form-control w-100"
    [placeholder]="placeholder"
    [formControl]="customFormControl" />
  <button
    class="btn btn-primary ms-1"
    type="button"
    [disabled]="!validateRecipientValue()"
    *ngIf="fieldStatus === EOtpDataFetchStatus.INPUT_RECIPIENT"
    (click)="onRequestSendOtp()">
    <i class="icon-passcode-lock h5" *ngIf="!isRequestingSendOtp"></i>
    <output
      *ngIf="isRequestingSendOtp"
      class="spinner-border spinner-border-sm mr-2 h5 mb-0"></output>
  </button>
  <i
    class="icon-check-circle text-success h3 ms-2 mb-0"
    *ngIf="fieldStatus === EOtpDataFetchStatus.COMPLETED"></i>
</div>

<div
  class="form-group w-100"
  *ngIf="
    fieldStatus === EOtpDataFetchStatus.INPUT_OTP ||
    fieldStatus === EOtpDataFetchStatus.OTP_VERIFIED
  ">
  <div class="w-100 small">
    {{ otpLabel | translate }}
    <span
      *ngIf="!isVerifyingOtp"
      class="text-primary pointer-cursor"
      (click)="onChangeRecipient()"
      (keydown)="onChangeRecipient()">
      {{ changeRecipientLabel | translate }}
    </span>
  </div>
  <div
    class="input-group d-flex flex-row justify-content-start align-items-center">
    <ng-otp-input
      class="text-center"
      [formCtrl]="otpFormControl"
      [config]="otpInputConfig"></ng-otp-input>
    <button
      class="btn btn-primary rounded ms-2"
      type="button"
      [disabled]="otpFormControl.invalid || isVerifyingOtp || isFetchingData"
      (click)="onVerifyOtp()">
      <i
        class="icon-send-01 h4 mb-1"
        *ngIf="!(isVerifyingOtp || isFetchingData)"></i>
      <output
        *ngIf="isVerifyingOtp"
        class="spinner-border spinner-border-sm mr-2 h4 mb-1"></output>
      <output
        *ngIf="isFetchingData"
        class="spinner-grow mr-2 h4 mb-0 mt-1"></output>
    </button>
  </div>
</div>
<small class="w-100 text-primary" *ngIf="statusInformationMessage"
  ><i class="fa-solid fa-circle-info me-1"></i
  >{{ statusInformationMessage | translate }}</small
>
