import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subscription, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { getValidDataFetchInputValue } from '../../../utils/utils/data-fetch-widget-utils';

@Component({
  selector: 'irembogov-application-number-input',
  templateUrl: './irembo-application-number-input.component.html',
  styleUrls: ['./irembo-application-number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboApplicationNumberInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IremboApplicationNumberInputComponent,
      multi: true,
    },
  ],
})
export class IremboApplicationNumberInputComponent
  implements
    ControlValueAccessor,
    Validator,
    OnChanges,
    AfterViewInit,
    OnDestroy
{
  @ViewChild('applicationNumber') applicationNumberEl: ElementRef | undefined;
  @Input() placeholder: unknown;
  @Input() id: unknown;
  @Input() appNoLength = 0;
  @Output() fetchData = new EventEmitter<string>();
  @Input() statusClass: string | undefined;
  @Input() compareKeyValue: string | number | boolean | undefined;
  @Input() compareValue: string | number | boolean | undefined;
  @Input() compareOperator: string | undefined;
  compareStatus = false;
  isInvalidAppNo = false;
  appNoInputSub: Subscription | undefined;
  customFormControl = new FormControl();

  constructor(private cd: ChangeDetectorRef) {}

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onChange = (value: unknown) => {};
  private _onTouch = (value: unknown) => {};
  private _onValidationChange = () => {};
  /* eslint-enable */

  ngAfterViewInit(): void {
    this.appNoInputSub = fromEvent(
      this.applicationNumberEl?.nativeElement,
      'keyup'
    )
      .pipe(
        map((event: unknown) => {
          let applicationNo = '';
          if (event instanceof Event) {
            applicationNo = (event.target as HTMLInputElement).value;
          }
          return applicationNo;
        }),
        distinctUntilChanged()
      )
      .subscribe({
        next: (value: string) => {
          this.statusClass = '';
          this._onChange(value);
          this._onTouch(value);
          const validApplicationNo = getValidDataFetchInputValue(
            value,
            this.appNoLength
          );
          if (validApplicationNo) {
            this.fetchData.emit(validApplicationNo);
          }
          this.cd.detectChanges();
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['statusClass']) {
      this.isInvalidAppNo = changes['statusClass'].currentValue === 'danger';
      this._onValidationChange();
    }

    if (changes?.['compareKeyValue']) {
      this._onValidationChange();
    }
  }

  writeValue(obj: unknown): void {
    this.customFormControl.setValue(obj);
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.customFormControl.disable();
    } else {
      this.customFormControl.enable();
    }
  }

  validate(formControl: FormControl): ValidationErrors | null {
    const value = formControl.value?.trim();
    const validationErrors: ValidationErrors = {};
    if (!value) {
      return null;
    }
    const validInput = getValidDataFetchInputValue(value, this.appNoLength);
    if (!validInput) {
      validationErrors['invalidInput'] = true;
    }
    if (this.isInvalidAppNo) {
      validationErrors['invalidAppNo'] = true;
    }
    if (this.compareKeyValue && this.compareValue && this.compareOperator) {
      if (
        this.compareValues(
          this.compareKeyValue,
          this.compareValue,
          this.compareOperator
        )
      ) {
        validationErrors['compareStatus'] = true;
      }
    }
    return Object.keys(validationErrors).length ? validationErrors : null;
  }

  compareValues(
    compareKeyValue: string | number | boolean,
    compareValue: string | number | boolean,
    operator: string
  ): boolean {
    switch (operator) {
      case '==':
        return compareKeyValue == compareValue;
      case '===':
        return compareKeyValue === compareValue;
      case '!=':
        return compareKeyValue != compareValue;
      case '!==':
        return compareKeyValue !== compareValue;
      case '<':
        return compareKeyValue < compareValue;
      case '>':
        return compareKeyValue > compareValue;
      case '<=':
        return compareKeyValue <= compareValue;
      case '>=':
        return compareKeyValue >= compareValue;
      default:
        throw new Error('Invalid operator');
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  ngOnDestroy(): void {
    this.appNoInputSub?.unsubscribe();
  }
}
