import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { TranslateService } from '@ngx-translate/core';
import { EApplicationApplicantType } from '../../models/applicant-type.enum';
import { Business } from '../../models/business/business.model';
import { IHttpPagedResponse } from '../../models/http/paginated-response.model';
import { BusinessService } from '../../services/business.service';
import { UserBusinessService } from '../../services/user-business.service';

@Component({
  selector: 'irembogov-add-business-success',
  templateUrl: './add-business-success.component.html',
  styleUrls: ['./add-business-success.component.scss'],
})
export class AddBusinessSuccessComponent implements OnInit {
  businessId!: string;
  businessName!: string;
  initiatedApplyServiceKeyword?: string = undefined;
  initiatedApplyServiceId?: string = undefined;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userBusinessService: UserBusinessService,
    private businessService: BusinessService,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {
    this.businessId = this.route.snapshot.queryParams['businessId'];
    this.businessName = this.route.snapshot.queryParams['businessName'];

    const initiatedApplyServiceNavigationData =
      this.router.getCurrentNavigation()?.extras?.state?.[
        'initiatedApplyService'
      ];
    this.initiatedApplyServiceKeyword =
      initiatedApplyServiceNavigationData?.keyword;
    this.initiatedApplyServiceId = initiatedApplyServiceNavigationData?.id;
  }

  ngOnInit(): void {
    this.userBusinessService.fetchAndSetUserServices();
  }

  redirectPage(redirectTo: string): void {
    if (redirectTo === 'application') {
      this.gotoINitiatedApplyServicePage();
      return;
    }

    this.returnToHomePage();
  }

  private gotoINitiatedApplyServicePage(): void {
    if (!(this.initiatedApplyServiceId && this.initiatedApplyServiceKeyword)) {
      return;
    }
    const navigationExtras: NavigationExtras = {
      queryParams: {
        keyword: this.initiatedApplyServiceKeyword,
        id: this.initiatedApplyServiceId,
      },
      state: {
        applicantData: {
          applicantType: EApplicationApplicantType.BUSINESS,
          businessId: this.businessId,
          businessName: this.businessName,
        },
      },
    };

    this.router.navigate(['application'], navigationExtras);
  }

  private returnToHomePage(): void {
    this.router.navigate(['/']);
  }
}
